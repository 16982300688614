import { createContext, ReactNode, useContext } from "react";

import { bindDialogState, useDialog } from "@hooks/use-dialog";

import {
  AppointmentCancelDialog,
  AppointmentCancelProps,
} from "@features/calendar/components/Appointment/AppointmentCancelDialog";

import {
  AppointmentDetailsDialog,
  AppointmentDetailsProps,
} from "@/features/calendar/components/Appointment/AppointmentDetailsDialog";
import { CalendarFiltersDialog } from "@/features/calendar/components/CalendarFiltersDialog";
import { ColumnClickSlotProps, SlotAddDialog } from "@/features/calendar/components/SlotAddDialog";
import { AppointmentStatus } from "@/types";

import { BlockedSlotDialog } from "../components/BlockedSlot/BlockedSlotDialog";
import { BlockedSlotFormProps } from "../components/BlockedSlot/BlockedSlotForm";
import { UnknownCalendarSlot } from "../models";
import { isAppointmentTreatmentSlot, isCalendarBlockedSlot } from "../utils/type-guards";

type CalendarDialogContextProps = {
  openDetailsDialog: (data?: AppointmentDetailsProps) => void;
  openCancelDialog: (data?: AppointmentCancelProps) => void;
  openFiltersDialog: () => void;
  openAddSlotDialog: (data?: ColumnClickSlotProps) => void;
  selectAppointment: (slot: UnknownCalendarSlot) => void;
};

const CalendarDialogContext = createContext<CalendarDialogContextProps | undefined>(undefined);

export const CalendarDialogProvider = ({ children }: { children: ReactNode }) => {
  const detailsDialogState = useDialog<AppointmentDetailsProps>();
  const cancelDialogState = useDialog<AppointmentCancelProps>();
  const filtersDialogState = useDialog();
  const addSlotDialogState = useDialog<ColumnClickSlotProps>();
  const blockedSlotDialogState = useDialog<BlockedSlotFormProps>();

  const openDetailsDialog = (data?: AppointmentDetailsProps) => detailsDialogState.open(data);
  const openCancelDialog = (data?: AppointmentCancelProps) => cancelDialogState.open(data);
  const openFiltersDialog = () => filtersDialogState.open();
  const openAddSlotDialog = (data?: ColumnClickSlotProps) => addSlotDialogState.open(data);

  const handleShowAppointment = (data?: AppointmentDetailsProps) => {
    detailsDialogState.open(data);
  };

  const handleCancelAppointment = (data?: AppointmentCancelProps) => {
    cancelDialogState.open(data);
  };

  const handleAppointmentSelect = (slot: UnknownCalendarSlot) => {
    if (isCalendarBlockedSlot(slot)) {
      blockedSlotDialogState.open({
        defaultValues: {
          ...slot,
        },
        action: "update",
      });
    } else if (isAppointmentTreatmentSlot(slot)) {
      switch (slot.status) {
        case AppointmentStatus.CanceledByClient:
          handleCancelAppointment({ appointmentId: slot.id });
          break;
        default:
          handleShowAppointment({
            appointmentId: slot.id,
          });
          break;
      }
    }
  };

  return (
    <CalendarDialogContext.Provider
      value={{
        selectAppointment: handleAppointmentSelect,
        openDetailsDialog,
        openCancelDialog,
        openFiltersDialog,
        openAddSlotDialog,
      }}>
      {children}
      <AppointmentDetailsDialog {...bindDialogState(detailsDialogState)} />
      <AppointmentCancelDialog {...bindDialogState(cancelDialogState)} />
      <CalendarFiltersDialog {...bindDialogState(filtersDialogState)} />
      <SlotAddDialog {...bindDialogState(addSlotDialogState)} />
      <BlockedSlotDialog {...bindDialogState(blockedSlotDialogState)} />
    </CalendarDialogContext.Provider>
  );
};

export const useCalendarDialogs = () => {
  const context = useContext(CalendarDialogContext);
  if (!context) {
    throw new Error("useDialogs must be used within a DialogProvider");
  }
  return context;
};
