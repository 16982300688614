import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { GENERIC_NAME } from "@constants/validations";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { TextField } from "@components/ui/TextField";

import { useCategoryStore } from "@features/services/hooks/use-category-store";

import { ColorPicker } from "@/components/ui/ColorPicker";
import { Select } from "@/components/ui/Select";
import { AddCategoryInput, Category } from "@/types";

import { useHeadCategoriesStore } from "../../hooks";

export type CategoryFormProps = {
  categoryId?: string;
  headCategoryId: string;
  onRemove?: (category: Category) => Promise<boolean>;
};

type CategoryForm = {
  category?: Category | null;
  onClose: () => void;
  onRemove?: (category: Category) => Promise<boolean>;
};

type CategoryFormDialogProps = {
  open: boolean;
  props?: CategoryFormProps;
  onClose: () => void;
};

type FormData = AddCategoryInput;

export const CategoryFormDialog = ({ open, props, onClose }: CategoryFormDialogProps) => {
  const { t } = useTranslation();
  const { category, loading } = useCategoryStore(props?.categoryId);

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={
        props?.categoryId ? (
          <span>{t("services.categories.edit")}</span>
        ) : (
          <span>{t("services.categories.addNew")}</span>
        )
      }>
      {loading ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <CategoryForm onClose={onClose} category={category} onRemove={props?.onRemove} />
      )}
    </DefaultDialog>
  );
};

const CategoryForm = ({ category, onRemove, onClose }: CategoryForm) => {
  const { t } = useTranslation();
  const { addCategory, updateCategory, loadingAdd, loadingUpdate } = useCategoryStore();
  const { headCategoryOptions, loading } = useHeadCategoriesStore();

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: { ...category, headCategoryId: category?.headCategoryId },
  });

  const handleCategoryForm = async (data: FormData) => {
    const { errors } = category
      ? await updateCategory(
          { name: data.name, color: data.color, headCategoryId: data.headCategoryId },
          category?.id,
        )
      : await addCategory(data);
    if (!errors) {
      onClose();
    }
  };

  const handleCategoryDelete = async () => {
    if (!onRemove || !category) return;

    const result = await onRemove(category);
    result && onClose();
  };

  return (
    <form className="relative space-y-4" onSubmit={handleSubmit(handleCategoryForm)} noValidate>
      <TextField
        {...register("name", GENERIC_NAME)}
        label={t("services.categories.categoryName")}
        showLabel
        placeholder={t("services.categories.categoryNamePlaceholder")}
      />
      <Controller
        name="headCategoryId"
        control={control}
        render={({ field: { onChange: formOnChange, value = "" } }) => (
          <Select
            name="headCategoryId"
            label={t("generic.serviceGroup")}
            options={headCategoryOptions}
            placeholder={t("placeholders.selectGroup")}
            disabled={loading}
            selectBy="value"
            onChange={(data) => formOnChange(data)}
            value={value}
          />
        )}
      />
      <ColorPicker control={control} name="color" label={t("generic.color")} />
      <p className="text-stone-500">{t("services.categories.categoryColorDescription")}</p>
      <div className="mt-4 flex flex-col items-center gap-4 md:flex-row">
        {category && (
          <Button variant="danger" fullWidth onClick={handleCategoryDelete}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          variant="primary"
          fullWidth
          type="submit"
          disabled={!isValid || !isDirty || loadingAdd || loadingUpdate}>
          {category ? (
            <span>{t("generic.save")}</span>
          ) : (
            <span>{t("services.categories.addNew")}</span>
          )}
        </Button>
      </div>
    </form>
  );
};
