import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UsersIcon } from "@heroicons/react/24/outline";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";
import { Tooltip } from "@components/ui/Tooltip";

import { useAppointmentStore } from "@features/calendar/hooks/use-appointment-store";
import { useClientStore } from "@features/clients/hooks";
import { useCountersStore } from "@features/session/hooks";

import { Appointment, AppointmentStatus, CancelAppointmentMutationVariables } from "@/types";

export type AppointmentCancelProps = {
  appointmentId: string;
  onConfirm?: () => void;
};

type AppointmentCancelDialogProps = {
  props?: AppointmentCancelProps;
  open: boolean;
  onClose: () => void;
};

type FormData = Omit<CancelAppointmentMutationVariables, "id">;

type AppointmentCancelFormProps = {
  appointment: Appointment;
  onConfirm?: () => void;
  onClose: () => void;
};

export const AppointmentCancelDialog = ({ open, props, onClose }: AppointmentCancelDialogProps) => {
  const { t } = useTranslation();
  const { appointment, loading } = useAppointmentStore({
    id: props?.appointmentId,
  });

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("generic.areYouSure")}>
      {loading || !appointment ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <AppointmentCancelForm
          onClose={onClose}
          onConfirm={props?.onConfirm}
          appointment={appointment}
        />
      )}
    </DefaultDialog>
  );
};

const AppointmentCancelForm = ({ appointment, onClose, onConfirm }: AppointmentCancelFormProps) => {
  const { t } = useTranslation();
  const { smsLimit } = useCountersStore();
  const { loadingCancel, cancelAppointment } = useAppointmentStore();
  const { client } = useClientStore(appointment.clientId);
  const {
    formState: { isValid },
    control,
    reset,
    handleSubmit,
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      sendNotification: smsLimit > 0 && !!client?.phone,
    },
  });

  const handleAppointmentCancel: SubmitHandler<FormData> = async (data) => {
    const { errors } = await cancelAppointment({
      id: appointment.appointmentId,
      ...data,
      sendNotification: data.sendNotification ?? false,
    });
    if (!errors) {
      reset({ sendNotification: smsLimit > 0 && !!client?.phone });
      onConfirm && onConfirm();
      onClose();
    }
  };

  return (
    <form
      className="flex h-full flex-1 flex-col justify-between space-y-4"
      onSubmit={handleSubmit(handleAppointmentCancel)}
      noValidate>
      <div>
        <div className="mt-4">
          {appointment.status === AppointmentStatus.CanceledByClient ? (
            <span>{t("appointments.confirmCancellationFromClientDescription")}</span>
          ) : (
            <span>{t("appointments.cancelDescription")}</span>
          )}
        </div>
        {appointment.status === AppointmentStatus.CanceledByClient && (
          <div className="flex justify-center p-4">
            <Button
              to={`/clients/${client?.id}`}
              startIcon={<UsersIcon />}
              variant="primary-inline">
              <span>{t("appointments.seeClientDetails")}</span>&nbsp;<span>{client?.fullName}</span>
            </Button>
          </div>
        )}
        {appointment.status !== AppointmentStatus.CanceledByClient && (
          <div className="py-4">
            <Tooltip
              content={
                smsLimit === 0 ? (
                  <span>{t("sms.limitReachedTooltip")}</span>
                ) : (
                  <span>{t("sms.clientPhoneNotProvided")}</span>
                )
              }
              disabled={smsLimit > 0 && !!client?.phone}>
              <Switch
                control={control}
                name="sendNotification"
                label={t("appointments.notifyAboutCancelation")}
                showLabel
                disabled={smsLimit === 0 || !client?.phone}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-8 grid grid-cols-2 gap-4">
        <Button variant="secondary-outline" fullWidth onClick={onClose} disabled={loadingCancel}>
          {t("generic.back")}
        </Button>
        <Button type="submit" variant="danger" fullWidth disabled={!isValid || loadingCancel}>
          {t("appointments.cancel")}
        </Button>
      </div>
    </form>
  );
};
