import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { useRouterUtils } from "@/features/router/hooks/use-router-utils";
import {
  OnSmsLimitUpdatedDocument,
  OnSmsLimitUpdatedSubscription,
} from "@/features/session/subscriptions/OnSmsLimitUpdated.generated";
import { useSessionContext } from "@/providers/SessionProvider";

import { useFetchSmsLimitQuery } from "../queries/FetchSmsLimit.generated";

export const useCountersStore = () => {
  const { isOnboarded, isEmailVerified } = useSessionContext();
  const { isAuthenticated } = useAuth0();

  const { isInitial, isOnOnboardingPage, isOnEmailVerificationPage, isOnMaintenancePage } =
    useRouterUtils();

  const shouldSkip =
    !isAuthenticated ||
    !isOnboarded ||
    !isEmailVerified ||
    isInitial ||
    isOnOnboardingPage ||
    isOnEmailVerificationPage ||
    isOnMaintenancePage;

  const {
    data: smsLimitData,
    loading: loadingSmsLimit,
    subscribeToMore: subscribeToMoreSmsLimit,
  } = useFetchSmsLimitQuery({
    skip: shouldSkip,
  });

  useEffect(() => {
    if (shouldSkip) {
      return;
    }

    const unsubscribe = subscribeToMoreSmsLimit<OnSmsLimitUpdatedSubscription>({
      document: OnSmsLimitUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => ({
        fetchSmsLimit: subscriptionData.data.smsLimitUpdated ?? prev.fetchSmsLimit,
      }),
    });

    return () => unsubscribe && unsubscribe();
  }, [subscribeToMoreSmsLimit]);

  return {
    loading: loadingSmsLimit,
    loadingSmsLimit,
    smsLimit: smsLimitData?.fetchSmsLimit || 0,
  };
};
