import { DndContext, closestCorners, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToWindowEdges,
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Employee } from "@/types";

import { EmployeesDirectoryItem } from "./EmployeesDirectoryItem";

type EmployeesDirectoryProps = {
  employees: Employee[];
  selectedEmployee: Employee | undefined;
  handleSelect: (employee: Employee) => void;
  isDraggable?: boolean;
  setTempEmployees: (employees: Employee[]) => void;
};

export const EmployeesDirectory = ({
  employees,
  selectedEmployee,
  handleSelect,
  isDraggable = false,
  setTempEmployees,
}: EmployeesDirectoryProps) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeIndex = employees.findIndex(
        (employee: Employee) => employee.accountId === active.id,
      );
      const overIndex = employees.findIndex((employee: Employee) => employee.accountId === over.id);
      const newEntities = arrayMove(employees, activeIndex, overIndex);

      setTempEmployees(newEntities);
    }
  };

  return (
    <nav>
      <DndContext
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement]}>
        <ul role="list" className="relative z-0 divide-y divide-stone-200">
          <SortableContext
            items={employees.map((employee: Employee) => employee.accountId)}
            strategy={verticalListSortingStrategy}>
            {employees.map((employee) => {
              return (
                <EmployeesDirectoryItem
                  key={employee.accountId}
                  employee={employee}
                  selectedEmployeeId={selectedEmployee?.accountId}
                  handleSelect={handleSelect}
                  isDraggable={isDraggable}
                />
              );
            })}
          </SortableContext>
        </ul>
      </DndContext>
    </nav>
  );
};
