import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../fragments/PageInfo.generated';
import { SearchTreatmentFragmentDoc } from '../fragments/SearchTreatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAllTreatmentsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchAllTreatmentsQuery = { __typename?: 'RootQueryType', fetchAllTreatments: { __typename?: 'SearchTreatmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchTreatmentEdge', node: { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryId: any, treatmentName: string, treatmentId: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null } }> } };


export const FetchAllTreatmentsDocument = gql`
    query FetchAllTreatments($first: Int, $after: String, $query: String) {
  fetchAllTreatments(first: $first, after: $after, query: $query) {
    count
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...SearchTreatment
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${SearchTreatmentFragmentDoc}`;

/**
 * __useFetchAllTreatmentsQuery__
 *
 * To run a query within a React component, call `useFetchAllTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTreatmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFetchAllTreatmentsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllTreatmentsQuery, FetchAllTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllTreatmentsQuery, FetchAllTreatmentsQueryVariables>(FetchAllTreatmentsDocument, options);
      }
export function useFetchAllTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllTreatmentsQuery, FetchAllTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllTreatmentsQuery, FetchAllTreatmentsQueryVariables>(FetchAllTreatmentsDocument, options);
        }
export type FetchAllTreatmentsQueryHookResult = ReturnType<typeof useFetchAllTreatmentsQuery>;
export type FetchAllTreatmentsLazyQueryHookResult = ReturnType<typeof useFetchAllTreatmentsLazyQuery>;
export type FetchAllTreatmentsQueryResult = Apollo.QueryResult<FetchAllTreatmentsQuery, FetchAllTreatmentsQueryVariables>;
