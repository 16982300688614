import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTreatmentQueryVariables = Types.Exact<{
  treatmentId: Types.Scalars['UUID4'];
}>;


export type FetchTreatmentQuery = { __typename?: 'RootQueryType', fetchTreatment: { __typename?: 'Treatment', id: any, name: string, categoryId: any, duration: number, isExactPrice: boolean, price?: number | null, priceFrom?: number | null, priceTo?: number | null } };


export const FetchTreatmentDocument = gql`
    query FetchTreatment($treatmentId: UUID4!) {
  fetchTreatment(treatmentId: $treatmentId) {
    id
    name
    categoryId
    duration
    isExactPrice
    price
    priceFrom
    priceTo
  }
}
    `;

/**
 * __useFetchTreatmentQuery__
 *
 * To run a query within a React component, call `useFetchTreatmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTreatmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTreatmentQuery({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *   },
 * });
 */
export function useFetchTreatmentQuery(baseOptions: Apollo.QueryHookOptions<FetchTreatmentQuery, FetchTreatmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchTreatmentQuery, FetchTreatmentQueryVariables>(FetchTreatmentDocument, options);
      }
export function useFetchTreatmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTreatmentQuery, FetchTreatmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchTreatmentQuery, FetchTreatmentQueryVariables>(FetchTreatmentDocument, options);
        }
export type FetchTreatmentQueryHookResult = ReturnType<typeof useFetchTreatmentQuery>;
export type FetchTreatmentLazyQueryHookResult = ReturnType<typeof useFetchTreatmentLazyQuery>;
export type FetchTreatmentQueryResult = Apollo.QueryResult<FetchTreatmentQuery, FetchTreatmentQueryVariables>;
