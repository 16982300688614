import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { Badge } from "@/components/ui/Badge";
import { useClientsContext } from "@/features/clients/providers/ClientsProvider";
import { cn } from "@/utils/utils";

import { useTagsStore } from "../hooks/tags/use-tags-store";

export const TagFilter = () => {
  const { t } = useTranslation();
  const { filters, setFilters } = useClientsContext();

  const { tagsSelectOptions } = useTagsStore();

  if (tagsSelectOptions.length === 0) return null;

  return (
    <div>
      <Listbox
        value={filters.tagsIds}
        onChange={(value) =>
          setFilters({
            ...filters,
            tagsIds: value,
          })
        }
        multiple>
        <Listbox.Button>
          <div className="group relative inline-flex items-center">
            <span className="text-xs text-stone-500 group-hover:text-stone-700">
              {t("actions.filter")}
            </span>
            {filters.tagsIds && filters.tagsIds.length > 0 && (
              <span className="ml-1 w-[2ch] text-xs">({filters.tagsIds.length})</span>
            )}
            <ChevronDownIcon className="-mb-0.5 ml-0.5 h-3.5 w-3.5 text-stone-500 group-hover:text-stone-700" />
          </div>
        </Listbox.Button>
        <div>
          <Listbox.Options className="overflow-y absolute z-40 mt-1 grid max-h-[1000%] overflow-y-scroll rounded border bg-white py-1 shadow-md">
            {tagsSelectOptions.map((tag) => (
              <Listbox.Option key={tag.value} value={tag.value}>
                {({ active, selected }) => (
                  <div
                    className={cn(
                      "flex cursor-pointer items-center gap-3 px-2 py-2 transition-all",
                      active && "bg-stone-100 transition-all",
                    )}>
                    <div
                      className={cn(
                        selected && "border-gold-600 bg-gold-500 text-white",
                        "h-5 w-5 rounded-sm border border-stone-300 p-[1px] shadow-sm",
                      )}>
                      {selected && <CheckIcon />}
                    </div>
                    <Badge color={tag.props.color} bordered className="whitespace-nowrap">
                      {tag.label}
                    </Badge>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};
