import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonFragmentDoc } from '../fragments/Salon.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSalonMutationVariables = Types.Exact<{
  data: Types.CreateSalonInput;
}>;


export type CreateSalonMutation = { __typename?: 'RootMutationType', createSalon: { __typename?: 'Salon', salonId: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: Types.MinLeadTimeOptionsInMinutes, maxLeadTime: Types.MaxLeadTimeOptionsInDays, url: any } } };


export const CreateSalonDocument = gql`
    mutation CreateSalon($data: CreateSalonInput!) {
  createSalon(data: $data) {
    ...Salon
  }
}
    ${SalonFragmentDoc}`;
export type CreateSalonMutationFn = Apollo.MutationFunction<CreateSalonMutation, CreateSalonMutationVariables>;

/**
 * __useCreateSalonMutation__
 *
 * To run a mutation, you first call `useCreateSalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalonMutation, { data, loading, error }] = useCreateSalonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalonMutation, CreateSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalonMutation, CreateSalonMutationVariables>(CreateSalonDocument, options);
      }
export type CreateSalonMutationHookResult = ReturnType<typeof useCreateSalonMutation>;
export type CreateSalonMutationResult = Apollo.MutationResult<CreateSalonMutation>;
export type CreateSalonMutationOptions = Apollo.BaseMutationOptions<CreateSalonMutation, CreateSalonMutationVariables>;
