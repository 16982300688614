import { Fragment } from "react";
import { Controller, FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import {
  BanknotesIcon,
  CalendarIcon,
  ChevronUpDownIcon,
  ClockIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { TREATMENTS } from "@constants/validations";
import { format, formatDuration, parseISO } from "date-fns";

import { Button } from "@components/ui/Button";
import { Radio } from "@components/ui/Radio";
import { TextField } from "@components/ui/TextField";

import { useAppointmentStore } from "@features/calendar/hooks/use-appointment-store";

import { PaymentMethod, PaymentMethodsMapped } from "@/components/PaymentMethod";
import { Select } from "@/components/ui/Select";
import { DATE_FORMAT } from "@/constants/date-formats";
import { formatPrice, integerToPrice } from "@/features/payments/utils";
import {
  Appointment,
  AppointmentStatus,
  Client,
  FinalizeBulkAppointmentInput,
  FinalizeBulkTreatmentInput,
} from "@/types";
import { cn } from "@/utils/utils";

import { AppointmentClientName } from "./AppointmentClientName";

type FormData = Pick<FinalizeBulkAppointmentInput, "status" | "treatments" | "paymentMethodId">;

type AppointmentsFinalizeFormProps = {
  suggestedPrices: { appointmentTreatmentId: string; suggestedPrice: number }[];
  appointment: Appointment;
  onSubmit: SubmitHandler<FormData>;
  onBackClick: () => void;
  client?: Client;
  onClose: () => void;
};

const statusOptions = [
  {
    value: AppointmentStatus.Completed,
    label: "appointments.status.completed",
  },
  {
    value: AppointmentStatus.Closed,
    label: "appointments.status.closed",
  },
];

export const AppointmentsFinalizeForm = ({
  suggestedPrices,
  appointment,
  client,
  onSubmit,
  onBackClick,
  onClose,
}: AppointmentsFinalizeFormProps) => {
  const { t } = useTranslation();
  const { loadingFinalize } = useAppointmentStore({
    id: appointment?.appointmentId,
    withSuggestedPrice: true,
  });

  const formMethods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      status: AppointmentStatus.Completed,
      paymentMethodId: "",
      treatments: appointment.treatments.map(({ appointmentTreatmentId }) => ({
        appointmentTreatmentId,
        finalPrice: integerToPrice(
          suggestedPrices.find(
            (suggestion) => suggestion.appointmentTreatmentId === appointmentTreatmentId,
          )?.suggestedPrice || 0,
        ),
      })),
    },
  });

  const paymentMethodsMapped = PaymentMethodsMapped();

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = formMethods;

  const treatments = useWatch({
    control,
    name: "treatments",
  });

  const summarizeTreatmentsPrice = (treatments: FinalizeBulkTreatmentInput[]) => {
    return treatments.reduce((acc, treatment) => acc + treatment.finalPrice * 100, 0);
  };

  const formatAppointmentDuration = (appointment: Appointment) => {
    const firstTreatment = appointment.treatments[0];
    const lastTreatment = appointment.treatments[appointment.treatments.length - 1];

    const from = new Date(firstTreatment.timeRange.from).getTime();
    const to = new Date(lastTreatment.timeRange.to).getTime();

    const duration = to - from;

    const formattedDuration = formatDuration({
      minutes: Math.floor(duration / 1000 / 60) % 60,
      hours: Math.floor(duration / 1000 / 60 / 60),
    });

    return formattedDuration;
  };

  const renderCustomSelectButton = (value: string) => {
    return value ? (
      <PaymentMethod paymentMethodId={value} />
    ) : (
      <p className="text-stone-400">{t("paymentMethods.placeholder")}</p>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex h-full flex-1 flex-col justify-between gap-4"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <div className="mt-4">
          {appointment?.clientName && client?.id ? (
            <AppointmentClientName client={client} onClose={onClose} />
          ) : (
            <span className="inline-flex text-2xl font-bold text-stone-600">
              {t("appointments.anonymousClient")}
            </span>
          )}
        </div>
        <div>
          <div className="mb-6">
            <Radio
              name="status"
              control={control}
              label={t("appointments.appointmentStatus")}
              translateLabels
              options={statusOptions}
              required
              errorMessage={
                errors.status &&
                t(`validation.${errors.status.type}`, {
                  name: t("generic.status"),
                })
              }
            />
          </div>
          <div className="mb-2 flex w-full flex-wrap gap-x-2 gap-y-1 text-sm font-normal capitalize text-stone-600 sm:flex-row">
            <div className="flex items-center">
              <CalendarIcon
                className="mr-0.5 h-3.5 w-3.5 text-stone-700 sm:flex"
                aria-hidden="true"
              />
              {format(
                parseISO(appointment.treatments[0].timeRange.from as string),
                DATE_FORMAT.DATE_WITH_DAY_NAME,
              )}
            </div>
            <div className="inline-flex items-center">
              <ClockIcon className="mr-0.5 h-3.5 w-3.5 text-gray-700 sm:flex" aria-hidden="true" />
              {format(parseISO(appointment.treatments[0].timeRange.from), "HH:mm")}
              {" - "}
              {format(
                parseISO(appointment.treatments[appointment.treatments.length - 1].timeRange.to),
                "HH:mm",
              )}
              <span className="ml-1 inline-flex lowercase">
                ({formatAppointmentDuration(appointment)})
              </span>
            </div>
          </div>
          <ul
            role="list"
            className="space-y-6 rounded-md border border-stone-300 p-4 shadow-sm @container sm:space-y-0">
            {appointment.treatments.map((treatment, index) => (
              <li key={`${treatment.treatmentId}-${treatment.timeRange.from}`}>
                <div className="relative">
                  <span
                    className="absolute left-8 top-5 -ml-4 hidden h-full w-[2px] bg-stone-300 @[400px]:flex"
                    aria-hidden="true"
                  />
                  <div className="relative flex items-start">
                    <div className="relative -ml-4 hidden @[400px]:block">
                      <div className="mt-2 flex items-center justify-center rounded-md bg-stone-50 px-4 py-1.5">
                        <div className="text-xs font-medium text-stone-500">
                          {format(parseISO(treatment.timeRange.from), "HH:mm")}
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1">
                      <div
                        key={treatment.treatmentId}
                        className={`bg-${treatment.categoryColor}-50 border text-sm border-${treatment.categoryColor}-200 sm:text-sm text-${treatment.categoryColor}-900 flex flex-col rounded-md px-4 py-2`}>
                        <p>{treatment.treatmentName}</p>
                        <div className="mr-4 mt-1 flex items-center">
                          <UserIcon className="mr-1 h-3.5 w-3.5 shrink-0" aria-hidden="true" />
                          {treatment.employeeName}
                        </div>
                      </div>
                      <div className="mt-2">
                        <TextField
                          {...register(`treatments.${index}.finalPrice`, {
                            ...TREATMENTS.price,
                            min: 0,
                          })}
                          type="number"
                          label={t("appointments.totalPrice")}
                          errorMessage={
                            errors.treatments &&
                            errors.treatments[index]?.finalPrice &&
                            t(`validation.${errors.treatments[index]?.finalPrice?.type}`, {
                              name: t("appointments.totalPrice"),
                              ...TREATMENTS.price,
                              min: 0,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
            <li>
              <div className="relative">
                <div className="relative flex items-start">
                  <div className="-ml-4 mt-2 hidden items-center justify-center rounded-md bg-stone-50 px-4 py-2 @[400px]:flex">
                    <div className="text-xs font-medium text-stone-500">
                      {format(
                        parseISO(
                          appointment.treatments[appointment.treatments.length - 1].timeRange.to,
                        ),
                        "HH:mm",
                      )}
                    </div>
                  </div>
                  <div className="-mt-6 flex w-full justify-end rounded-md bg-stone-50 py-2 font-medium sm:mt-2">
                    <div className="flex items-center px-0.5 text-sm text-stone-700 sm:px-4">
                      <span className="mr-3 text-stone-600">{t("generic.total")}:</span>
                      <BanknotesIcon
                        className={`mr-1 h-4 w-4 text-stone-700 sm:h-3.5 sm:w-3.5`}
                        aria-hidden="true"
                      />
                      <span>
                        {formatPrice({
                          price: summarizeTreatmentsPrice(treatments),
                          currency: "PLN",
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <Controller
          name="paymentMethodId"
          control={control}
          render={({ field: { onChange: formOnChange, value = "" } }) => (
            <Select
              name="paymentMethodId"
              value={value}
              selectBy="value"
              placeholder={t("paymentMethods.placeholder")}
              onChange={formOnChange}
              options={paymentMethodsMapped}
              label={t("paymentMethods.label")}
              renderCustomSelectButton={() => (
                <Listbox.Button
                  as="div"
                  className="relative flex h-11 w-full cursor-pointer appearance-none items-center rounded-md border border-stone-300 bg-white pl-3 pr-20 text-left text-sm transition-colors duration-300 hover:border-stone-400 focus:border-stone-400 focus:outline-none focus:ring-stone-400 disabled:bg-stone-100">
                  {renderCustomSelectButton(value)}
                  <div className="absolute inset-y-2 right-0 flex items-center gap-1.5 pr-2">
                    <span className="pointer-events-none -ml-0.5 flex items-center">
                      <ChevronUpDownIcon className="h-5 w-5 text-stone-400" aria-hidden="true" />
                    </span>
                  </div>
                </Listbox.Button>
              )}
              renderCustomSelectOptions={() => (
                <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-stone-300 focus:outline-none">
                  {paymentMethodsMapped.map((option) => (
                    <Listbox.Option key={option.value} value={option.value} as={Fragment}>
                      {({ active }) => (
                        <li
                          className={cn(
                            "relative cursor-pointer select-none px-4 py-2 text-stone-600",
                            {
                              "bg-stone-50 text-stone-700": active && option.value !== value,
                              "bg-stone-100": option.value === value,
                            },
                          )}>
                          <div className="flex items-center gap-2">
                            <PaymentMethod paymentMethodId={option.value} />
                          </div>
                        </li>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              )}
            />
          )}
        />

        <div className="flex gap-4 pt-4">
          <Button variant="secondary-outline" fullWidth onClick={onBackClick}>
            {t("generic.back")}
          </Button>
          <Button
            type="submit"
            fullWidth
            className="col-span-2"
            disabled={!isValid || loadingFinalize}>
            {t("generic.confirm")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
