import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentTreatmentFragmentDoc } from './AppointmentTreatment.generated';
export type AppointmentFragment = { __typename?: 'Appointment', appointmentId: any, clientId?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodId?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentId: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> };

export const AppointmentFragmentDoc = gql`
    fragment Appointment on Appointment {
  appointmentId
  clientId
  clientName
  clientDisplayName @client
  note
  isSelfBooked
  imagesUrls {
    imageUrl
    appointmentId
    insertedAt
    updatedAt
  }
  status
  totalPrice
  paymentMethodId
  treatments {
    ...AppointmentTreatment
  }
  insertedAt
}
    ${AppointmentTreatmentFragmentDoc}`;
