import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientFragmentDoc } from '../fragments/Client.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteClientMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
}>;


export type DeleteClientMutation = { __typename?: 'RootMutationType', deleteClient: { __typename?: 'Client', id: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, phoneNew?: { __typename?: 'Phone', countryCode: string, number: string } | null, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, id: any }> } };


export const DeleteClientDocument = gql`
    mutation DeleteClient($id: UUID4!) {
  deleteClient(clientId: $id) {
    ...Client
  }
}
    ${ClientFragmentDoc}`;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
