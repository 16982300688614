import { UserIcon } from "@heroicons/react/24/outline";

import { Avatar } from "@/components/ui/Avatar";
import { CalendarViews } from "@/features/calendar/models";
import { CalendarEmployee } from "@/types";

import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";

type CalendarWeekEmployeesColumnProps = {
  employees: Array<CalendarEmployee>;
};

export const CalendarWeekEmployeesColumn = ({ employees }: CalendarWeekEmployeesColumnProps) => {
  const { view, setView } = useCalendarView();
  const { setFilters } = useCalendarFilters();

  const handleButtonClick = (employee: CalendarEmployee) => {
    if (view === CalendarViews.EmployeeWeek) return;

    setFilters({ employeeId: employee.accountId });
    setView(CalendarViews.EmployeeWeek);
  };

  return (
    <div className="w-1/8 flex max-h-full border-r">
      <div className="flex w-full flex-col bg-stone-50 pt-10 text-center text-xs font-semibold leading-6 text-stone-700">
        {employees.map((employee, index) => (
          <button
            className="flex h-full w-full flex-col items-center justify-center border-b px-3 transition-colors duration-200 last:border-transparent hover:bg-stone-100"
            key={index}
            onClick={() => handleButtonClick(employee)}>
            <Avatar imageUrl={employee.imageUrl} fallbackIcon={UserIcon} className="mr-0" />
            <span className="w-full self-center">{employee.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
