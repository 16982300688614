import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SEX_LABELS_MAP } from "@/features/clients/constants";
import { useClientStore } from "@/features/clients/hooks/use-client-store";
import { useToasts } from "@/providers/ToastsProvider";
import type { AddClientInput, AddClientMutation, Client, UpdateClientMutation } from "@/types";
import { Option } from "@/utils/select-utils";

import { ClientFormProps } from "../components/ClientFormDialog";

type Input = AddClientInput & {
  phoneOption?: Option;
};

export const useClientForm = ({ client, action, onClose, onSave }: ClientFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();

  const tagsIds = useMemo(() => client?.tags.map((tag) => tag.id), [client?.tags]);

  const form = useForm<Input>({
    shouldUnregister: true,
    defaultValues: {
      ...client,
      tagsIds: tagsIds ?? [],
      phoneNew: {
        countryCode: client?.phoneNew?.countryCode ?? "+48",
        number: client?.phoneNew?.number,
      },
    },
  });

  const {
    loadingUpdate,
    updateError,
    updateClient,
    addClient,
    addError,
    loadingAdd,
    deleteClient,
  } = useClientStore(client?.id);

  const clientGenderSelectOptions = useMemo(
    () =>
      Object.keys(SEX_LABELS_MAP).map((key) => ({
        value: key,
        label: t(SEX_LABELS_MAP[key]),
      })),
    [SEX_LABELS_MAP],
  );

  const mutationError = updateError || addError;
  const isMutationLoading = loadingUpdate || loadingAdd;

  const handleClientSubmit: SubmitHandler<Input> = async (data) => {
    if (!data.phoneNew?.number) {
      delete data.phoneNew;
    }

    const { errors, data: clientData } =
      action === "update" ? await updateClient(data, client?.id) : await addClient(data);

    const clientResponse: Client =
      action === "update"
        ? (clientData as UpdateClientMutation).updateClient
        : (clientData as AddClientMutation).addClient;

    if (!errors) {
      clientResponse && onSave && onSave(clientResponse);
      showToast({
        title:
          action === "update" ? t("clients.updateClientSuccess") : t("clients.addClientSuccess"),
      });
      onClose?.();
    }
  };

  /* re-render form when tagsIds change - this won't happen on prop change as expected, because defaultValues matter only on first render */
  useEffect(() => {
    form.reset({
      ...form.getValues(),
      tagsIds,
    });
  }, [tagsIds]);

  return {
    form,
    clientGenderSelectOptions,
    mutationError,
    isMutationLoading,
    handleClientSubmit,
    deleteClient,
  };
};
