import { PlusIcon } from "@heroicons/react/24/outline";

import { CustomActionDialog } from "@/components/dialogs/CustomActionDialog";
import { Button } from "@/components/ui/Button";
import { useCustomActionDialog } from "@/hooks/use-custom-action-dialog";
import { useUserAgent } from "@/hooks/use-user-agent";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

import { useCalendarDialogs } from "../contexts/CalendarDialogContext";

export const CalendarFooter = () => {
  const { isIOS } = useUserAgent();
  const { permissions } = useSessionContext();
  const { openAddSlotDialog } = useCalendarDialogs();
  const customActionDialogState = useCustomActionDialog();

  return (
    <div
      className={cn(
        isIOS ? "bottom-16" : "bottom-20",
        "fixed right-4 rounded-full p-1 drop-shadow-[0_-4px_4px_rgb(0,0,0,0.07)]",
      )}>
      <Button
        className="rounded-xl p-2.5"
        fullWidth
        disabled={!permissions.add_appointment && !permissions.add_slot_block}
        onClick={() => openAddSlotDialog()}>
        <PlusIcon className="h-6 w-6" />
      </Button>
      <CustomActionDialog dialogState={customActionDialogState} />
    </div>
  );
};
