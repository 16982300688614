import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientTagFragmentDoc } from './ClientTag.generated';
export type ClientFragment = { __typename?: 'Client', id: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, phoneNew?: { __typename?: 'Phone', countryCode: string, number: string } | null, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, id: any }> };

export const ClientFragmentDoc = gql`
    fragment Client on Client {
  id
  email
  phone
  firstName
  lastName
  fullName @client
  sex
  insertedAt
  updatedAt
  postalCode
  city
  street
  streetNumber
  note
  phoneNew {
    countryCode
    number
  }
  imagesUrls
  tags {
    ...ClientTag
  }
}
    ${ClientTagFragmentDoc}`;
