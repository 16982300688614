import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonSmsTemplatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonSmsTemplatesQuery = { __typename?: 'RootQueryType', fetchSalonSmsTemplates: Array<{ __typename?: 'SalonSmsTemplate', description?: string | null, salonId: any, title: string, id: any, type?: string | null }> };


export const FetchSalonSmsTemplatesDocument = gql`
    query FetchSalonSmsTemplates {
  fetchSalonSmsTemplates {
    description
    salonId
    title
    id
    type
  }
}
    `;

/**
 * __useFetchSalonSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useFetchSalonSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonSmsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonSmsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonSmsTemplatesQuery, FetchSalonSmsTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonSmsTemplatesQuery, FetchSalonSmsTemplatesQueryVariables>(FetchSalonSmsTemplatesDocument, options);
      }
export function useFetchSalonSmsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonSmsTemplatesQuery, FetchSalonSmsTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonSmsTemplatesQuery, FetchSalonSmsTemplatesQueryVariables>(FetchSalonSmsTemplatesDocument, options);
        }
export type FetchSalonSmsTemplatesQueryHookResult = ReturnType<typeof useFetchSalonSmsTemplatesQuery>;
export type FetchSalonSmsTemplatesLazyQueryHookResult = ReturnType<typeof useFetchSalonSmsTemplatesLazyQuery>;
export type FetchSalonSmsTemplatesQueryResult = Apollo.QueryResult<FetchSalonSmsTemplatesQuery, FetchSalonSmsTemplatesQueryVariables>;
