import { useFetchSalonTagsQuery } from "@/features/clients/queries/tags/FetchSalonTags.generated";
import { Option } from "@/utils/select-utils";

export const useTagsStore = () => {
  const { data: clientTags, loading, refetch } = useFetchSalonTagsQuery();

  const tagsSelectOptions: Option[] =
    clientTags?.fetchSalonTags.map((tag) => ({
      label: tag.name,
      value: tag.id,
      props: { color: tag.color },
    })) ?? [];

  return {
    tagsSelectOptions,
    clientTags,
    loading,
    refetch,
  };
};
