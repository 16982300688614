import { useDeleteEquipmentItemMutation } from "@/features/equipment/mutations/DeleteEquipmentItem.generated";
import { useGetEquipmentItemQuery } from "@/features/equipment/mutations/GetEquipmentItem.generated";
import { useUpdateEquipmentItemMutation } from "@/features/equipment/mutations/UpdateEquipmentItem.generated";
import { CreateEquipmentItemInput, UpdateEquipmentItemInput, namedOperations } from "@/types";

import { useCreateEquipmentItemMutation } from "../mutations/CreateEquipmentItem.generated";
export const useItemStore = (equipmentItemId?: string) => {
  const { data: item, loading: loadingFetch } = useGetEquipmentItemQuery({
    variables: {
      equipmentItemId,
    },
    skip: !equipmentItemId,
  });

  const [create, { loading: loadingCreate }] = useCreateEquipmentItemMutation();
  const [update, { loading: loadingUpdate }] = useUpdateEquipmentItemMutation();
  const [deleteItem, { loading: loadingDelete }] = useDeleteEquipmentItemMutation();

  return {
    loading: loadingCreate || loadingFetch || loadingUpdate || loadingDelete,
    loadingCreate,
    loadingFetch,
    loadingUpdate,
    loadingDelete,
    data: item?.getEquipmentItem,
    create(data: CreateEquipmentItemInput) {
      return create({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchSalonEquipmentItems],
      });
    },
    update(data: UpdateEquipmentItemInput, id?: string) {
      return update({
        variables: { data, equipmentItemId: id ?? equipmentItemId },
        refetchQueries: [namedOperations.Query.FetchSalonEquipmentItems],
      });
    },
    del(id?: string) {
      return deleteItem({
        variables: { equipmentItemId: id ?? equipmentItemId },
        refetchQueries: [namedOperations.Query.FetchSalonEquipmentItems],
        update(cache) {
          cache.evict({ id: `EquipmentItem:${id ?? equipmentItemId}` });
          cache.gc();
        },
      });
    },
  };
};
