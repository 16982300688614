import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HeadCategoryFragmentDoc } from '../fragments/HeadCategory.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchHeadCategoriesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchHeadCategoriesQuery = { __typename?: 'RootQueryType', fetchHeadCategories: { __typename?: 'HeadCategoryConnection', count: number, edges: Array<{ __typename?: 'HeadCategoryEdge', node: { __typename?: 'HeadCategory', name: string, id: any, updatedAt?: any | null, salonId: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryId: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, id: any }> | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };


export const FetchHeadCategoriesDocument = gql`
    query FetchHeadCategories($first: Int, $query: String, $after: String) {
  fetchHeadCategories(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...HeadCategory
      }
    }
    count
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${HeadCategoryFragmentDoc}`;

/**
 * __useFetchHeadCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchHeadCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHeadCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHeadCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchHeadCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>(FetchHeadCategoriesDocument, options);
      }
export function useFetchHeadCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>(FetchHeadCategoriesDocument, options);
        }
export type FetchHeadCategoriesQueryHookResult = ReturnType<typeof useFetchHeadCategoriesQuery>;
export type FetchHeadCategoriesLazyQueryHookResult = ReturnType<typeof useFetchHeadCategoriesLazyQuery>;
export type FetchHeadCategoriesQueryResult = Apollo.QueryResult<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>;
