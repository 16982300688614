import { Fragment, InputHTMLAttributes, useRef, useState } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import { Listbox } from "@headlessui/react";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

import { Float } from "@headlessui-float/react";

import { Option } from "@/utils/select-utils";
import { cn } from "@/utils/utils";

import { countryCodes } from "./constants/phone-country-codes";

type PhoneFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  control: Control<any>;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  showLabel?: boolean;
  useDeprecated?: boolean;
};

export const PhoneField = ({
  label,
  name,
  control,
  required,
  errorMessage,
  showLabel = true,
  useDeprecated = false,
  ...delegated
}: PhoneFieldProps) => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const options = countryCodes.map((country) => ({
    value: country.code,
    label: t(`countries.${country.name}`),
    props: {
      code: country.code,
      flag: country.flag,
      iso: country.iso,
      name: country.name,
    },
  }));

  const defaultCountryCode = useDeprecated ? "PL" : getValues("phoneNew.countryCode");

  const defaultOption = options.find((option) => option.value === defaultCountryCode) || options[0];

  const [selectedOption, setSelectedOption] = useState<Option | null>(defaultOption);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: Option) => {
    setSelectedOption(value);
  };

  return (
    <div className={cn("relative", { "space-y-1": showLabel })}>
      {showLabel && (
        <label htmlFor={name} className="block text-xs text-stone-500">
          {label || name}
        </label>
      )}

      <div className="relative flex h-11 w-full items-center rounded-md border border-stone-300">
        {!useDeprecated && (
          <Controller
            control={control}
            name={"phoneNew.countryCode"}
            defaultValue={selectedOption?.props.code}
            render={({ field: { onChange } }) => (
              <Listbox
                value={selectedOption}
                disabled={useDeprecated}
                onChange={(option: Option) => {
                  onChange(option.props.code);

                  handleChange(option);
                }}>
                <Float
                  as="div"
                  className="h-full"
                  placement="bottom-start"
                  offset={4}
                  flip={10}
                  zIndex={10}
                  floatingAs={Fragment}>
                  <Listbox.Button
                    as="div"
                    className="flex h-full cursor-pointer items-center gap-3 rounded-l-md border-r border-stone-300 pl-4 pr-2 text-sm placeholder-stone-400 focus:border-none focus:outline-none focus:ring-0">
                    <span className="scale-125">{selectedOption?.props.flag}</span>
                    <span className="pointer-events-none -ml-0.5 flex items-center">
                      <ChevronUpDownIcon className="h-5 w-5 text-stone-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-stone-300 focus:outline-none">
                    {options.map((option) => (
                      <Listbox.Option key={option.value} value={option} as={Fragment}>
                        {({ active }) => (
                          <li
                            className={cn(
                              "relative flex cursor-pointer select-none gap-3 px-4 py-2 text-stone-600",
                              {
                                "bg-stone-50 text-stone-700": active,
                                "bg-stone-100": option.value === selectedOption?.value,
                              },
                            )}>
                            <span className="scale-125">{option.props.flag}</span>
                            {option.label}
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Float>
              </Listbox>
            )}
          />
        )}

        {/* Invisible Controller for phoneNew.number if not useDeprecated. It is used because PhoneInput lib uses E.164 format as a value (with countryCode) */}
        {!useDeprecated && (
          <Controller
            control={control}
            name="phoneNew.number"
            render={({ field: { value } }) => <input type="hidden" value={value} />}
          />
        )}

        <Controller
          control={control}
          name="phone"
          rules={{
            required,
            validate: (value) => value && isPossiblePhoneNumber(value, selectedOption?.props.iso),
          }}
          render={({ field: { onChange, value } }) => (
            <div className="flex h-full w-full">
              <div
                className={cn(
                  useDeprecated ? "rounded-md" : "rounded-r-md",
                  "group flex h-full w-full cursor-text items-center bg-white pl-3 text-sm text-stone-400",
                )}
                onClick={() => inputRef.current?.focus()}>
                <div>
                  <span>{selectedOption?.props.code}</span>
                </div>
                <PhoneInput
                  control={control}
                  name="phone"
                  defaultValue={value}
                  value={value}
                  onChange={(value: string) => {
                    const number = (value && value.replace(selectedOption?.props.code, "")) || "";

                    !useDeprecated && setValue("phoneNew.number", number);

                    onChange(value);
                  }}
                  country={selectedOption?.props.iso}
                  defaultCountry="PL"
                  {...delegated}
                  ref={inputRef}
                  className={cn(
                    useDeprecated ? "rounded-md" : "rounded-r-md",
                    "flex-0 flex h-full w-full appearance-none items-center border-none pl-2 text-sm text-stone-700 placeholder-stone-400 focus:border-none focus:outline-none focus:ring-0",
                  )}
                />
              </div>
            </div>
          )}
        />
      </div>
      {errorMessage && <span className="block text-xs text-red-600">{errorMessage}</span>}
    </div>
  );
};
