import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentDoc } from './Category.generated';
export type HeadCategoryFragment = { __typename?: 'HeadCategory', name: string, id: any, updatedAt?: any | null, salonId: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryId: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, id: any }> | null };

export const HeadCategoryFragmentDoc = gql`
    fragment HeadCategory on HeadCategory {
  categories {
    ...Category
  }
  name
  id
  updatedAt
  salonId
  isDeleted
  insertedAt
}
    ${CategoryFragmentDoc}`;
