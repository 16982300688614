import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HeadCategoryFragmentDoc } from '../fragments/HeadCategory.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHeadCategoryMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['UUID4'];
  data: Types.UpdateHeadCategoryInput;
}>;


export type UpdateHeadCategoryMutation = { __typename?: 'RootMutationType', updateHeadCategory: { __typename?: 'HeadCategory', name: string, id: any, updatedAt?: any | null, salonId: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryId: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, id: any }> | null } };


export const UpdateHeadCategoryDocument = gql`
    mutation UpdateHeadCategory($categoryId: UUID4!, $data: UpdateHeadCategoryInput!) {
  updateHeadCategory(categoryId: $categoryId, data: $data) {
    ...HeadCategory
  }
}
    ${HeadCategoryFragmentDoc}`;
export type UpdateHeadCategoryMutationFn = Apollo.MutationFunction<UpdateHeadCategoryMutation, UpdateHeadCategoryMutationVariables>;

/**
 * __useUpdateHeadCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateHeadCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeadCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeadCategoryMutation, { data, loading, error }] = useUpdateHeadCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHeadCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeadCategoryMutation, UpdateHeadCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeadCategoryMutation, UpdateHeadCategoryMutationVariables>(UpdateHeadCategoryDocument, options);
      }
export type UpdateHeadCategoryMutationHookResult = ReturnType<typeof useUpdateHeadCategoryMutation>;
export type UpdateHeadCategoryMutationResult = Apollo.MutationResult<UpdateHeadCategoryMutation>;
export type UpdateHeadCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateHeadCategoryMutation, UpdateHeadCategoryMutationVariables>;
