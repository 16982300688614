import { MouseEvent, Touch, TouchEvent, useState } from "react";

import {
  DEFAULT_APPOINTMENT_DURATION_STEPS,
  SCALE_MINUTES_PER_STEP,
  SCALE_STEPS_PER_DAY,
  TOUCH_EVENT_DISTANCE_LIMIT,
  TOUCH_EVENT_TIME_LIMIT,
} from "@features/calendar/constants";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { getTimeWithOffset } from "@/features/calendar/utils/time";
import { useSessionContext } from "@/providers/SessionProvider";
import { CalendarEmployee, Timerange } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarDialogs } from "../../contexts/CalendarDialogContext";

const calculateRange = (element: HTMLDivElement, clientY: number): Timerange => {
  const { top, height } = element.getBoundingClientRect();
  const dy = Math.floor(((clientY - top) * SCALE_STEPS_PER_DAY) / height);
  const from = getTimeWithOffset(dy, SCALE_MINUTES_PER_STEP);
  const to = getTimeWithOffset(dy + DEFAULT_APPOINTMENT_DURATION_STEPS, SCALE_MINUTES_PER_STEP);

  return { from, to };
};

type CalendarDayPlaceholderProps = {
  employees: Array<CalendarEmployee>;
  hasMoreThanOneEmployee: boolean;
};

export const CalendarDayPlaceholder = ({
  employees,
  hasMoreThanOneEmployee,
}: CalendarDayPlaceholderProps) => {
  const { isSm } = useBreakpoint("sm");
  const [startTouch, setStartTouch] = useState<Touch | null>(null);

  const { permissions } = useSessionContext();
  const { openAddSlotDialog } = useCalendarDialogs();
  const { selectedDate } = useCalendarDate();

  const handleColClick = (event: MouseEvent<HTMLDivElement>, employeeId: string) => {
    if (!permissions.add_appointment && !permissions.add_slot_block) return;

    const { from, to } = calculateRange(event.target as HTMLDivElement, event.clientY);

    openAddSlotDialog({
      date: selectedDate,
      from,
      to,
      employeeId,
    });
  };

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    if (!permissions.add_appointment && !permissions.add_slot_block) return;

    setStartTouch(event.touches[0]);

    setTimeout(() => setStartTouch(null), TOUCH_EVENT_TIME_LIMIT);
  };

  const handleTouchEnd = (event: TouchEvent<HTMLDivElement>, employeeId: string) => {
    if (event.cancelable) {
      event.preventDefault();
    }

    if (startTouch) {
      const endTouch = event.changedTouches[0];
      const dx = Math.pow(startTouch.pageX - endTouch.pageX, 2);
      const dy = Math.pow(startTouch.pageY - endTouch.pageY, 2);
      const distance = Math.round(Math.sqrt(dx + dy));

      if (distance <= TOUCH_EVENT_DISTANCE_LIMIT) {
        const { from, to } = calculateRange(event.target as HTMLDivElement, endTouch.clientY);

        openAddSlotDialog({
          date: selectedDate,
          from,
          to,
          employeeId,
        });
      }
    }
  };

  return (
    <>
      {isSm ? (
        <div
          className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-stone-200"
          style={{ gridTemplateColumns: `repeat(${employees.length}, minmax(250px, 1fr))` }}>
          {employees.map((employee, index) => (
            <div
              key={employee.accountId}
              className={cn(
                "z-10 row-span-full mt-7 opacity-30 hover:bg-gold-50",
                `col-start-${index + 1}`,
              )}
              onClick={(event) => handleColClick(event, employee.accountId)}></div>
          ))}
          {hasMoreThanOneEmployee && (
            <div className="col-start-8 row-span-full hidden w-8 sm:block"></div>
          )}
        </div>
      ) : (
        <>
          <div
            className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-stone-200"
            style={{ gridTemplateColumns: `repeat(${employees.length}, minmax(0, 1fr))` }}>
            {employees.map((employee, index) => (
              <div
                className={cn("z-10 row-span-full mt-7", `col-start-${index + 1}`)}
                key={employee.accountId}
                onTouchStart={handleTouchStart}
                onTouchEnd={(event) => handleTouchEnd(event, employee.accountId)}></div>
            ))}
            {hasMoreThanOneEmployee && (
              <div className="col-start-8 row-span-full hidden w-8 sm:block"></div>
            )}
          </div>
        </>
      )}
    </>
  );
};
