import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendSmsToClientsMutationVariables = Types.Exact<{
  clientsIds: Array<Types.Scalars['UUID4']> | Types.Scalars['UUID4'];
  message: Types.Scalars['String'];
}>;


export type SendSmsToClientsMutation = { __typename?: 'RootMutationType', sendSmsToClients: string };


export const SendSmsToClientsDocument = gql`
    mutation SendSmsToClients($clientsIds: [UUID4!]!, $message: String!) {
  sendSmsToClients(clientsIds: $clientsIds, message: $message)
}
    `;
export type SendSmsToClientsMutationFn = Apollo.MutationFunction<SendSmsToClientsMutation, SendSmsToClientsMutationVariables>;

/**
 * __useSendSmsToClientsMutation__
 *
 * To run a mutation, you first call `useSendSmsToClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsToClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsToClientsMutation, { data, loading, error }] = useSendSmsToClientsMutation({
 *   variables: {
 *      clientsIds: // value for 'clientsIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendSmsToClientsMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsToClientsMutation, SendSmsToClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsToClientsMutation, SendSmsToClientsMutationVariables>(SendSmsToClientsDocument, options);
      }
export type SendSmsToClientsMutationHookResult = ReturnType<typeof useSendSmsToClientsMutation>;
export type SendSmsToClientsMutationResult = Apollo.MutationResult<SendSmsToClientsMutation>;
export type SendSmsToClientsMutationOptions = Apollo.BaseMutationOptions<SendSmsToClientsMutation, SendSmsToClientsMutationVariables>;
