import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTreatmentMutationVariables = Types.Exact<{
  treatmentId: Types.Scalars['UUID4'];
  data: Types.UpdateTreatmentInput;
}>;


export type UpdateTreatmentMutation = { __typename?: 'RootMutationType', updateTreatment: { __typename?: 'Treatment', name: string, categoryId: any, duration: number } };


export const UpdateTreatmentDocument = gql`
    mutation UpdateTreatment($treatmentId: UUID4!, $data: UpdateTreatmentInput!) {
  updateTreatment(treatmentId: $treatmentId, data: $data) {
    name
    categoryId
    duration
  }
}
    `;
export type UpdateTreatmentMutationFn = Apollo.MutationFunction<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>;

/**
 * __useUpdateTreatmentMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentMutation, { data, loading, error }] = useUpdateTreatmentMutation({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>(UpdateTreatmentDocument, options);
      }
export type UpdateTreatmentMutationHookResult = ReturnType<typeof useUpdateTreatmentMutation>;
export type UpdateTreatmentMutationResult = Apollo.MutationResult<UpdateTreatmentMutation>;
export type UpdateTreatmentMutationOptions = Apollo.BaseMutationOptions<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>;
