import { useEffect, useState } from "react";

import { SCALE_MINUTES_PER_STEP, SCALE_STEPS_PER_DAY } from "@features/calendar/constants";
import { getEmployeeUnavailabilityRanges } from "@features/salon/utils";

import { useSalonOpeningHoursStore } from "@features/salon/hooks";

import { getDivisionOffset, getDivisionsDistance } from "@/features/calendar/utils/time";
import { useEmployeesStore } from "@/features/employees/hooks";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { Timerange } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";

type CalendarDayAvailabilityProps = {
  selectedEmployeeId?: string;
};

const CalendarDaysClosedTimerange = ({ from, to }: Timerange) => {
  const rowOffset = getDivisionOffset(from, 2, SCALE_MINUTES_PER_STEP);
  const rowsCount = getDivisionsDistance(from, to, SCALE_MINUTES_PER_STEP);

  return (
    <div
      className="bg-stone-100"
      style={{
        gridRow: `${rowOffset} / span ${rowsCount}`,
      }}></div>
  );
};

export const CalendarDayAvailability = ({ selectedEmployeeId }: CalendarDayAvailabilityProps) => {
  const { timeRange, selectedDate } = useCalendarDate();
  const { regular, special, loading: openingHoursLoading } = useSalonOpeningHoursStore();
  const { employeesWithMappedWorkSchedule, loading: employeesLoading } = useEmployeesStore();
  const [closedTimeranges, setClosedTimeranges] = useState<Timerange[][]>([]);
  const { isSm } = useBreakpoint("sm");

  const findEmployee = (employeeId: string) => {
    const employee = employeesWithMappedWorkSchedule.find(
      (employee) => employee.employeeId === employeeId,
    );

    return employee ? [employee] : [];
  };

  const employees = selectedEmployeeId
    ? findEmployee(selectedEmployeeId)
    : employeesWithMappedWorkSchedule;

  const loading = openingHoursLoading || employeesLoading;

  useEffect(() => {
    const dayTimeRange = isSm ? timeRange : { from: selectedDate, to: selectedDate };

    const timeranges = employees.map(({ workSchedule }) =>
      getEmployeeUnavailabilityRanges({ regular, special, workSchedule, timeRange: dayTimeRange }),
    );

    setClosedTimeranges(timeranges.flat());
  }, [loading, selectedDate, timeRange]);

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-stone-200"
      style={{
        gridTemplateColumns: `repeat(${employees.length}, minmax(250px, 1fr))`,
      }}>
      {employees.map((employee, index) => (
        <div
          key={employee.employeeId}
          className={cn("row-span-full grid", `col-start-${index + 1}`)}
          style={{
            gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
          }}>
          {closedTimeranges[index]?.map((timerange, timerangeIndex) => (
            <CalendarDaysClosedTimerange key={timerangeIndex} {...timerange} />
          ))}
        </div>
      ))}
      {employeesWithMappedWorkSchedule.length > 1 && (
        <div className="col-start-8 row-span-full hidden w-8 sm:block"></div>
      )}
    </div>
  );
};
