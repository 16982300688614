import { Spinner } from "@/components/ui/Spinner";
import { SMSTemplateForm } from "@/features/messages/components/SMSTemplateForm";
import { MESSAGES_VARIABLES } from "@/features/messages/constants/index";
import { useSalonSmsTemplatesStore } from "@/features/messages/hooks";

export const SmsTemplates = () => {
  const { templates, loading } = useSalonSmsTemplatesStore();

  const { appointmentDate, clientName, cancelLink, appointmentTime } = MESSAGES_VARIABLES;

  return (
    <div>
      <div>
        {loading ? (
          <Spinner className="h-[25vh]" />
        ) : (
          <ul className="grid gap-2">
            {templates?.map(
              (template) =>
                template && (
                  <li key={template.id} className="block">
                    <SMSTemplateForm
                      template={template}
                      variables={[appointmentDate, clientName, appointmentTime].concat(
                        template.type !== "canceled" ? [cancelLink] : [],
                      )}
                    />
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
