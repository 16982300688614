import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BanknotesIcon,
  ChatBubbleBottomCenterTextIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";

import { DURATION_MULTIPLIER } from "@constants/index";
import { GENERIC_NAME, TREATMENTS } from "@constants/validations";
import { integerToPrice, priceToInteger } from "@features/payments/utils";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Radio } from "@components/ui/Radio";
import { Switch } from "@components/ui/Switch";
import { TextAreaField } from "@components/ui/TextAreaField";
import { TextField } from "@components/ui/TextField";

import { useTreatmentStore } from "@features/services/hooks/use-treatment-store";

import { Select } from "@/components/ui/Select";
import { AddTreatmentInput, Treatment } from "@/types";

import { useHeadCategoriesStore } from "../../hooks";

export type TreatmentFormProps = {
  treatment?: Treatment;
  onRemove?: (treatment: Treatment) => Promise<boolean>;
};

type TreatmentForm = TreatmentFormProps & {
  onClose: () => void;
};

type TreatmentFormDialogProps = {
  props?: TreatmentFormProps;
  open: boolean;
  onClose: () => void;
};

type FormData = Omit<AddTreatmentInput, "isExactPrice"> & {
  priceType: "exact" | "varying";
};

export const TreatmentFormDialog = ({ open, props, onClose }: TreatmentFormDialogProps) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={
        props?.treatment ? (
          <span>{t("services.treatments.edit")}</span>
        ) : (
          <span>{t("services.treatments.addNew")}</span>
        )
      }>
      <TreatmentForm onClose={onClose} treatment={props?.treatment} onRemove={props?.onRemove} />
    </DefaultDialog>
  );
};

const TreatmentForm = ({ treatment, onClose, onRemove }: TreatmentForm) => {
  const { t } = useTranslation();
  const { loadingAdd, loadingUpdate, addTreatment, updateTreatment } = useTreatmentStore();
  const { groupedCategoryOptions, loading } = useHeadCategoriesStore();

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    control,
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      ...treatment,
      priceType: treatment?.isExactPrice ?? true ? "exact" : "varying",
      price: treatment?.price ? integerToPrice(treatment.price) : 0,
      priceFrom: treatment?.priceFrom ? integerToPrice(treatment.priceFrom) : 0,
      priceTo: treatment?.priceTo ? integerToPrice(treatment.priceTo) : 0,
      duration: treatment?.duration ? treatment.duration / DURATION_MULTIPLIER : 0,
      isPublic: treatment?.isPublic,
    },
  });

  const priceType = useWatch({
    name: "priceType",
    control,
  });

  const handleTreatmentForm = async (formData: FormData) => {
    const data = {
      ...formData,
      priceType: undefined,
      isExactPrice: formData.priceType === "exact",
      price: formData.price ? priceToInteger(formData.price) : undefined,
      priceFrom: formData.priceFrom ? priceToInteger(formData.priceFrom) : undefined,
      priceTo: formData.priceTo ? priceToInteger(formData.priceTo) : undefined,
      duration: formData.duration * DURATION_MULTIPLIER,
    };

    const response = treatment
      ? await updateTreatment(data, treatment.id)
      : await addTreatment(data);

    if (!response.errors) {
      onClose();
    }
  };

  const handleTreatmentDelete = async () => {
    if (!onRemove || !treatment) return;

    const result = await onRemove(treatment);
    result && onClose();
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(handleTreatmentForm)} noValidate>
      <div>
        <TextField
          {...register("name", GENERIC_NAME)}
          placeholder={"Laseroterapia"}
          label={t("services.serviceName")}
          startIcon={<ChatBubbleBottomCenterTextIcon />}
          errorMessage={
            errors.name &&
            t(`validation.${errors.name.type}`, {
              name: t("services.serviceName"),
              ...GENERIC_NAME,
            })
          }
        />
      </div>
      <Controller
        name="categoryId"
        control={control}
        render={({ field: { onChange: formOnChange, value = "" } }) => (
          <Select
            name="categoryId"
            label={t("generic.serviceCategory")}
            options={groupedCategoryOptions}
            placeholder={t("placeholders.selectCategory")}
            disabled={loading}
            selectBy="value"
            onChange={(data) => formOnChange(data)}
            value={value}
          />
        )}
      />
      <div>
        <TextField
          {...register("duration", {
            ...TREATMENTS.duration,
          })}
          type="number"
          label={t("services.duration")}
          placeholder={t("services.categories.durationPlaceholder")}
          startIcon={<ClockIcon />}
          errorMessage={
            errors.duration &&
            t(`validation.${errors.duration.type}`, {
              name: t("services.duration"),
              ...TREATMENTS.duration,
            })
          }
        />
      </div>

      <div>
        <Radio
          name="priceType"
          control={control}
          label={t("services.priceType")}
          options={[
            {
              value: "exact",
              label: t("services.exactPrice"),
            },
            {
              value: "varying",
              label: t("services.varyingPrice"),
            },
          ]}
          required
        />
      </div>

      {priceType === "exact" ? (
        <TextField
          {...register("price", {
            ...TREATMENTS.price,
          })}
          label={t("services.price")}
          showLabel
          startIcon={<BanknotesIcon />}
          type="number"
          placeholder={"0,00"}
          errorMessage={
            errors.price &&
            t(`validation.${errors.price.type}`, {
              name: t("services.price"),
              ...TREATMENTS.price,
            })
          }
        />
      ) : (
        <div>
          <div className="flex w-full items-center gap-1 md:gap-2">
            <TextField
              {...register("priceFrom", {
                ...TREATMENTS.price,
                deps: ["priceTo"],
                validate: {
                  hasToBeLess: (value) => Number(value) < Number(getValues("priceTo")),
                },
              })}
              startIcon={<BanknotesIcon />}
              placeholder={"0,00"}
              type="number"
              label={t("services.priceFrom")}
              showLabel
            />
            <span className="pt-6 text-stone-400">&mdash;</span>
            <TextField
              {...register("priceTo", {
                ...TREATMENTS.price,
                deps: ["priceFrom"],
                validate: {
                  hasToBeGreater: (value) => Number(value) > Number(getValues("priceFrom")),
                },
              })}
              startIcon={<BanknotesIcon />}
              type="number"
              placeholder={"0,00"}
              label={t("services.priceTo")}
              showLabel
            />
          </div>

          {errors.priceFrom ? (
            <p className="pt-1 text-center text-red-500">
              {t(`validation.${errors.priceFrom.type}`, {
                x: t("services.priceFrom"),
                y: t("services.priceTo"),
                name: t("services.priceFrom"),
                ...TREATMENTS.price,
              })}
            </p>
          ) : (
            errors.priceTo && (
              <p className="pt-1 text-center text-red-500">
                {t(`validation.${errors.priceTo.type}`, {
                  x: t("services.priceFrom"),
                  y: t("services.priceTo"),
                  name: t("services.priceTo"),
                  ...TREATMENTS.price,
                })}
              </p>
            )
          )}
        </div>
      )}

      <Switch
        control={control}
        name="isPublic"
        label={t("selfBooking.makeTreatmentPublic")}
        showLabel
      />
      <TextAreaField
        {...register("description", { maxLength: 500 })}
        label={t("generic.description")}
        errorMessage={
          errors.description &&
          t(`validation.${errors.description.type}`, {
            name: t("generic.note"),
            maxLength: 500,
          })
        }
      />

      <div className="mt-4 flex flex-col items-center gap-4 md:flex-row">
        {treatment && (
          <Button variant="danger" fullWidth onClick={handleTreatmentDelete}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          variant="primary"
          fullWidth
          type="submit"
          disabled={!isValid || !isDirty || loadingAdd || loadingUpdate}>
          {treatment ? (
            <span>{t("services.treatments.edit")}</span>
          ) : (
            <span>{t("services.treatments.addNew")}</span>
          )}
        </Button>
      </div>
    </form>
  );
};
