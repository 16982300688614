import { Fragment, useCallback, useLayoutEffect, useMemo, useState } from "react";

import { Transition } from "@headlessui/react";

import {
  AUTOSCROLL_OFFSET,
  MINUTES_PER_DAY,
  SCALE_STEPS_PER_DAY,
} from "@features/calendar/constants";
import { SlotRowsByEmployee } from "@features/calendar/models";

import { useBreakpoint } from "@/hooks/use-breakpoint";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";
import { useCalendarStore } from "../../hooks/use-calendar-store";
import { CalendarDaysNavigation } from "../CalendarDaysNavigation";
import { CalendarLoader } from "../CalendarLoader";
import { CalendarScaleBar } from "../CalendarScaleBar";
import { CalendarSlotsGroup } from "../CalendarSlotsGroup";
import { CalendarDayAvailability } from "./CalendarDayAvailability";
import { CalendarDayEmployees } from "./CalendarDayEmployees";
import { CalendarDayPlaceholder } from "./CalendarDayPlaceholder";

export const CalendarDayContent = () => {
  const { view } = useCalendarView();
  const { filters } = useCalendarFilters();
  const { timeRange } = useCalendarDate();

  const { slotRowsByEmployee: slotGroups, loading } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });

  const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [scaleElement, setScaleElement] = useState<HTMLElement | null>(null);
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);

  const { isSm } = useBreakpoint("sm");

  const containerRef = useCallback(
    (element: HTMLElement | null) => setContainerElement(element),
    [],
  );
  const headerRef = useCallback((element: HTMLElement | null) => setHeaderElement(element), []);
  const scaleRef = useCallback((element: HTMLElement | null) => setScaleElement(element), []);

  const employeeFilter = useMemo(() => filters.employeeId, [filters]);
  const filteredSlotGroups = useMemo(() => {
    if (!employeeFilter) {
      return slotGroups;
    }

    return slotGroups.filter((slotGroup) => slotGroup.employee.accountId === employeeFilter);
  }, [slotGroups, employeeFilter]);
  const employees = useMemo(
    () => filteredSlotGroups.map((entry) => entry.employee),
    [filteredSlotGroups],
  );
  const slotGroupsEmployeesCount = useMemo(() => employees.length, [employees]);

  const handleResize = () => {
    if (!containerElement || !headerElement || !scaleElement) return;

    const currentPeriodOffset = new Date().getHours() * 60 - AUTOSCROLL_OFFSET || 0;

    const scrollableHeight =
      containerElement.scrollHeight -
      (headerElement?.offsetHeight || 0) -
      ((scaleElement?.firstChild as HTMLDivElement)?.offsetHeight || 0);

    const offset = (scrollableHeight * currentPeriodOffset) / MINUTES_PER_DAY;

    containerElement.scrollTo({ top: offset, behavior: "smooth" });
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerElement, headerElement, scaleElement]);

  useLayoutEffect(() => {
    const calcIndicatorOffset = () => {
      if (!scaleElement) return;

      const containerHeight = scaleElement?.offsetHeight || 0;
      const offsetHeight = (scaleElement?.firstChild as HTMLElement)?.offsetHeight || 0;
      const height = containerHeight - offsetHeight;
      const pxPerMinute = height > 0 ? height / MINUTES_PER_DAY : 0;

      const now = new Date();
      const currentMinute = now.getHours() * 60 + now.getMinutes();

      setIndicatorOffset(currentMinute * pxPerMinute);
    };

    calcIndicatorOffset();

    const intervalId = window.setInterval(calcIndicatorOffset, 5000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [scaleElement]);

  const isSalonOwnerHasEmployees = slotGroups.length > 1 || filters.employeeId !== undefined;

  return (
    <div className="relative flex h-full flex-col">
      {!isSm && <CalendarDaysNavigation ref={headerRef} />}
      <div
        ref={containerRef}
        className="isolate flex flex-auto flex-col overflow-auto overscroll-none border-t bg-white sm:rounded-md sm:border">
        {isSalonOwnerHasEmployees && (
          <div className="absolute left-0 z-50 h-[41px] w-[41px] border-b border-r border-stone-200 bg-white sm:top-0 sm:h-[42px] sm:rounded-tl-md sm:border-l sm:border-r-0 sm:border-t"></div>
        )}
        <div className="flex w-fit min-w-full flex-col 2xl:!w-full 2xl:max-w-full">
          {isSalonOwnerHasEmployees && (
            <>
              <CalendarDayEmployees ref={headerRef} employees={employees} />
            </>
          )}

          <div className="flex w-full min-w-max flex-auto pb-4 pt-0 sm:-mt-4 md:pb-0">
            <div className="sticky left-0 z-30 w-10 flex-none bg-white/60 ring-1 ring-stone-100 backdrop-blur-sm"></div>
            <div className="relative grid flex-auto grid-cols-1 grid-rows-1">
              <Transition
                show={indicatorOffset > 0}
                appear
                as={Fragment}
                enter="transition-[opacity,_right] duration-500 ease-in"
                enterFrom="opacity-0 right-full"
                enterTo="opacity-80 right-0"
                leave="transition-[opacity,_right] duration-500 ease-in"
                leaveFrom="opacity-80 right-0"
                leaveTo="opacity-0 right-full">
                <div
                  className="border-1 absolute left-0 right-0 z-30 mt-7 h-[2px] border border-white border-opacity-25 bg-red-500 before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-red-500 before:content-['']"
                  style={{ top: `${indicatorOffset}px` }}></div>
              </Transition>
              <CalendarScaleBar ref={scaleRef} />
              <CalendarDayAvailability selectedEmployeeId={employeeFilter} />
              {loading ? (
                <CalendarLoader />
              ) : (
                <>
                  <CalendarDayPlaceholder
                    employees={employees}
                    hasMoreThanOneEmployee={isSalonOwnerHasEmployees}
                  />

                  <ol
                    className={cn(
                      isSalonOwnerHasEmployees ? "sm:pr-8" : "sm:pr-0.5",
                      "col-start-1 col-end-2 row-start-1 grid",
                    )}
                    style={{
                      gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
                      gridTemplateColumns: `repeat(${slotGroupsEmployeesCount}, minmax(250px, 1fr))`,
                    }}>
                    {slotGroups.map((slotGroup: SlotRowsByEmployee, index) =>
                      slotGroup.slotRows.map((slotRow) => (
                        <CalendarSlotsGroup
                          key={slotRow.from}
                          group={slotRow}
                          employeeCount={!employeeFilter ? index : 0}
                        />
                      )),
                    )}
                  </ol>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
