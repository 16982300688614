import { useForm } from "react-hook-form";

import { DatePicker } from "@components/ui/DatePicker";

import { ClientSelect } from "@features/clients/components/ClientSelect";

import { EmployeeSelect } from "@/features/employees/components/EmployeeSelect";
import type { AppointmentFilters } from "@/types";

import { useCalendarDate } from "../contexts/CalendarDateContext";
import { useCalendarFilters } from "../contexts/CalendarFiltersContext";
import { useCalendarView } from "../contexts/CalendarViewContext";
import { CalendarViews } from "../models";

export const CalendarFilters = () => {
  const { view } = useCalendarView();
  const { selectedDate, setSelectedDate } = useCalendarDate();
  const { setFilters } = useCalendarFilters();
  const { control, resetField } = useForm<AppointmentFilters>();

  const handleFilterChange = (filterKey: keyof AppointmentFilters, value?: string) => {
    setFilters({ [filterKey]: value });
  };

  const handleFilterClear = (filterKey: keyof AppointmentFilters) => {
    setFilters({ [filterKey]: undefined });
    resetField(`filter_${filterKey}` as keyof AppointmentFilters);
  };

  return (
    <div className="row-span-2 min-w-[15rem] space-y-2 overflow-y-auto px-1 md:h-full md:w-full">
      {view !== CalendarViews.Month && (
        <div className="hidden w-full justify-center rounded-md border border-stone-300 p-0.5 sm:flex">
          <DatePicker inline borderless selected={selectedDate} onChange={setSelectedDate} />
        </div>
      )}
      {view === CalendarViews.Month && (
        <EmployeeSelect
          control={control}
          name="filter_employeeId"
          onChange={(data) => handleFilterChange("employeeId", data?.value as string)}
          onClear={() => handleFilterClear("employeeId")}
        />
      )}
      <ClientSelect
        control={control}
        name="filter_clientId"
        onChange={(data) => handleFilterChange("clientId", data?.value as string)}
        onClear={() => handleFilterClear("clientId")}
      />
      {/*
       * Element below used as a spacer so that there will be enough of space for dropdown
       * TODO: detect & handle dropdown direction and remove spacer
       */}
      <div className="h-[15rem] w-full"></div>
    </div>
  );
};
