import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { Button } from "@/components/ui/Button";
import { TextAreaField } from "@/components/ui/TextAreaField";
import { TextField } from "@/components/ui/TextField";
import { useItemStore } from "@/features/equipment/hooks/use-item-store";
import { TreatmentSelect } from "@/features/services/components/treatments/TreatmentSelect";
import { useSessionContext } from "@/providers/SessionProvider";
import { CreateEquipmentItemInput, EquipmentItem } from "@/types";
import { isNotWhitespace } from "@/utils/form";

export type EquipmentFormProps = {
  item?: EquipmentItem;
  onClose: () => void;
};

type Input = CreateEquipmentItemInput;

type EquipmentFormDialog = {
  open: boolean;
  props?: EquipmentFormProps;
  onClose: EquipmentFormProps["onClose"];
};

export const EquipmentDialog = ({ open, props, onClose }: EquipmentFormDialog) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={props?.item ? t("equipment.update") : t("equipment.addNew")}>
      <EquipmentForm {...props} onClose={onClose} />
    </DefaultDialog>
  );
};

const EquipmentForm = ({ item, onClose }: EquipmentFormProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionContext();
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<Input>({
    defaultValues: {
      ...item,
      treatmentsIds: item?.treatments.map((treatment) => treatment.treatmentId) ?? [],
    },
    shouldUnregister: true,
  });

  const { create, update, del } = useItemStore(item?.id);

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const { errors } = item ? await update(data) : await create(data);

    if (!errors) {
      onClose();
    }
  };

  return (
    <form className="relative grid gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: true,
          validate: {
            isNotWhitespace,
          },
        })}
        label={t("equipment.itemName")}
        required
        showLabel
        placeholder={t("equipment.placeholders.itemName")}
      />
      <TreatmentSelect
        control={control}
        name="treatmentsIds"
        label={t("equipment.treatmentsThatRequireItem")}
        multiple
      />

      <TextAreaField
        {...register("description")}
        label={t("equipment.itemDescription")}
        showLabel
        placeholder={t("equipment.placeholders.itemDescription")}
      />

      <div className="align-center mt-4 flex flex-col gap-4">
        {item && (
          <Button
            fullWidth
            variant="danger-outline"
            disabled={!permissions.manage_equipment}
            onClick={() => {
              del();
              onClose();
            }}>
            {t("generic.delete")}
          </Button>
        )}
        <Button fullWidth type="submit" disabled={!permissions.manage_equipment || !isValid}>
          {t("generic.save")}
        </Button>
      </div>
    </form>
  );
};
