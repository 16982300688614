import { AddTreatmentInput, namedOperations, UpdateTreatmentInput } from "@/types";

import { useAddTreatmentMutation } from "../mutations/AddTreatment.generated";
import {
  DeleteTreatmentMutationVariables,
  useDeleteTreatmentMutation,
} from "../mutations/DeleteTreatment.generated";
import { useUpdateTreatmentMutation } from "../mutations/UpdateTreatment.generated";
import { useFetchTreatmentQuery } from "../queries/FetchTreatment.generated";

export const useTreatmentStore = (treatmentId?: string) => {
  const [addTreatment, { loading: loadingAdd }] = useAddTreatmentMutation();
  const [updateTreatment, { loading: loadingUpdate }] = useUpdateTreatmentMutation();
  const [deleteTreatment] = useDeleteTreatmentMutation();
  const { data, loading } = useFetchTreatmentQuery({
    variables: { treatmentId },
    skip: !treatmentId,
  });

  return {
    treatment: data?.fetchTreatment,
    loading,
    loadingAdd,
    loadingUpdate,
    data: data?.fetchTreatment,
    deleteTreatment({ treatmentId }: DeleteTreatmentMutationVariables) {
      return deleteTreatment({
        variables: { treatmentId },
        update(cache) {
          cache.evict({
            id: cache.identify({
              id: treatmentId,
              __typename: "Treatment",
            }),
          });
          cache.evict({
            id: cache.identify({
              treatmentId,
              __typename: "SearchTreatment",
            }),
          });
          cache.gc();
        },
      });
    },
    updateTreatment(data: UpdateTreatmentInput, id: string) {
      return updateTreatment({
        variables: {
          treatmentId: id,
          data,
        },
        refetchQueries: [namedOperations.Query.FetchTreatments],
        update(cache) {
          cache.evict({ fieldName: "fetchAllTreatments" });
          cache.gc();
        },
      });
    },
    addTreatment(data: AddTreatmentInput) {
      return addTreatment({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchTreatments],
        update(cache) {
          cache.evict({ fieldName: "fetchAllTreatments" });
          cache.gc();
        },
      });
    },
  };
};
