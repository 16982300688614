import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Disclosure } from "@headlessui/react";

import {
  ArrowTopRightOnSquareIcon,
  ChevronUpIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

import { isPast } from "date-fns";

import { Badge } from "@/components/ui/Badge";
import { Button } from "@/components/ui/Button";
import { Tooltip } from "@/components/ui/Tooltip";
import { DATE_FORMAT } from "@/constants/date-formats";
import {
  AppointmentDetailsDialog,
  AppointmentDetailsProps,
} from "@/features/calendar/components/Appointment/AppointmentDetailsDialog";
import { formatDateTime } from "@/features/calendar/utils/time";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { Appointment, AppointmentStatus } from "@/types";

import { ClientAppointmentNote } from "./ClientAppointmentNote";

type ClientAppointmentProps = {
  appointment: Appointment;
};

export const ClientAppointment = ({ appointment }: ClientAppointmentProps) => {
  const { t } = useTranslation();
  const detailsDialogState = useDialog<AppointmentDetailsProps>();

  const getAppointmentBadgeData = (appointment: Appointment) => {
    if (appointment.status === AppointmentStatus.Completed) {
      return {
        color: "green",
        label: t("appointments.status.completed"),
      };
    }
    if (appointment.status === AppointmentStatus.Requested) {
      return {
        color: "gold",
        label: t("appointments.status.requested"),
      };
    }
    if (
      appointment.status === AppointmentStatus.Scheduled &&
      isPast(new Date(appointment.treatments[0].timeRange.to))
    ) {
      return {
        color: "blue",
        label: t("appointments.status.unfinalized"),
      };
    }
    if (
      appointment.status === AppointmentStatus.Scheduled &&
      !isPast(new Date(appointment.treatments[0].timeRange.to))
    ) {
      return {
        color: "fuchsia",
        label: t("appointments.status.scheduled"),
      };
    }
    if (appointment.status === AppointmentStatus.Closed) {
      return {
        color: "orange",
        label: t("appointments.status.closed"),
      };
    }
    if (appointment.status === AppointmentStatus.Canceled) {
      return {
        color: "red",
        label: t("appointments.status.canceled"),
      };
    }
    if (appointment.status === AppointmentStatus.CanceledByClient) {
      return {
        color: "red",
        label: t("appointments.status.canceledByClient"),
      };
    }
    return {
      color: "stone",
      label: t("appointments.status.unknown"),
    };
  };

  const badgeData = useMemo(() => getAppointmentBadgeData(appointment), [appointment]);

  return (
    <>
      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              as="div"
              className="relative w-full cursor-pointer justify-start gap-2 px-4 py-5 text-left transition-colors @container hover:bg-stone-100">
              <div className="flex w-full flex-col justify-between gap-1 @[480px]:flex-row">
                <div className="flex items-center gap-1 @[480px]:order-2 @[480px]:gap-2">
                  {badgeData && (
                    <Badge color={badgeData.color} bordered className="@[480px]:order-2">
                      {badgeData.label}
                    </Badge>
                  )}
                  <div className="ml-auto @[480px]:order-1">
                    {(appointment.note ||
                      (appointment.imagesUrls && appointment.imagesUrls.length > 0)) && (
                      <Tooltip
                        content={t("appointments.hasNote")}
                        children={
                          <DocumentTextIcon className="h-5 w-5 text-stone-400" />
                        }></Tooltip>
                    )}
                  </div>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } order-last h-5 w-5 text-stone-500`}
                  />
                </div>
                <div className="flex items-center gap-2 @[480px]:order-1">
                  <p className="inline-flex text-sm font-medium text-stone-600">
                    {formatDateTime(
                      appointment.treatments[0].timeRange.from,
                      DATE_FORMAT.DEFAULT_WITH_TIME,
                    )}
                  </p>
                  <Button
                    size="tiny"
                    variant="secondary-outline"
                    onClick={(e) => {
                      e.stopPropagation();

                      detailsDialogState.open({
                        appointmentId: appointment.appointmentId,
                      });
                    }}>
                    <span className="hidden @[480px]:block">
                      {t("appointments.openAppointment")}
                    </span>
                    <ArrowTopRightOnSquareIcon className="h-3.5 w-3.5 @[480px]:ml-1" />
                  </Button>
                </div>
              </div>
              <div className="mt-5 flex w-full flex-col gap-1">
                <div>
                  {appointment.treatments.map((treatment) => (
                    <div key={treatment.treatmentId} className="flex text-stone-500">
                      <p className="line-clamp-1">{treatment.treatmentName}</p>
                    </div>
                  ))}
                </div>
                <p className="text-xs text-stone-400 @[480px]:right-8">
                  {/* TODO: implement information who added appointment https://www.notion.so/estetify/CLIENT-APPOINTMENTS-Informacja-o-tym-kt-ry-pracownik-doda-wizyt-baf57c23cc104c68839ae6049d964890?pvs=4 */}
                  {t("appointments.addedAt", {
                    date: formatDateTime(appointment.insertedAt, DATE_FORMAT.DEFAULT_WITH_TIME),
                  })}
                </p>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="border-t bg-stone-50 px-8 py-4 text-left text-sm text-stone-500">
              <ClientAppointmentNote
                note={appointment.note}
                imagesUrls={appointment.imagesUrls}
                appointmentId={appointment.appointmentId}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <AppointmentDetailsDialog {...bindDialogState(detailsDialogState)} />
    </>
  );
};
