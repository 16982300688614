import { useTranslation } from "react-i18next";

import BgImage from "@assets/images/subscriptions/talk.png";

import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";

export const BusinessPlanCard = () => {
  const { t } = useTranslation();

  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return (
    <div className="mx-auto max-w-7xl">
      <section className="relative mt-8 rounded-xl bg-gold-800 shadow-lg shadow-stone-900/5">
        <div className="absolute bottom-0 h-72 w-full xl:inset-0 xl:h-full">
          <div className="h-full w-full xl:grid xl:grid-cols-3">
            <div className="col-span-2 h-full xl:relative xl:col-start-2">
              <img
                className="h-full w-full object-cover object-top opacity-25 xl:absolute xl:inset-0"
                src={BgImage}
                alt=""
              />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gold-800 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-4xl px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
          <div className="relative pb-64 pt-12 text-center sm:pb-64 sm:pt-12 md:text-left xl:col-start-1 xl:pb-12">
            <h3 className="text-center text-sm font-semibold text-gold-100 md:text-left">
              {t("pricing.tiers.business.name")}
            </h3>
            <h2 className="mt-5 text-center text-3xl tracking-tight text-gold-50 md:text-left">
              {t("pricing.letsTalk")}
            </h2>
            <p className="mx-auto mt-4 text-center text-sm leading-6 text-gold-300 md:ml-0 md:mr-auto md:w-3/4 md:text-left lg:w-2/3">
              {t("pricing.tiers.business.description")}
            </p>
            <Button
              className="mx-auto mt-6 border-none bg-white text-gold-900 hover:bg-stone-100 focus:ring-stone-100 md:ml-0 md:mr-auto lg:w-1/2"
              onClick={() =>
                feedbackDialogState.open({
                  subject: "partnership",
                })
              }>
              {t("pricing.tiers.business.cta.landing")}
            </Button>
          </div>
        </div>
      </section>
      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </div>
  );
};
