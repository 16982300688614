import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonFragment = { __typename?: 'Salon', salonId: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: Types.MinLeadTimeOptionsInMinutes, maxLeadTime: Types.MaxLeadTimeOptionsInDays, url: any } };

export const SalonFragmentDoc = gql`
    fragment Salon on Salon {
  salonId
  name
  phoneType
  phone
  mobile
  email
  city
  links
  logoImageUrl
  backgroundImageUrl
  postalCode
  street
  streetNumber
  socials
  description
  isPublic
  slug
  selfBooking {
    enabled
    slotDuration
    automaticalSlotApproval
    automaticalSlotApprovalSms
    minLeadTime
    maxLeadTime
    url
  }
}
    `;
