import { DeepPartial } from "react-hook-form";

import type {
  Appointment,
  AppointmentTreatment,
  BlockedSlot,
  CalendarEmployee,
  Timerange,
} from "@/types";

export enum CalendarViews {
  Day,
  Week,
  Month,
  EmployeeDay,
  EmployeeWeek,
}

export type CalendarColumn = UnknownCalendarSlot[];

export type SlotRow = {
  from: Timerange["from"];
  to: Timerange["to"];
  columns: CalendarColumn[];
};

export type SlotRowsByEmployee = {
  employee: CalendarEmployee;
  slotRows: SlotRow[];
};

export type SlotsAssignedToDays = Record<number, UnknownCalendarSlot[]>;

export type slotsAssignedToDaysByEmployee = {
  employee: CalendarEmployee;
  slotsAssignedToDays: SlotsAssignedToDays;
};

export type DisplayedMonthDay = {
  date: Date;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  slots: UnknownCalendarSlot[];
};

export type CalendarBlockedSlot = BlockedSlot & CommonSlotValues;

export type AppointmentTreatmentSlot = AppointmentTreatment &
  Pick<Appointment, "status" | "clientDisplayName" | "isSelfBooked" | "paymentMethodId"> &
  Pick<AppointmentTreatment, "employeeName" | "employeeId"> &
  CommonSlotValues & {
    isBulk: boolean;
    bulkPrice: number | null;
    note: Appointment["note"];
  };

type CommonSlotValues = {
  __typename?: string;
  __isSlot: boolean;
  id: Appointment["appointmentId"];
  categoryColor?: AppointmentTreatment["categoryColor"];
  timeRange: AppointmentTreatment["timeRange"];
  employeeName: AppointmentTreatment["employeeName"];
};

export type UnknownCalendarSlot = DeepPartial<
  Omit<AppointmentTreatmentSlot & CalendarBlockedSlot, "__typename" | "employeeName">
> &
  CommonSlotValues;
