/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";

import { useDebounce } from "@hooks/use-debounce";

import { namedOperations } from "@/types";

import type { EmployeeSchedule } from "../models";
import { useUpdateEmployeesPositionsMutation } from "../mutations/UpdateEmployeesPositions.generated";
import { useFetchEmployeeQuery } from "../queries/FetchEmployee.generated";
import { useFetchEmployeesQuery } from "../queries/FetchEmployees.generated";
import { encodeEmployeeWorkSchedule } from "../utils";

const EMPLOYEES_LIMIT = 100;

type EmployeesStoreProps = {
  selectedOptionId?: string;
};

type EmployeeWithSchedule = {
  employeeId: string;
  workSchedule: EmployeeSchedule;
};

export const useEmployeesStore = ({ selectedOptionId }: EmployeesStoreProps = {}) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [employeeIdToInclude, setEmployeeIdToInclude] = useState<string | undefined>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data, fetchMore, refetch, networkStatus } = useFetchEmployeesQuery({
    variables: { limit: EMPLOYEES_LIMIT },
    notifyOnNetworkStatusChange: true,
  });

  const { data: employeeToIncludeData } = useFetchEmployeeQuery({
    variables: { id: employeeIdToInclude },
    skip: !employeeIdToInclude,
  });

  const [updateEmployeesPositions, { loading: loadingUpdate }] =
    useUpdateEmployeesPositionsMutation();

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && selectedOptionId && !searchQuery) {
      setEmployeeIdToInclude(
        !data?.fetchEmployees.edges.find(({ node }) => node.accountId === selectedOptionId)
          ? selectedOptionId
          : undefined,
      );
    }
  }, [selectedOptionId, networkStatus]);

  const employees = data?.fetchEmployees.edges.map(({ node }) => node) || [];

  const availableEmpoyees = employees.filter((e) => e.isAvailableForAppointments);

  const employeesWithMappedWorkSchedule: EmployeeWithSchedule[] = employees.reduce<
    EmployeeWithSchedule[]
  >((acc, { workSchedule, accountId, isAvailableForAppointments }) => {
    if (isAvailableForAppointments) {
      const schedule = encodeEmployeeWorkSchedule(JSON.parse(workSchedule || "[]"));
      acc.push({
        employeeId: accountId,
        workSchedule: schedule,
      });
    }

    return acc;
  }, []);

  const isPossibleToDisableEmployeeAvailability = availableEmpoyees.length > 1;

  return {
    loading: networkStatus === NetworkStatus.loading,
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    loadingUpdate,
    employees: employees,
    employeesCount: data?.fetchEmployees.count || 0,
    employeesWithMappedWorkSchedule,
    isPossibleToDisableEmployeeAvailability: isPossibleToDisableEmployeeAvailability,
    employeesOptions: [
      ...(employeeToIncludeData ? [{ node: employeeToIncludeData.fetchEmployee }] : []),
      ...(data?.fetchEmployees.edges || []),
    ]
      .filter(({ node }) => node.isAvailableForAppointments)
      .map(({ node }) => ({
        value: node!.accountId,
        label: node!.fullName,
        props: {
          firstName: node!.firstName,
          lastName: node!.lastName,
        },
      })),
    hasMore: data?.fetchEmployees.pageInfo.hasNextPage || false,
    searchQuery,
    refetchWithQuery(query: string) {
      setSearchQuery(query);
    },
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchEmployees.pageInfo.endCursor,
          },
        });
      }
    },
    updateEmployeesPositions({ data }: { data: { sortedEmployeesIds: string[] } }) {
      return updateEmployeesPositions({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchEmployees],
        update(cache) {
          cache.evict({ fieldName: "fetchAllEmployees" });
          cache.gc();
        },
      });
    },
  };
};
