import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBlockedSlotMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
}>;


export type DeleteBlockedSlotMutation = { __typename?: 'RootMutationType', deleteBlockedSlot: string };


export const DeleteBlockedSlotDocument = gql`
    mutation DeleteBlockedSlot($id: UUID4!) {
  deleteBlockedSlot(id: $id)
}
    `;
export type DeleteBlockedSlotMutationFn = Apollo.MutationFunction<DeleteBlockedSlotMutation, DeleteBlockedSlotMutationVariables>;

/**
 * __useDeleteBlockedSlotMutation__
 *
 * To run a mutation, you first call `useDeleteBlockedSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockedSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockedSlotMutation, { data, loading, error }] = useDeleteBlockedSlotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockedSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockedSlotMutation, DeleteBlockedSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockedSlotMutation, DeleteBlockedSlotMutationVariables>(DeleteBlockedSlotDocument, options);
      }
export type DeleteBlockedSlotMutationHookResult = ReturnType<typeof useDeleteBlockedSlotMutation>;
export type DeleteBlockedSlotMutationResult = Apollo.MutationResult<DeleteBlockedSlotMutation>;
export type DeleteBlockedSlotMutationOptions = Apollo.BaseMutationOptions<DeleteBlockedSlotMutation, DeleteBlockedSlotMutationVariables>;
