import { MouseEvent, useMemo } from "react";

import {
  DEFAULT_APPOINTMENT_DURATION_STEPS,
  NUMBER_OF_DAYS_IN_WEEK,
  SCALE_MINUTES_PER_STEP,
  SCALE_STEPS_PER_DAY,
} from "@features/calendar/constants";

import { getDateWithOffset, getTimeWithOffset } from "@/features/calendar/utils/time";
import { useSessionContext } from "@/providers/SessionProvider";
import { Timerange } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarDialogs } from "../../contexts/CalendarDialogContext";
import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";

const calculateRange = (element: HTMLDivElement, clientY: number): Timerange => {
  const { top, height } = element.getBoundingClientRect();
  const dy = Math.floor(((clientY - top) * SCALE_STEPS_PER_DAY) / height);
  const from = getTimeWithOffset(dy, SCALE_MINUTES_PER_STEP);
  const to = getTimeWithOffset(dy + DEFAULT_APPOINTMENT_DURATION_STEPS, SCALE_MINUTES_PER_STEP);

  return { from, to };
};

export const CalendarEmployeeWeekPlaceholder = () => {
  const { permissions } = useSessionContext();

  const { timeRange } = useCalendarDate();
  const { filters } = useCalendarFilters();
  const { openAddSlotDialog } = useCalendarDialogs();

  const handleColClick = (event: MouseEvent<HTMLDivElement>, employeeId: string, index: number) => {
    if (!permissions.add_appointment && !permissions.add_slot_block) return;

    const { from, to } = calculateRange(event.target as HTMLDivElement, event.clientY);

    openAddSlotDialog({
      date: getDateWithOffset(timeRange.from, index),
      from,
      to,
      employeeId,
    });
  };

  const employeeId = useMemo(() => filters.employeeId, [filters]);

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-stone-200"
      style={{ gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))` }}>
      {new Array(NUMBER_OF_DAYS_IN_WEEK).fill({}).map((_, index) => (
        <div
          key={index}
          className={cn(
            "z-10 row-span-full mt-7 opacity-30 hover:bg-gold-50",
            `col-start-${index + 1}`,
          )}
          onClick={(event) => handleColClick(event, employeeId, index)}></div>
      ))}
      <div className="col-start-8 row-span-full w-8"></div>
    </div>
  );
};
