import { useTranslation } from "react-i18next";

import { EnvelopeIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

import { useCountersStore } from "@features/session/hooks";

import { Spinner } from "@/components/ui/Spinner";
import { GetSmsPackageDialog } from "@/features/payments/components/GetSmsPackageDialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

export const SidebarAccountDetails = () => {
  const { t } = useTranslation();
  const { isOwner } = useSessionContext();
  const { smsLimit, loadingSmsLimit: loading } = useCountersStore();
  const dialogState = useDialog();

  if (loading) {
    <div className="px-16">
      <Spinner />
    </div>;
  }

  return (
    <div className="w-full">
      <div
        className={cn(
          "rounded-md border border-dashed border-gold-400 bg-gold-100 px-2 py-1 shadow md:my-4",
        )}>
        <div className="flex justify-between">
          <div
            className={cn(
              "flex items-center gap-1",
              smsLimit > 0 ? "text-stone-600" : "text-red-500",
            )}>
            <EnvelopeIcon className="h-5 w-5" />
            <span className="py-2 text-sm">{t("header.smsMenu.quantityLeft", [smsLimit])}</span>
          </div>
          {isOwner && (
            <Button
              variant="primary-inline"
              size="tiny"
              onClick={() => dialogState.open()}
              className="shrink-0 text-xs">
              {t("actions.buySMS")}
            </Button>
          )}
        </div>
      </div>
      <GetSmsPackageDialog {...bindDialogState(dialogState)} />
    </div>
  );
};
