import { useTranslation } from "react-i18next";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { Button } from "@/components/ui/Button";
import { ColorPicker } from "@/components/ui/ColorPicker";
import { TextField } from "@/components/ui/TextField";
import { TAG } from "@/constants/validations";
import { useTagForm } from "@/features/clients/hooks/tags/use-tag-form";
import { useSessionContext } from "@/providers/SessionProvider";
import { ClientTag, ClientTagInput } from "@/types";
import { isNotWhitespace } from "@/utils/form";
import { cn } from "@/utils/utils";

export type TagFormProps = {
  action: "create" | "update";
  tagId?: ClientTag["id"];
  defaultValues?: ClientTagInput;
  onClose: () => void;
  onSubmit?: (tag: ClientTag) => void;
};

type TagFormDialogProps = {
  props?: TagFormProps;
  onClose: () => void;
  open: boolean;
};

export const TagFormDialog = ({ props, onClose, open }: TagFormDialogProps) => {
  const { t } = useTranslation();

  const action = props?.tagId ? "update" : "create";

  return (
    <DefaultDialog
      onClose={onClose}
      open={open}
      title={action === "update" ? t("tags.update") : t("tags.create")}>
      <TagForm action={action} onClose={onClose} {...props} />
    </DefaultDialog>
  );
};

const TagForm = ({ tagId, action, onClose, defaultValues, onSubmit }: TagFormProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionContext();

  const { handleTagDelete, handleTagSubmit, form, isMutationLoading } = useTagForm({
    tagId,
    action,
    onClose,
    defaultValues,
    onSubmit,
  });

  const {
    control,
    register,
    formState: { errors, isValid, isDirty },
  } = form;

  return (
    <form className="grid gap-4">
      <TextField
        {...register("name", {
          required: true,
          maxLength: TAG.MAX_LENGTH,
          validate: { isNotWhitespace },
        })}
        label={t("tags.name")}
        showLabel
        placeholder={t("tags.placeholders.name")}
        errorMessage={
          errors.name &&
          t(`validation.${errors.name.type}`, {
            name: t("generic.tags"),
            maxLength: TAG.MAX_LENGTH,
          })
        }
      />

      <ColorPicker name="color" control={control} label={t("generic.color")} />

      <div
        className={cn("align-center grid gap-4 pt-4", {
          "md:grid-cols-2": action === "update",
        })}>
        {action === "update" && (
          <Button variant="danger" onClick={handleTagDelete} disabled={!permissions.delete_tag}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          fullWidth
          disabled={!isValid || !isDirty}
          loading={isMutationLoading}
          // fix: bugs workaround when 2 forms were submitted at the same time
          onClick={() => handleTagSubmit(form.getValues())}>
          {t("generic.save")}
        </Button>
      </div>
    </form>
  );
};
