import { ReactNode } from "react";

import { CalendarDateProvider } from "../contexts/CalendarDateContext";
import { CalendarDialogProvider } from "../contexts/CalendarDialogContext";
import { CalendarFiltersProvider } from "../contexts/CalendarFiltersContext";
import { CalendarNavigationProvider } from "../contexts/CalendarNavigationContext";
import { CalendarViewProvider } from "../contexts/CalendarViewContext";

export const CalendarProvider = ({ children }: { children: ReactNode }) => {
  return (
    <CalendarViewProvider>
      <CalendarDateProvider>
        <CalendarNavigationProvider>
          <CalendarFiltersProvider>
            <CalendarDialogProvider>{children}</CalendarDialogProvider>
          </CalendarFiltersProvider>
        </CalendarNavigationProvider>
      </CalendarDateProvider>
    </CalendarViewProvider>
  );
};
