import { NetworkStatus } from "@apollo/client";
import { useState, useEffect } from "react";

import { useDebounce } from "@hooks/use-debounce";

import { useFetchAllTreatmentsQuery } from "../queries/FetchAllTreatments.generated";
import { useFetchChosenTreatmentsQuery } from "../queries/FetchChosenTreatments.generated";

type TreatmentsSearchStoreProps = {
  selectedOptionIds?: string[];
};

export const TREATMENTS_FETCH_LIMIT = 100;
export const MIN_OPTIONS_FOR_SEARCH = 20;

export const useTreatmentsSearchStore = ({
  selectedOptionIds,
}: TreatmentsSearchStoreProps = {}) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [treatmentIdsToInclude, setTreatmentIdsToInclude] = useState<string[] | undefined>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data, networkStatus, fetchMore, refetch } = useFetchAllTreatmentsQuery({
    variables: { first: TREATMENTS_FETCH_LIMIT },
    notifyOnNetworkStatusChange: true,
  });

  const { data: treatmentsToIncludeData } = useFetchChosenTreatmentsQuery({
    variables: { ids: treatmentIdsToInclude },
    skip: !treatmentIdsToInclude,
  });

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && selectedOptionIds && !searchQuery) {
      const existingIds = (data?.fetchAllTreatments.edges || []).map(
        ({ node }) => node.treatmentId,
      );
      const idsToInclude = selectedOptionIds.filter((id) => !existingIds.includes(id));
      setTreatmentIdsToInclude(idsToInclude.length > 0 ? idsToInclude : undefined);
    }
  }, [selectedOptionIds, networkStatus]);

  const treatmentOptions = [
    ...(treatmentsToIncludeData?.fetchChosenTreatments
      ? treatmentsToIncludeData.fetchChosenTreatments.filter(({ treatmentId }) =>
          treatmentIdsToInclude?.includes(treatmentId),
        )
      : []),
    ...(data?.fetchAllTreatments.edges || []).map(({ node }) => node),
  ].map((treatment) => ({
    value: treatment.treatmentId,
    label: treatment.treatmentName,
    props: {
      ...treatment,
      color: treatment.categoryColor,
    },
    groupValue: treatment.categoryId,
    groupLabel: treatment.categoryName,
  }));

  return {
    loading: networkStatus === NetworkStatus.loading,
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    treatments: data?.fetchAllTreatments.edges.map(({ node }) => node) || [],
    treatmentOptions,
    count: data?.fetchAllTreatments.count || 0,
    hasMore: !!data?.fetchAllTreatments.pageInfo.hasNextPage,
    searchQuery,
    refetchWithQuery(query: string) {
      setSearchQuery(query);
    },
    fetchMore(limit: number = TREATMENTS_FETCH_LIMIT) {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchAllTreatments.pageInfo.endCursor,
            first: limit,
          },
        });
      }
    },
  };
};
