import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteClientTagMutationVariables = Types.Exact<{
  tagId: Types.Scalars['UUID4'];
}>;


export type DeleteClientTagMutation = { __typename?: 'RootMutationType', deleteClientTag: string };


export const DeleteClientTagDocument = gql`
    mutation DeleteClientTag($tagId: UUID4!) {
  deleteClientTag(tagId: $tagId)
}
    `;
export type DeleteClientTagMutationFn = Apollo.MutationFunction<DeleteClientTagMutation, DeleteClientTagMutationVariables>;

/**
 * __useDeleteClientTagMutation__
 *
 * To run a mutation, you first call `useDeleteClientTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientTagMutation, { data, loading, error }] = useDeleteClientTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteClientTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientTagMutation, DeleteClientTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientTagMutation, DeleteClientTagMutationVariables>(DeleteClientTagDocument, options);
      }
export type DeleteClientTagMutationHookResult = ReturnType<typeof useDeleteClientTagMutation>;
export type DeleteClientTagMutationResult = Apollo.MutationResult<DeleteClientTagMutation>;
export type DeleteClientTagMutationOptions = Apollo.BaseMutationOptions<DeleteClientTagMutation, DeleteClientTagMutationVariables>;
