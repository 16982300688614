import { useEffect, useState } from "react";

import {
  NUMBER_OF_DAYS_IN_WEEK,
  SCALE_MINUTES_PER_STEP,
  SCALE_STEPS_PER_DAY,
} from "@features/calendar/constants";
import { getEmployeeUnavailabilityRanges } from "@features/salon/utils";

import { useSalonOpeningHoursStore } from "@features/salon/hooks";

import { getDivisionOffset, getDivisionsDistance } from "@/features/calendar/utils/time";
import { useEmployeeStore } from "@/features/employees/hooks";
import { Timerange } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";

const CalendarEmployeeWeekClosedTimerange = ({ from, to }: Timerange) => {
  const rowOffset = getDivisionOffset(from, 2, SCALE_MINUTES_PER_STEP);
  const rowsCount = getDivisionsDistance(from, to, SCALE_MINUTES_PER_STEP);

  return (
    <div
      className="bg-stone-100"
      style={{
        gridRow: `${rowOffset} / span ${rowsCount}`,
      }}></div>
  );
};

export const CalendarEmployeeWeekAvailability = () => {
  const { filters } = useCalendarFilters();
  const { selectedDate, timeRange } = useCalendarDate();

  const { regular, special, loading: openingHoursLoading } = useSalonOpeningHoursStore();
  const { employeeWorkSchedule, loading: employeeLoading } = useEmployeeStore(filters.employeeId);

  const [closedTimeranges, setClosedTimeranges] = useState<Timerange[][]>([]);

  const loading = openingHoursLoading || employeeLoading;

  useEffect(() => {
    setClosedTimeranges(
      getEmployeeUnavailabilityRanges({
        regular,
        special,
        workSchedule: employeeWorkSchedule,
        timeRange,
      }),
    );
  }, [loading, selectedDate]);

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-stone-200"
      style={{ gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))` }}>
      {new Array(NUMBER_OF_DAYS_IN_WEEK).fill({}).map((_, index) => (
        <div
          key={index}
          className={cn("row-span-full grid", `col-start-${index + 1}`)}
          style={{
            gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
          }}>
          {closedTimeranges[index]?.map((timerange, index) => (
            <CalendarEmployeeWeekClosedTimerange key={index} {...timerange} />
          ))}
        </div>
      ))}
      <div className="col-start-8 row-span-full w-8"></div>
    </div>
  );
};
