import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TimerangeFragmentDoc } from '../../../fragments/TimeRange.generated';
export type BlockedSlotFragment = { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeId: any, title?: string | null, id: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } };

export const BlockedSlotFragmentDoc = gql`
    fragment BlockedSlot on BlockedSlot {
  description
  employeeName
  employeeId
  timeRange {
    ...Timerange
  }
  title
  id
  insertedAt
}
    ${TimerangeFragmentDoc}`;
