import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EquipmentItemFragmentDoc } from '../fragments/EquipmentItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEquipmentItemMutationVariables = Types.Exact<{
  data: Types.CreateEquipmentItemInput;
}>;


export type CreateEquipmentItemMutation = { __typename?: 'RootMutationType', createEquipmentItem: { __typename?: 'EquipmentItem', id: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentId: any }> } };


export const CreateEquipmentItemDocument = gql`
    mutation CreateEquipmentItem($data: CreateEquipmentItemInput!) {
  createEquipmentItem(data: $data) {
    ...EquipmentItem
  }
}
    ${EquipmentItemFragmentDoc}`;
export type CreateEquipmentItemMutationFn = Apollo.MutationFunction<CreateEquipmentItemMutation, CreateEquipmentItemMutationVariables>;

/**
 * __useCreateEquipmentItemMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentItemMutation, { data, loading, error }] = useCreateEquipmentItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateEquipmentItemMutation, CreateEquipmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEquipmentItemMutation, CreateEquipmentItemMutationVariables>(CreateEquipmentItemDocument, options);
      }
export type CreateEquipmentItemMutationHookResult = ReturnType<typeof useCreateEquipmentItemMutation>;
export type CreateEquipmentItemMutationResult = Apollo.MutationResult<CreateEquipmentItemMutation>;
export type CreateEquipmentItemMutationOptions = Apollo.BaseMutationOptions<CreateEquipmentItemMutation, CreateEquipmentItemMutationVariables>;
