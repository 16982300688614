import { AddClientInput, namedOperations, UpdateClientInput } from "@/types";

import { useAddClientMutation } from "../mutations/AddClient.generated";
import { useDeleteClientMutation } from "../mutations/DeleteClient.generated";
import { useUpdateClientMutation } from "../mutations/UpdateClient.generated";
import { useFetchClientQuery } from "../queries/FetchClient.generated";

export const useClientStore = (id?: string) => {
  const {
    data,
    loading,
    error: fetchError,
  } = useFetchClientQuery({
    variables: { id },
    skip: !id,
  });

  const [addClient, { loading: loadingAdd, error: addError }] = useAddClientMutation();
  const [updateClient, { loading: loadingUpdate, error: updateError }] = useUpdateClientMutation();
  const [deleteClient, { loading: loadingDelete }] = useDeleteClientMutation();

  const client = data?.fetchClient;
  const tagsIds = data?.fetchClient?.tags.map((tag) => tag.id);

  return {
    loading,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
    client,
    tagsIds,
    addError,
    updateError,
    fetchError,
    addClient(data: AddClientInput) {
      return addClient({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchSalonClients],
      });
    },
    updateClient(data: UpdateClientInput, id: string) {
      return updateClient({
        variables: { id, data },
      });
    },
    deleteClient(id: string) {
      return deleteClient({
        variables: { id },
        update(cache) {
          const normalizedId = cache.identify({
            id: id,
            __typename: "Client",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        refetchQueries: [namedOperations.Query.FetchSalonClients],
      });
    },
  };
};
