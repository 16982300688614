import { useTranslation } from "react-i18next";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Appointment, Client } from "@/types";

import { AppointmentClientName } from "./AppointmentClientName";
import { AppointmentDetailsDatetimeInfo } from "./AppointmentDetailsDatetimeInfo";
import { AppointmentDetailsTreatments } from "./AppointmentDetailsTreatments";

type AppointmentDetailsProps = {
  appointment: Appointment;
  client?: Client;
  onClose: () => void;
};

export const AppointmentDetails = ({ appointment, client, onClose }: AppointmentDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flow-root">
        <div className="mb-1 mt-4">
          {appointment?.clientName && client?.id ? (
            <AppointmentClientName client={client} onClose={onClose} />
          ) : (
            <span className="inline-flex text-2xl font-bold text-stone-600">
              {t("appointments.anonymousClient")}
            </span>
          )}
        </div>
        {client?.note && (
          <div className="mb-6 flex items-center justify-self-end rounded-md bg-stone-100 px-3 py-2">
            <InformationCircleIcon className="mr-1 mt-0.5 h-3 w-3 shrink-0 self-start" />
            <p className="line-clamp-2 text-xs font-normal">{client?.note}</p>
          </div>
        )}
        <AppointmentDetailsDatetimeInfo appointment={appointment} />
        <AppointmentDetailsTreatments appointment={appointment} />
      </div>
    </div>
  );
};
