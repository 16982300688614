import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Select } from "@/components/ui/Select";
import { TagFormDialog, TagFormProps } from "@/features/clients/components/tags/TagFormDialog";
import { useTagsStore } from "@/features/clients/hooks/tags/use-tags-store";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";

import { TagsSelectOptions } from "./TagsSelectOptions";

type TagsSelectProps = {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
};

export const TagsSelect = ({ name, control, disabled, label }: TagsSelectProps) => {
  const { t } = useTranslation();
  const { tagsSelectOptions, loading } = useTagsStore();

  const dialogState = useDialog<TagFormProps>();

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = [] } }) => (
          <Select
            name={name}
            label={label ?? t("generic.tags")}
            disabled={loading || disabled}
            multiple
            onChange={onChange}
            value={value}
            options={tagsSelectOptions}
            placeholder={t("tags.placeholders.select")}
            renderCustomSelectOptions={() => (
              <TagsSelectOptions
                options={tagsSelectOptions}
                loading={loading}
                selected={value}
                addNew={() => dialogState.open()}
                selectAll={() => onChange(tagsSelectOptions.map((option) => option.value))}
                deselectAll={() => onChange([])}
                onEdit={(data) =>
                  dialogState.open({
                    action: "update",
                    tagId: data.value,
                    defaultValues: {
                      name: data.label,
                      color: data.props.color,
                    },
                    onClose: dialogState.close,
                  })
                }
              />
            )}
          />
        )}
      />
      <TagFormDialog {...bindDialogState(dialogState)} />
    </>
  );
};
