import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonPaymentMethodFragmentDoc } from '../fragments/SalonPaymentMethod.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonPaymentMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonPaymentMethodsQuery = { __typename?: 'RootQueryType', fetchSalonPaymentMethods?: Array<{ __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: Types.SalonPaymentMethods, salonId: any, id: any }> | null };


export const FetchSalonPaymentMethodsDocument = gql`
    query FetchSalonPaymentMethods {
  fetchSalonPaymentMethods {
    ...SalonPaymentMethod
  }
}
    ${SalonPaymentMethodFragmentDoc}`;

/**
 * __useFetchSalonPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useFetchSalonPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonPaymentMethodsQuery, FetchSalonPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonPaymentMethodsQuery, FetchSalonPaymentMethodsQueryVariables>(FetchSalonPaymentMethodsDocument, options);
      }
export function useFetchSalonPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonPaymentMethodsQuery, FetchSalonPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonPaymentMethodsQuery, FetchSalonPaymentMethodsQueryVariables>(FetchSalonPaymentMethodsDocument, options);
        }
export type FetchSalonPaymentMethodsQueryHookResult = ReturnType<typeof useFetchSalonPaymentMethodsQuery>;
export type FetchSalonPaymentMethodsLazyQueryHookResult = ReturnType<typeof useFetchSalonPaymentMethodsLazyQuery>;
export type FetchSalonPaymentMethodsQueryResult = Apollo.QueryResult<FetchSalonPaymentMethodsQuery, FetchSalonPaymentMethodsQueryVariables>;
