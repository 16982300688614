import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";

import {
  addMonths,
  addWeeks,
  differenceInCalendarWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  isSameDay,
  isSameMonth,
  setDate,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import { AnimatePresence, motion, Variants } from "framer-motion";

import { useBreakpoint } from "@/hooks/use-breakpoint";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../contexts/CalendarDateContext";
import { useCalendarNavigation } from "../contexts/CalendarNavigationContext";

const setDatePreservingDay = (baseDate: Date, newDate: Date) => {
  const desiredDay = getDate(baseDate);
  const end = endOfMonth(newDate);
  const newDay = desiredDay > getDate(end) ? getDate(end) : desiredDay;
  return setDate(newDate, newDay);
};

interface MotionVariantsProps {
  direction: number;
}

const swipeVariants: Variants = {
  enter: (custom: MotionVariantsProps) => ({
    x: custom.direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (custom: MotionVariantsProps) => ({
    x: custom.direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

const expandVariants: Variants = {
  enter: { y: 50, opacity: 0 },
  center: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 },
};

const noneVariants: Variants = {
  enter: { opacity: 1, x: 0, y: 0 },
  center: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 1, x: 0, y: 0 },
};

export const CalendarDaysNavigation = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { isSm } = useBreakpoint("sm");
  const { t } = useTranslation();
  const { selectedDate, setSelectedDate } = useCalendarDate();
  const { isExpanded, setIsExpanded, direction, setDirection, actionType, setActionType } =
    useCalendarNavigation();

  const initialWeekStart = useMemo(() => {
    return selectedDate
      ? startOfWeek(selectedDate, { weekStartsOn: 1 })
      : startOfWeek(new Date(), { weekStartsOn: 1 });
  }, [selectedDate]);

  const [currentWeekStart, setCurrentWeekStart] = useState(initialWeekStart);

  useEffect(() => {
    if (selectedDate) {
      const newWeekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
      setCurrentWeekStart(newWeekStart);
    }
  }, [selectedDate]);

  const handleNextWeek = () => {
    setDirection(1);
    setActionType("swipe");

    const newWeekStart = addWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);

    if (selectedDate) {
      setSelectedDate(addWeeks(selectedDate, 1));
    } else {
      setSelectedDate(newWeekStart);
    }
  };

  const handlePrevWeek = () => {
    setDirection(-1);
    setActionType("swipe");

    const newWeekStart = subWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);

    if (selectedDate) {
      setSelectedDate(subWeeks(selectedDate, 1));
    } else {
      setSelectedDate(newWeekStart);
    }
  };

  const handleNextMonth = () => {
    setDirection(1);
    setActionType("swipe");

    const newMonth = addMonths(selectedDate, 1);
    const newSelectedDate = setDatePreservingDay(selectedDate, newMonth);
    const newMonthStart = startOfWeek(startOfMonth(newSelectedDate), { weekStartsOn: 1 });
    setCurrentWeekStart(newMonthStart);

    setSelectedDate(newSelectedDate);
  };

  const handlePrevMonth = () => {
    setDirection(-1);
    setActionType("swipe");

    const newMonth = subMonths(selectedDate, 1);
    const newSelectedDate = setDatePreservingDay(selectedDate, newMonth);
    const newMonthStart = startOfWeek(startOfMonth(newSelectedDate), { weekStartsOn: 1 });
    setCurrentWeekStart(newMonthStart);

    setSelectedDate(newSelectedDate);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isExpanded) {
        handleNextMonth();
      } else {
        handleNextWeek();
      }
    },
    onSwipedRight: () => {
      if (isExpanded) {
        handlePrevMonth();
      } else {
        handlePrevWeek();
      }
    },
    onSwipedUp: () => {
      setActionType("expand");
      setIsExpanded(false);
    },
    onSwipedDown: () => {
      setActionType("expand");
      setIsExpanded(true);
    },
    trackMouse: true,
    delta: 50,
  });

  const month = useMemo(() => {
    if (isExpanded && selectedDate) {
      return format(selectedDate, "LLLL yyyy");
    } else {
      const start = format(currentWeekStart, "LLLL yyyy");
      const endOfCurrentWeek = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
      const endMonth = format(endOfCurrentWeek, "LLLL yyyy");
      return isSameMonth(currentWeekStart, endOfCurrentWeek) ? start : `${start} - ${endMonth}`;
    }
  }, [currentWeekStart, isExpanded, selectedDate]);

  const days = useMemo(() => {
    if (isExpanded && selectedDate) {
      const monthStart = startOfMonth(selectedDate);
      const monthEnd = endOfMonth(monthStart);
      const start = startOfWeek(monthStart, { weekStartsOn: 1 });
      const end = endOfWeek(monthEnd, { weekStartsOn: 1 });
      return eachDayOfInterval({ start, end });
    } else {
      return eachDayOfInterval({
        start: currentWeekStart,
        end: endOfWeek(currentWeekStart, { weekStartsOn: 1 }),
      });
    }
  }, [currentWeekStart, selectedDate, isExpanded]);

  const renderWeekDays = useMemo(() => {
    return days.map((day, index) => (
      <button
        key={index}
        type="button"
        className="flex w-full flex-col items-center pb-2"
        onClick={() => {
          setActionType("none");
          setSelectedDate(day);
        }}>
        <span
          className={cn("flex h-6 w-6 items-center justify-center font-semibold text-stone-900", {
            "rounded-md bg-gold-200 text-gold-700": isSameDay(day, selectedDate),
            "text-gray-400": !isSameMonth(day, selectedDate),
          })}>
          {format(day, "dd")}
        </span>
      </button>
    ));
  }, [days, selectedDate, setSelectedDate]);

  const weekDayLabels = [
    t("weekDaysAbbreviated.monday"),
    t("weekDaysAbbreviated.tuesday"),
    t("weekDaysAbbreviated.wednesday"),
    t("weekDaysAbbreviated.thursday"),
    t("weekDaysAbbreviated.friday"),
    t("weekDaysAbbreviated.saturday"),
    t("weekDaysAbbreviated.sunday"),
  ];

  const numberOfWeeks = useMemo(() => {
    if (!isExpanded || !selectedDate) return 1;

    const monthStart = startOfMonth(selectedDate);
    const monthEnd = endOfMonth(monthStart);
    const start = startOfWeek(monthStart, { weekStartsOn: 1 });
    const end = endOfWeek(monthEnd, { weekStartsOn: 1 });

    return differenceInCalendarWeeks(end, start) + 1;
  }, [isExpanded, selectedDate]);

  const weekRowHeight = 32;
  const expandedHeight = numberOfWeeks * weekRowHeight;

  return (
    <div ref={ref} className="bg-white sm:hidden">
      {!isSm && (
        <time className="flex justify-center border-b border-stone-100 py-2 text-xs uppercase tracking-wide text-stone-500 first-letter:capitalize">
          {month}
        </time>
      )}

      <div className="grid grid-cols-7 text-xs leading-6 text-stone-500">
        {weekDayLabels.map((label, index) => (
          <div key={index} className="flex justify-center lowercase">
            {label}.
          </div>
        ))}
      </div>

      <motion.div
        {...handlers}
        initial={{ height: weekRowHeight }}
        animate={{
          height: isExpanded ? expandedHeight : weekRowHeight,
          transition: { duration: 0.2, ease: "easeInOut" },
        }}
        className="relative overflow-hidden">
        <AnimatePresence custom={{ direction }} initial={false}>
          <motion.div
            key={currentWeekStart.toISOString()}
            className="absolute left-0 top-0 grid w-full grid-cols-7 pb-1 text-xs leading-6 text-stone-500"
            custom={{ direction }}
            variants={
              actionType === "swipe"
                ? swipeVariants
                : actionType === "expand"
                ? expandVariants
                : noneVariants
            }
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x:
                actionType === "swipe"
                  ? { type: "spring", stiffness: 300, damping: 30 }
                  : undefined,
              y:
                actionType === "expand"
                  ? { type: "spring", stiffness: 300, damping: 30 }
                  : undefined,
              opacity: { duration: 0.2 },
            }}>
            {renderWeekDays}
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
});
