import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@/constants";
import { useEmployeeStore } from "@/features/employees/hooks";
import { useUserAgent } from "@/hooks/use-user-agent";

import { MobileNavigationItems } from "./MobileSidebar/MobileNavigationItems";
import { SidebarAccountButton } from "./MobileSidebar/SidebarAccountButton";
import { SidebarAccountDetails } from "./MobileSidebar/SidebarAccountDetails";

type MobileSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accountId: string;
};

declare global {
  interface Window {
    EstetifyMobile: {
      postMessage: (message: string) => void;
    };
  }
}

export const MobileSidebar = ({ isOpen, setIsOpen, accountId }: MobileSidebarProps) => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore(accountId);
  const { isMobileApp } = useUserAgent();

  return (
    <Transition.Root show={isOpen} as={Fragment} unmount>
      <div className="relative z-[50] overscroll-none">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className="fixed inset-0 bg-stone-600 bg-opacity-75"
            onClick={() => setIsOpen(false)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div className="fixed inset-0 z-40 grid h-screen w-full max-w-sm grid-rows-[auto_1fr_auto] bg-gold-50 pb-[76px] pt-4">
            <div className="overflow-y-auto overscroll-none">
              <span className="mb-3 inline-flex w-full border-b border-dashed border-stone-300 px-4 pb-2 font-medium text-stone-500">
                {t("generic.menu")}
              </span>
              <div className="px-3">
                <SidebarAccountDetails />
                {employee && <SidebarAccountButton setIsOpen={setIsOpen} employee={employee} />}
                <MobileNavigationItems />
                {isMobileApp && (
                  <div className="flex flex-col gap-3 rounded-md border border-dashed border-stone-400 px-3 py-4 text-sm text-stone-500 underline shadow">
                    <button
                      className="flex items-center gap-1"
                      onClick={() => {
                        // specific to mobile app, to use native functionality (open about page)
                        window.EstetifyMobile.postMessage("about");
                      }}>
                      <InformationCircleIcon className="size-4 text-stone-400" />
                      {t("sidebar.aboutApp")}
                    </button>
                    <Link to={TERMS_OF_SERVICE_URL} className="flex items-center gap-1">
                      <InformationCircleIcon className="size-4 text-stone-400" />
                      {t("sidebar.termsOfService")}
                    </Link>
                    <Link to={PRIVACY_POLICY_URL} className="flex items-center gap-1">
                      <InformationCircleIcon className="size-4 text-stone-400" />
                      {t("sidebar.privacyPolicy")}
                    </Link>
                  </div>
                )}
                <div></div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default MobileSidebar;
