import { ForwardedRef, Fragment, forwardRef } from "react";

import { getDayHours } from "@utils/datetime";

export const CalendarScaleBar = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const scaleDivisions = getDayHours();
  const scaleSubdivisionsCount = scaleDivisions.length * 2; // 1 row = 30 min

  return (
    <div
      ref={ref}
      className="relative col-start-1 col-end-2 row-start-1 grid"
      style={{ gridTemplateRows: `repeat(${scaleSubdivisionsCount}, minmax(3.5rem, 1fr))` }}>
      <div className="row-end-1 h-7"></div>
      {scaleDivisions.map((division) => (
        <Fragment key={division}>
          <div className="border-b border-dashed border-stone-200 after:absolute after:-mt-2.5 after:flex after:w-full after:border-t after:border-stone-200">
            <div className="sticky left-0 z-30 -ml-14 -mt-2.5 w-10 pr-1.5 text-right text-xs leading-5 text-stone-400">
              {division}
            </div>
          </div>
          <div></div>
        </Fragment>
      ))}
    </div>
  );
});
