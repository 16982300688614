import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Popover, Transition } from "@headlessui/react";

import { Badge } from "@/components/ui/Badge";
import { ClientTag } from "@/types";

import { MAX_VISIBLE_PROFILE_TAGS } from "../constants";

type ClientTagsProps = {
  tags: ClientTag[];
  maxVisibleTags?: number;
  size?: "xs" | "sm";
};

export const ClientTags = ({
  tags,
  maxVisibleTags = MAX_VISIBLE_PROFILE_TAGS,
}: ClientTagsProps) => {
  const { t } = useTranslation();
  const FIRST_TAG = tags[0];

  return (
    <div className="inline-flex w-full flex-wrap">
      {tags.length ? (
        tags.length <= maxVisibleTags ? (
          <div>
            {tags.map((tag) => (
              <Badge bordered color={tag.color} className="mr-2 w-fit last:mr-0" key={tag.id}>
                {tag.name}
              </Badge>
            ))}
          </div>
        ) : (
          <div>
            <Badge bordered color={FIRST_TAG.color} className="mr-2 w-fit">
              {FIRST_TAG.name}
            </Badge>
            <Popover className="relative inline-flex">
              <div>
                <Popover.Button className="items center font-md [w-20ch] flex appearance-none items-center gap-1 text-stone-500 focus:outline-none">
                  <Badge
                    bordered
                    className="w-fit bg-gradient-to-r from-nebula-100 via-gold-100 to-fuchsia-100">
                    <span className="bg-gradient-to-r from-indigo-800 via-snuff-800 to-pink-800 bg-clip-text text-transparent">
                      {tags.length - 1} <span className="lowercase">{t("generic.more")}</span>
                    </span>
                  </Badge>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1">
                  <Popover.Panel className="overflow-y absolute z-40 mt-1 grid max-h-[1000%] gap-2 overflow-y-scroll rounded-lg border bg-white p-2 py-3 shadow-md md:left-auto md:top-auto">
                    {/* display rest of the tags */}
                    {tags.slice(1).map((tag) => (
                      <Badge bordered color={tag.color} className="whitespace-nowrap" key={tag.id}>
                        {tag.name}
                      </Badge>
                    ))}
                  </Popover.Panel>
                </Transition>
              </div>
            </Popover>
          </div>
        )
      ) : null}
    </div>
  );
};
