import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { usePaymentsStore } from "@/features/payments/hooks";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { useToasts } from "@/providers/ToastsProvider";
import { PaymentMode, Product } from "@/types";

type SubscriptionsProviderProps = {
  children: ReactNode;
};

export type SubscriptionsContextInterface = {
  product: Product | null;
  setProduct: Dispatch<SetStateAction<Product | null>>;
  loading: boolean;
} | null;

export const SubscriptionsContext = createContext<SubscriptionsContextInterface>(null);

export const SubscriptionsProvider = ({ children }: SubscriptionsProviderProps) => {
  const [product, setProduct] = useState<Product | null>(null);

  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { makeOrder, makePayment, loading } = usePaymentsStore();
  const { session } = useSessionContext();
  const confirmationDialogState = useConfirmationDialog();

  const showErrorToast = () =>
    showToast({
      type: "error",
      title: t("generic.oops"),
      description: t("generic.somethingWentWrong"),
    });

  const handlePlanChange = async (product: Product) => {
    if (!product.priceId) {
      return showErrorToast();
    }

    const result = await makeOrder({
      orderItems: JSON.stringify([{ price: product.priceId, quantity: 1 }]),
      paymentMode: "subscription",
    });

    if (result.errors) {
      return showErrorToast();
    }

    const { data, errors } = await makePayment({
      accountId: session?.accountId,
      orderItems: result.data?.makeOrder?.orderItems,
      orderId: result.data?.makeOrder?.id,
      paymentMode: PaymentMode.Subscription,
    });

    if (errors) {
      return showErrorToast();
    } else if (data?.makePayment?.url) {
      window.location.href = data.makePayment.url;
    }
  };

  useEffect(() => {
    if (product) {
      handlePlanChange(product);
    }
  }, [product]);

  const contextValue = useMemo(
    () => ({
      product,
      setProduct,
      loading: loading.loadingMakePayment || loading.loadingMakeOrder,
    }),
    [product, setProduct, loading.loadingMakePayment, loading.loadingMakeOrder],
  );

  return (
    <SubscriptionsContext.Provider value={contextValue}>
      {children}
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </SubscriptionsContext.Provider>
  );
};
