export const ROUTES = {
  EMAIL_NOT_VERIFIED: "/email-not-verified",
  EMAIL_VERIFIED: "/email-verified",
  ONBOARDING_PERSONAL: "/onboarding/personal",
  ONBOARDING_SALON: "/onboarding/salon",
  ONBOARDING_SMS: "/onboarding/sms",
  CALENDAR: "/calendar",
  CLIENTS: "/clients",
  getClientById(id: string) {
    return `/${this.CLIENTS}/${id}`;
  },
  getClientEditById(id: string) {
    return `/${this.getClientById(id)}/edit`;
  },
  STATISTICS: "/statistics",
  SALON: "/salon",
  SALON_EDIT: "/salon/edit",
  EMPLOYEES: "/employees",
  getEmployeeById(id: string) {
    return `/${this.EMPLOYEES}/${id}`;
  },
  getEmployeeEditById(id: string) {
    return `/${this.getEmployeeById(id)}/edit`;
  },
  OPENING_HOURS: "/opening-hours",
  OPENING_HOURS_EDIT: "/opening-hours/edit",
  SERVICES: "/services",
  getCategoriesById(headCategoryId: string) {
    return `/${this.SERVICES}/${headCategoryId}`;
  },
  getTreatmentsByIds(headCategoryId: string, categoryId: string) {
    return `/${this.getCategoriesById(headCategoryId)}/${categoryId}`;
  },
  EQUIPMENT: "/equipment",
  SUBSCRIPTIONS: "/subscriptions",
  PAYMENT_SUCCESSFUL: "/payment-successful",
  MAINTENANCE: "/maintenance",
  SETTINGS: "/settings",
  SUBSCRIPTION: "/no-subscription",
} as const;

export const ONBOARDING_ROUTES = [
  ROUTES.ONBOARDING_PERSONAL,
  ROUTES.ONBOARDING_SALON,
  ROUTES.ONBOARDING_SMS,
] as const;

export const EMAIL_VERIFICATION_ROUTES = [
  ROUTES.EMAIL_NOT_VERIFIED,
  ROUTES.EMAIL_VERIFIED,
] as const;
