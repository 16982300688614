export type CountryCode = {
  code: string;
  iso: string;
  name: string;
  flag: string;
};

export const countryCodes: CountryCode[] = [
  { name: "poland", code: "+48", iso: "PL", flag: "🇵🇱" },
  { name: "austria", code: "+43", iso: "AT", flag: "🇦🇹" },
  { name: "belgium", code: "+32", iso: "BE", flag: "🇧🇪" },
  { name: "czechia", code: "+420", iso: "CZ", flag: "🇨🇿" },
  { name: "france", code: "+33", iso: "FR", flag: "🇫🇷" },
  { name: "germany", code: "+49", iso: "DE", flag: "🇩🇪" },
  { name: "greece", code: "+30", iso: "GR", flag: "🇬🇷" },
  { name: "hungary", code: "+36", iso: "HU", flag: "🇭🇺" },
  { name: "ireland", code: "+353", iso: "IE", flag: "🇮🇪" },
  { name: "italy", code: "+39", iso: "IT", flag: "🇮🇹" },
  { name: "latvia", code: "+371", iso: "LV", flag: "🇱🇻" },
  { name: "lithuania", code: "+370", iso: "LT", flag: "🇱🇹" },
  { name: "netherlands", code: "+31", iso: "NL", flag: "🇳🇱" },
  { name: "slovakia", code: "+421", iso: "SK", flag: "🇸🇰" },
  { name: "spain", code: "+34", iso: "ES", flag: "🇪🇸" },
  { name: "sweden", code: "+46", iso: "SE", flag: "🇸🇪" },
  { name: "switzerland", code: "+41", iso: "CH", flag: "🇨🇭" },
  { name: "ukraine", code: "+380", iso: "UA", flag: "🇺🇦" },
  { name: "united_kingdom", code: "+44", iso: "GB", flag: "🇬🇧" },
];
