import { AddEmployeeInput, namedOperations, UpdateEmployeeInput } from "@/types";

import { useAddEmployeeMutation } from "../mutations/AddEmployee.generated";
import { useDeleteEmployeeMutation } from "../mutations/DeleteEmployee.generated";
import { useUpdateEmployeeMutation } from "../mutations/UpdateEmployee.generated";
import { useFetchEmployeeQuery } from "../queries/FetchEmployee.generated";
import { encodeEmployeeWorkSchedule } from "../utils";

export const useEmployeeStore = (id?: string) => {
  const {
    data,
    loading,
    error: fetchError,
  } = useFetchEmployeeQuery({
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });
  const [add, { loading: loadingAdd, error: addError }] = useAddEmployeeMutation();
  const [update, { loading: loadingUpdate, error: updateError }] = useUpdateEmployeeMutation();
  const [remove, { loading: loadingDelete }] = useDeleteEmployeeMutation();

  return {
    loading,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
    employee: data?.fetchEmployee,
    employeeWorkSchedule: encodeEmployeeWorkSchedule(
      JSON.parse(data?.fetchEmployee?.workSchedule || "[]"),
    ),
    addError,
    updateError,
    fetchError,
    addEmployee(data: AddEmployeeInput) {
      return add({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchEmployees],
      });
    },
    updateEmployee(data: UpdateEmployeeInput, id: string) {
      return update({
        variables: { id, data },
        refetchQueries: [namedOperations.Query.FetchEmployees],
      });
    },
    deleteEmployee(id: string) {
      return remove({
        variables: { id },
        update(cache) {
          const normalizedId = cache.identify({
            accountId: id,
            __typename: "Employee",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
