import { useUpdateCategoriesPositionsMutation } from "../mutations/UpdateCategoriesPositions.generated";
import {
  FetchHeadCategoriesDocument,
  FetchHeadCategoriesQuery,
  FetchHeadCategoriesQueryVariables,
} from "../queries/FetchHeadCategories.generated";

export const useCategoriesStore = () => {
  const [updateCategoriesPositionsMutation, { loading: loadingUpdate }] =
    useUpdateCategoriesPositionsMutation();

  const handleUpdateCategoriesPositions = async ({
    data,
  }: {
    data: { headCategoryId: string; sortedCategoryIds: string[] };
  }) => {
    await updateCategoriesPositionsMutation({
      variables: {
        data,
      },
      optimisticResponse: {
        updateCategoriesPositions: "successfully_updated",
      },
      update: (cache, { data: mutationData }) => {
        if (!mutationData || mutationData.updateCategoriesPositions !== "successfully_updated") {
          return;
        }

        const { headCategoryId, sortedCategoryIds } = data;

        const existingData: FetchHeadCategoriesQuery | null = cache.readQuery<
          FetchHeadCategoriesQuery,
          FetchHeadCategoriesQueryVariables
        >({
          query: FetchHeadCategoriesDocument,
          variables: { first: 100 },
        });

        if (!existingData) return;

        const { fetchHeadCategories } = existingData;

        const headCategoryEdge = fetchHeadCategories.edges.find(
          (edge) => edge.node.id === headCategoryId,
        );

        if (!headCategoryEdge) return;

        const sortedCategories = sortedCategoryIds
          .map((id) => headCategoryEdge.node.categories?.find((category) => category.id === id))
          .filter(Boolean) as typeof headCategoryEdge.node.categories;

        const newEdges = fetchHeadCategories.edges.map((edge) => {
          if (edge.node.id === headCategoryId) {
            return {
              ...edge,
              node: {
                ...edge.node,
                categories: sortedCategories,
              },
            };
          }
          return edge;
        });

        cache.writeQuery<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>({
          query: FetchHeadCategoriesDocument,
          variables: { first: 100 },
          data: {
            fetchHeadCategories: {
              ...fetchHeadCategories,
              edges: newEdges,
            },
          },
        });
      },
    });
  };

  return {
    loadingUpdate,
    updateCategoriesPositions: handleUpdateCategoriesPositions,
  };
};
