import { useTranslation } from "react-i18next";

import { Tab } from "@headlessui/react";

import {
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useAppointmentsStore } from "@/features/calendar/hooks/use-appointments-store";
import { AppointmentStatus, Client, SortOrder } from "@/types";

import { ClientAppointments } from "./ClientAppointments";
import { ClientInformation } from "./ClientInformation";
import { ClientProfileHeader } from "./ClientProfileHeader";
import { ClientSms } from "./ClientSms";
import { ClientUpcomingAppointment } from "./ClientUpcomingAppointment";

type ClientProfileProps = {
  client: Client;
};

const today = new Date();

export const ClientProfile = ({ client }: ClientProfileProps) => {
  const { t } = useTranslation();
  const tabs = [
    { name: t("clients.clientProfile.title"), icon: UserIcon },
    { name: t("generic.appointments"), icon: CalendarDaysIcon },
    { name: t("clients.sentMessages"), icon: ChatBubbleLeftEllipsisIcon },
  ];

  const { appointmentsPaginated: upcomingAppointments, loadingAppointmentsPaginated } =
    useAppointmentsStore({
      filters: {
        clientId: client?.id,
        status: AppointmentStatus.Scheduled,
        timeRange: {
          from: today.toISOString(),
        },
      },
      first: 1,
      sortByDatetime: SortOrder.Asc,
    });

  return (
    <article className="flex min-h-[600px] w-full flex-col rounded-lg @container lg:bg-white">
      {client && <ClientProfileHeader client={client} />}
      <Tab.Group>
        <Tab.List className="-mx-4 mt-5 box-border flex border-y border-stone-200 @[480px]:space-x-8 @[480px]:border-t-0 @[480px]:px-4 lg:bg-white">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className="w-full whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium outline-none ui-selected:border-gold-500 ui-selected:text-stone-900 ui-not-selected:border-transparent ui-not-selected:text-stone-500 ui-not-selected:hover:border-stone-300 ui-not-selected:hover:text-stone-700 @[480px]:w-fit">
              <span className="text-xs @[480px]:text-sm">{tab.name}</span>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4 flex flex-1 flex-col">
          <Tab.Panel>
            <ClientInformation client={client} />
            {!loadingAppointmentsPaginated && upcomingAppointments && (
              <ClientUpcomingAppointment upcomingAppointment={upcomingAppointments[0]} />
            )}
          </Tab.Panel>
          <Tab.Panel className="flex flex-1 flex-col">
            <ClientAppointments clientId={client.id} />
          </Tab.Panel>
          <Tab.Panel className="sm:px-8">
            <ClientSms clientId={client.id} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </article>
  );
};
