import { AddCategoryInput, namedOperations, UpdateCategoryInput } from "../../../types";
import { useAddCategoryMutation } from "../mutations/AddCategory.generated";
import {
  useDeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from "../mutations/DeleteCategory.generated";
import { useUpdateCategoryMutation } from "../mutations/UpdateCategory.generated";
import { useFetchCategoryQuery } from "../queries/FetchCategory.generated";

export const useCategoryStore = (categoryId?: string) => {
  const { data, loading } = useFetchCategoryQuery({
    variables: { categoryId },
    skip: !categoryId,
  });

  const [addCategory, { loading: loadingAdd }] = useAddCategoryMutation();
  const [deleteCategory, { loading: loadingDelete }] = useDeleteCategoryMutation();
  const [updateCategory, { loading: loadingUpdate }] = useUpdateCategoryMutation();

  return {
    loading,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
    category: data?.fetchCategory,
    addCategory(data: AddCategoryInput) {
      return addCategory({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    updateCategory(data: UpdateCategoryInput, categoryId: string) {
      return updateCategory({
        variables: { categoryId, data },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    deleteCategory({ categoryId }: DeleteCategoryMutationVariables) {
      return deleteCategory({
        variables: { categoryId },
        update(cache) {
          const normalizedId = cache.identify({
            id: categoryId,
            __typename: "Category",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
