import { useTranslation } from "react-i18next";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { Badge } from "@/components/ui/Badge";
import { Spinner } from "@/components/ui/Spinner";
import { useSalonStore } from "@/features/salon/hooks";

export const SelfBooking = () => {
  const { t } = useTranslation();
  const { salon, loading } = useSalonStore();

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return salon ? (
    <div className="mx-auto w-full">
      <div className="mb-4 flex items-center gap-2 ">
        <h3 className="text-2xl font-semibold text-stone-700">Self-booking</h3>
        <div className="-mb-1">
          <Badge color={salon.isPublic ? "green" : "nebula"} bordered uppercase>
            {t(salon.isPublic ? "generic.active" : "generic.inactive")}
          </Badge>
        </div>
      </div>
      <div className="mb-4 flex-1 border-t border-stone-200" />

      {salon.isPublic ? (
        <>
          <div className="flex justify-start">
            <span className="text-sm">{t("selfBooking.linkToSalon")}: </span>
            <a
              className="flex items-center text-sm text-gold-600 xs:ml-2"
              target="_blank"
              href={salon.selfBooking.url}>
              <span className="mr-1 overflow-hidden text-ellipsis">{salon.selfBooking.url}</span>
              <ArrowTopRightOnSquareIcon className="h-4 w-4 flex-shrink-0 text-gold-500" />
            </a>
          </div>
          {(salon.links?.terms || salon.links?.privacy) && (
            <div className="mb-5 mt-4 flex-1 border-t border-stone-200" />
          )}
          <div className="mt-4 flex gap-2">
            {salon.links?.terms && (
              <div className="flex">
                <a
                  className="flex items-center rounded-md border border-gold-500 px-4 py-1 text-sm text-gold-600"
                  target="_blank"
                  href={salon.links.terms}>
                  <span className="mr-1 overflow-hidden text-ellipsis">
                    {t("salonProfile.links.terms")}
                  </span>
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gold-600" />
                </a>
              </div>
            )}
            {salon.links?.privacy && (
              <div className="flex">
                <a
                  className="flex items-center rounded-md border border-gold-500 px-4 py-1 text-sm text-gold-600"
                  target="_blank"
                  href={salon.links.privacy}>
                  <span className="mr-1 overflow-hidden text-ellipsis">
                    {t("salonProfile.links.privacy")}
                  </span>
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gold-600" />
                </a>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-sm text-stone-900 sm:w-1/2">
          {t("salonProfile.selfBooking.inactiveDescription")}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
