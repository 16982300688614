import { createContext, useContext, useMemo } from "react";

import {
  EquipmentDialog,
  EquipmentFormProps,
} from "@/features/equipment/components/EquipmentDialog";
import { useItemStore } from "@/features/equipment/hooks/use-item-store";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { EquipmentItem } from "@/types";

type EquipmentContextProps = {
  createItem: () => void;
  updateItem: (item: EquipmentItem) => void;
  deleteItem: (id: string) => void;
};

type EquipmentProviderProps = {
  children: React.ReactNode;
};

const EquipmentContext = createContext<EquipmentContextProps | null>(null);

export const EquipmentProvider = ({ children }: EquipmentProviderProps) => {
  const item = useItemStore();

  const itemFormState = useDialog<EquipmentFormProps>();

  const handleCreate: EquipmentContextProps["createItem"] = () => {
    itemFormState.open({
      onClose: () => itemFormState.close(),
    });
  };

  const handleUpdate: EquipmentContextProps["updateItem"] = (item) => {
    itemFormState.open({
      item,
      onClose: () => itemFormState.close(),
    });
  };

  const handleDelete: EquipmentContextProps["deleteItem"] = (id) => {
    item.del(id);
  };

  const value = useMemo(() => {
    return {
      createItem: handleCreate,
      updateItem: handleUpdate,
      deleteItem: handleDelete,
    };
  }, [handleCreate, handleUpdate, handleDelete]);

  return (
    <EquipmentContext.Provider value={value}>
      {children}
      <EquipmentDialog {...bindDialogState(itemFormState)} />
    </EquipmentContext.Provider>
  );
};

export const useEquipmentContext = () => {
  const context = useContext(EquipmentContext);

  if (!context) {
    throw new Error("useEquipmentContext must be used within an EquipmentProvider");
  }

  return context;
};
