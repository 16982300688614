import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BanknotesIcon, ClockIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";

import { ATTENTION_COLOR, FINALIZED_COLOR } from "@features/calendar/constants";
import type { ServiceCategoryColor } from "@features/services/models";

import { isoTimeRangeToTime } from "@/features/calendar/utils/time";
import { isAppointmentTreatmentSlot, isBlockedSlot } from "@/features/calendar/utils/type-guards";
import { formatPrice } from "@/features/payments/utils";
import { AppointmentStatus } from "@/types";
import { resolveCategoryColor } from "@/utils/colors";
import { cn } from "@/utils/utils";

import { useCalendarDialogs } from "../../contexts/CalendarDialogContext";
import { UnknownCalendarSlot } from "../../models/index";

type CalendarWeekSlotProps = {
  slot: UnknownCalendarSlot;
};

export const CalendarWeekSlot = ({ slot }: CalendarWeekSlotProps) => {
  const { t } = useTranslation();

  const { selectAppointment } = useCalendarDialogs();
  const [color, setColor] = useState<ServiceCategoryColor>(FINALIZED_COLOR);

  const handleSlotClick = () => selectAppointment(slot);

  useEffect(() => {
    switch (slot.status) {
      case AppointmentStatus.Scheduled:
        setColor(
          slot.categoryColor
            ? resolveCategoryColor(slot.categoryColor) || FINALIZED_COLOR
            : FINALIZED_COLOR,
        );
        break;
      case AppointmentStatus.CanceledByClient:
        setColor(ATTENTION_COLOR);
        break;
      default:
        setColor(FINALIZED_COLOR);
        break;
    }
  }, [slot]);

  const { from, to } = useMemo(() => isoTimeRangeToTime(slot.timeRange), [slot.timeRange]);

  const isAppointment = isAppointmentTreatmentSlot(slot);
  const isBlocked = isBlockedSlot(slot);

  return (
    <li key={slot.id}>
      <div
        className={cn(
          "group relative flex cursor-pointer flex-col overflow-hidden rounded-md border text-xs leading-5 outline outline-1 outline-white",
          {
            pattern: isBlocked,
          },
          color.border,
          color.secondary,
        )}
        onClick={handleSlotClick}>
        <div
          className={cn(
            "flex items-center justify-between whitespace-nowrap px-2 py-0.5 text-[0.5rem] font-bold leading-4",
            color.primary,
            color.primaryText,
          )}>
          <div className="flex items-center gap-3">
            <div className="flex items-center">
              <ClockIcon className="mr-0.5 h-3 w-3 shrink-0" />
              <time dateTime={slot.timeRange.from}>{from}</time>
              &ndash;
              <time dateTime={slot.timeRange.to}>{to}</time>
            </div>
            {slot.suggestedPrice && (
              <div className="flex items-center">
                <BanknotesIcon className="mr-0.5 h-3 w-3 shrink-0" />

                <span>{formatPrice({ price: slot.suggestedPrice, currency: "PLN" })}</span>
              </div>
            )}
          </div>
          {(slot.isBulk || slot.isSelfBooked) && (
            <div className="flex items-center gap-1">
              {slot.isSelfBooked && <CursorArrowRaysIcon className="h-3 w-3 shrink-0" />}
              {slot.isBulk && <Square3Stack3DIcon className="h-3 w-3 shrink-0" />}
            </div>
          )}
        </div>
        <div className={cn("flex flex-col p-1 px-2", color.secondaryText || color.primaryText)}>
          <span className="text-[0.5rem] font-bold uppercase leading-4">
            {isAppointment && slot.clientDisplayName}
          </span>
          <p className="text-xs font-normal">
            {isAppointment && slot.treatmentName}
            {isBlocked && slot.title}
          </p>
        </div>
        {slot.status === AppointmentStatus.Requested && (
          <div className="absolute inset-0 h-full bg-stone-300/90 p-1">
            <ClockIcon className="mx-auto h-6 w-6 shrink-0 text-black" />
            <span className="whitespace-nowrap text-center text-xs font-bold text-black">
              {t("appointments.requestedAppointmentCaption")}
            </span>
          </div>
        )}
      </div>
    </li>
  );
};
