import { ForwardedRef, forwardRef, useMemo } from "react";

import { ArrowUturnLeftIcon, UserIcon } from "@heroicons/react/24/outline";

import { Avatar } from "@/components/ui/Avatar";
import { CalendarViews } from "@/features/calendar/models";
import { useEmployeesStore } from "@/features/employees/hooks/use-employees-store";
import { cn } from "@/utils/utils";

import { useCalendarFilters } from "../contexts/CalendarFiltersContext";
import { useCalendarView } from "../contexts/CalendarViewContext";

export const CalendarEmployeeHeader = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { view, setView } = useCalendarView();
  const { filters, setFilters } = useCalendarFilters();

  const { employees } = useEmployeesStore();

  const handleButtonClick = () => {
    setFilters({ employeeId: undefined });
    setView(view === CalendarViews.EmployeeDay ? CalendarViews.Day : CalendarViews.Week);
  };

  const employeeId = useMemo(() => filters.employeeId, [filters]);
  const employee = useMemo(
    () => employees.find((employee) => employee.accountId === employeeId),
    [employeeId, employees],
  );

  return (
    <div
      ref={ref}
      className="absolute left-0 right-0 top-[90px] z-40 flex h-11 justify-center border-x border-b border-stone-200 bg-white sm:top-0 sm:rounded-t-md sm:border sm:pr-8">
      <div
        className={cn(
          "flex text-sm leading-6 text-stone-500 sm:-mr-px",
          `grid-cols-${employees.length}`,
        )}>
        <div className="col-end-1 w-10" />
        <div className="-ml-10 flex flex-auto items-center justify-center">
          <button
            className="mr-3 h-6 rounded-md border border-stone-300 px-2 py-1 text-sm text-stone-500 shadow-sm transition-colors hover:border-stone-400 hover:text-stone-600 sm:mr-4 sm:px-3"
            onClick={() => handleButtonClick()}>
            <ArrowUturnLeftIcon className="h-3 w-3 sm:h-4 sm:w-4" aria-hidden="true" />
          </button>
          <Avatar imageUrl={employee?.imageUrl} fallbackIcon={UserIcon} />
          <span className="text-xs font-semibold text-stone-900">
            {employee && employee.fullName}
          </span>
        </div>
      </div>
    </div>
  );
});
