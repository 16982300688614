import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Switch } from "@headlessui/react";

import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";

import { setTimeToDate } from "@utils/datetime";
import { isPast, parseISO } from "date-fns";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";

import { useAppointmentStore } from "@features/calendar/hooks/use-appointment-store";

import { Alert } from "@/components/ui/Alert";
import { Button } from "@/components/ui/Button";
import { ImagesGallery } from "@/components/ui/ImagesGallery";
import { Spinner } from "@/components/ui/Spinner";
import { useClientStore } from "@/features/clients/hooks";
import { priceToInteger } from "@/features/payments/utils";
import { useCountersStore } from "@/features/session/hooks";
import { useSessionContext } from "@/providers/SessionProvider";
import { useToasts } from "@/providers/ToastsProvider";
import {
  AppointmentFragment,
  AppointmentStatus,
  FinalizeBulkAppointmentInput,
  UpdateBulkAppointmentInput,
  UpdateBulkAppointmentTreatmentInput,
} from "@/types";
import { sendEvent } from "@/utils/google-analytics";
import { cn } from "@/utils/utils";

import { useCalendarDialogs } from "../../contexts/CalendarDialogContext";
import { AppointmentDetails } from "./AppointmentDetails";
import { AppointmentEditForm } from "./AppointmentEditForm";
import { AppointmentFinalizationSummary } from "./AppointmentFinalizationSummary";
import { AppointmentsFinalizeForm } from "./AppointmentsFinalizeForm";

export type AppointmentDetailsProps = {
  appointmentId: string;
};

type AppointmentDetailsDialogProps = {
  props?: AppointmentDetailsProps;
  open: boolean;
  onClose: () => void;
};

type FormData = Omit<UpdateBulkAppointmentInput, "treatments"> & {
  treatments: Array<
    UpdateBulkAppointmentTreatmentInput & {
      treatmentName?: string;
      employeeName?: AppointmentFragment["treatments"][number]["employeeName"];
    }
  >;
  date: Date;
};

type FinalizeFormData = Pick<
  FinalizeBulkAppointmentInput,
  "status" | "treatments" | "paymentMethodId"
>;

export enum AppointmentDetailsViewStatus {
  PRESENTATION,
  EDIT,
  FINALIZE,
}

export const AppointmentDetailsDialog = ({
  open,
  props,
  onClose,
}: AppointmentDetailsDialogProps) => {
  const { t } = useTranslation();
  const {
    appointment,
    undo,
    update,
    approve,
    loadingFetch,
    loadingUpdate,
    suggestedPrices,
    finalize,
  } = useAppointmentStore({
    id: props?.appointmentId,
    withSuggestedPrice: true,
  });
  const { showToast } = useToasts();
  const { smsLimit } = useCountersStore();
  const { client } = useClientStore(appointment?.clientId);
  const { permissions } = useSessionContext();

  const [hasAppointmentStarted, setAppointmentStarted] = useState(false);
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [appointmentDetailsViewStatus, setAppointmentDetailsViewStatus] = useState(
    AppointmentDetailsViewStatus.PRESENTATION,
  );

  const { openCancelDialog } = useCalendarDialogs();

  const handleAccept = async () => {
    if (!appointment) return;

    const { errors } = await approve({
      appointmentId: appointment?.appointmentId,
      clientId: appointment.clientId || undefined,
      note: appointment.note,
      sendNotification: shouldSendNotification,
      treatments: appointment.treatments.map(({ timeRange, treatmentId, employeeId }) => ({
        timeRange: {
          from: timeRange.from,
          to: timeRange.to,
        },
        treatmentId,
        employeeId,
      })),
    });

    if (!errors) onClose();
  };

  const handleEditSubmit: SubmitHandler<FormData> = async (data) => {
    await update({
      appointmentId: appointment?.appointmentId,
      clientId: data.clientId || undefined,
      note: data.note,
      imagesUrls: data.imagesUrls,
      sendNotification: data.sendNotification,
      treatments: data.treatments.map(
        ({ appointmentTreatmentId, treatmentId, employeeId, timeRange }) => ({
          appointmentTreatmentId,
          treatmentId,
          employeeId,
          timeRange: {
            from: setTimeToDate(data.date, timeRange.from),
            to: setTimeToDate(data.date, timeRange.to),
          },
        }),
      ),
    });
  };

  const handleFinalize: SubmitHandler<FinalizeFormData> = async (data) => {
    await finalize({
      status: data.status,
      appointmentId: appointment?.appointmentId,
      paymentMethodId: data.paymentMethodId || null,
      treatments: data.treatments.map(({ appointmentTreatmentId, finalPrice }) => ({
        appointmentTreatmentId,
        finalPrice: priceToInteger(finalPrice || 0),
      })),
    });
  };

  const handleUndoFinalizing = async () => {
    sendEvent("undo_finalizing", "appointments");

    const { errors } = await undo();

    if (!errors) {
      showToast({
        title: t("appointments.finalizationUndone"),
      });
    }
  };

  const handleCancel = () => {
    appointment &&
      openCancelDialog({
        appointmentId: appointment.appointmentId,
        onConfirm: onClose,
      });
  };

  const shouldDisableNotification = useMemo(() => {
    return smsLimit <= 0 || !client?.phone;
  }, [smsLimit, client]);

  const shouldSendNotification = isNotificationActive && !shouldDisableNotification;

  useEffect(() => {
    const checkIfStarted = () =>
      setAppointmentStarted(
        !!appointment?.treatments.some(({ timeRange }) => isPast(parseISO(timeRange.from))),
      );

    checkIfStarted();
    const interval = setInterval(checkIfStarted, 10000);
    return () => clearInterval(interval);
  }, [appointment]);

  useEffect(() => {
    if (!open) {
      setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.PRESENTATION);
      setIsNotificationActive(false);
    }
  }, [open]);

  const renderActions = () => {
    switch (appointment?.status) {
      case AppointmentStatus.Closed:
      case AppointmentStatus.Completed:
        return (
          <div className="flex-col space-y-3">
            <div className="flex gap-4">
              <Button
                fullWidth
                variant="primary-outline"
                startIcon={<ArrowUturnLeftIcon />}
                disabled={!permissions.unfinalize_appointment}
                onClick={handleUndoFinalizing}>
                {t("appointments.undoFinalizing")}
              </Button>
            </div>
          </div>
        );
      case AppointmentStatus.Scheduled:
        return (
          <div className="flex-col space-y-3">
            <div className="flex flex-wrap gap-4">
              <Button
                variant="danger-outline"
                onClick={handleCancel}
                disabled={!permissions.delete_appointment}
                className="w-1/2 min-w-fit flex-1">
                {t("appointments.cancel")}
              </Button>
              <Button
                className="w-1/2 min-w-fit flex-1"
                variant="primary-outline"
                disabled={!permissions.edit_appointment}
                onClick={() => setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.EDIT)}>
                {t("appointments.edit")}
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                variant="primary"
                onClick={() =>
                  setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.FINALIZE)
                }
                disabled={!hasAppointmentStarted || !permissions.finalize_appointment}>
                {t("appointments.finalize")}
              </Button>
            </div>
          </div>
        );
      case AppointmentStatus.Requested:
        return (
          <div className="flex-col space-y-3">
            <div className="mt-4 flex gap-4">
              <Button
                variant="danger-outline"
                className="w-1/2 min-w-fit flex-1"
                disabled={!permissions.delete_appointment}
                onClick={handleCancel}>
                {t("appointments.decline")}
              </Button>
              <Button
                className="w-1/2 min-w-fit flex-1"
                variant="primary-outline"
                disabled={!permissions.edit_appointment}
                onClick={() => setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.EDIT)}>
                {t("appointments.edit")}
              </Button>
            </div>
            <Button onClick={handleAccept} fullWidth disabled={!permissions.accept_appointment}>
              {t("appointments.accept")}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const setDialogTitle = () => {
    switch (appointmentDetailsViewStatus) {
      case AppointmentDetailsViewStatus.PRESENTATION:
        return appointment?.status === AppointmentStatus.Requested
          ? t("appointments.request")
          : t("appointments.details");
      case AppointmentDetailsViewStatus.EDIT:
        return t("appointments.edit");
      case AppointmentDetailsViewStatus.FINALIZE:
        return t("appointments.finalize");
    }
  };

  return (
    <DefaultDialog open={open} onClose={onClose} title={setDialogTitle()}>
      {appointment && !loadingFetch && !loadingUpdate ? (
        <section className="flex flex-1 flex-col justify-between">
          {appointmentDetailsViewStatus === AppointmentDetailsViewStatus.PRESENTATION ? (
            <>
              <AppointmentDetails appointment={appointment} client={client} onClose={onClose} />
              {AppointmentStatus.Requested === appointment.status && (
                <Switch.Group
                  as={"div"}
                  className={cn(
                    isNotificationActive && "mb-4",
                    "mt-2.5 flex items-center self-start sm:mb-0",
                  )}>
                  <Switch.Label
                    className={cn(
                      shouldDisableNotification ? "text-stone-400" : "text-stone-700",
                      "mr-2 text-sm",
                    )}>
                    {t("appointments.smsInfo.acceptAppointment")}
                  </Switch.Label>
                  <Switch
                    checked={isNotificationActive}
                    onChange={setIsNotificationActive}
                    disabled={shouldDisableNotification}
                    className={cn(
                      shouldDisableNotification
                        ? "bg-stone-200"
                        : isNotificationActive
                        ? "bg-gold-500"
                        : "bg-stone-400",
                      "relative mr-4 inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-gold-600 focus:ring-offset-2",
                    )}>
                    <span
                      className={`${
                        isNotificationActive ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform cursor-pointer rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </Switch.Group>
              )}
            </>
          ) : null}
          {appointmentDetailsViewStatus === AppointmentDetailsViewStatus.EDIT ? (
            <AppointmentEditForm
              client={client}
              onBackClick={() =>
                setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.PRESENTATION)
              }
              onSubmit={(data) => {
                handleEditSubmit(data);
                onClose();
              }}
              appointment={appointment}
              onClose={onClose}
            />
          ) : null}
          {appointmentDetailsViewStatus === AppointmentDetailsViewStatus.FINALIZE ? (
            <AppointmentsFinalizeForm
              client={client}
              onClose={onClose}
              appointment={appointment}
              suggestedPrices={suggestedPrices}
              onBackClick={() =>
                setAppointmentDetailsViewStatus(AppointmentDetailsViewStatus.PRESENTATION)
              }
              onSubmit={(data) => {
                handleFinalize(data);
                onClose();
              }}
            />
          ) : null}
          {appointmentDetailsViewStatus === AppointmentDetailsViewStatus.PRESENTATION ? (
            <div className="mt-auto pt-4">
              {appointment.note && (
                <div className="mt-4">
                  <h2 className="mb-1 text-xs font-normal text-stone-500">
                    {t("appointments.internalNote")}
                  </h2>
                  <p className="mb-4 mt-1 rounded-md bg-stone-100 px-3 py-2 text-sm text-stone-700">
                    {appointment.note}
                  </p>
                </div>
              )}
              {appointment?.imagesUrls && appointment?.imagesUrls.length > 0 && (
                <div className="my-4">
                  <h2 className="mb-1 text-xs font-normal text-stone-500">{t("generic.images")}</h2>
                  <ImagesGallery imagesUrls={appointment.imagesUrls} />
                </div>
              )}
              <div className="mb-4">
                {appointment.status === "COMPLETED" && (
                  <AppointmentFinalizationSummary appointment={appointment} />
                )}
                {appointment.status === "CLOSED" && (
                  <Alert type="error" textSize="xs">
                    {t("appointments.statusInfo.closed.description")}
                  </Alert>
                )}
                {appointment.status === "SCHEDULED" &&
                  isPast(
                    new Date(
                      appointment.treatments[appointment.treatments.length - 1].timeRange.to,
                    ),
                  ) && (
                    <Alert type="info" textSize="xs">
                      {t("appointments.statusInfo.ended")}
                    </Alert>
                  )}
              </div>
              <div className="grid gap-2">{renderActions()}</div>
            </div>
          ) : null}
        </section>
      ) : (
        <div className="flex h-screen w-full items-center justify-center sm:h-96">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </DefaultDialog>
  );
};
