import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EquipmentItemFragmentDoc } from '../fragments/EquipmentItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEquipmentItemMutationVariables = Types.Exact<{
  data: Types.UpdateEquipmentItemInput;
  equipmentItemId: Types.Scalars['UUID4'];
}>;


export type UpdateEquipmentItemMutation = { __typename?: 'RootMutationType', updateEquipmentItem: { __typename?: 'EquipmentItem', id: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentId: any }> } };


export const UpdateEquipmentItemDocument = gql`
    mutation UpdateEquipmentItem($data: UpdateEquipmentItemInput!, $equipmentItemId: UUID4!) {
  updateEquipmentItem(data: $data, equipmentItemId: $equipmentItemId) {
    ...EquipmentItem
  }
}
    ${EquipmentItemFragmentDoc}`;
export type UpdateEquipmentItemMutationFn = Apollo.MutationFunction<UpdateEquipmentItemMutation, UpdateEquipmentItemMutationVariables>;

/**
 * __useUpdateEquipmentItemMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentItemMutation, { data, loading, error }] = useUpdateEquipmentItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *      equipmentItemId: // value for 'equipmentItemId'
 *   },
 * });
 */
export function useUpdateEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentItemMutation, UpdateEquipmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEquipmentItemMutation, UpdateEquipmentItemMutationVariables>(UpdateEquipmentItemDocument, options);
      }
export type UpdateEquipmentItemMutationHookResult = ReturnType<typeof useUpdateEquipmentItemMutation>;
export type UpdateEquipmentItemMutationResult = Apollo.MutationResult<UpdateEquipmentItemMutation>;
export type UpdateEquipmentItemMutationOptions = Apollo.BaseMutationOptions<UpdateEquipmentItemMutation, UpdateEquipmentItemMutationVariables>;
