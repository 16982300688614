import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SalonPaymentMethod } from "@/types";

type SalonPaymentMethodWithIcon = SalonPaymentMethod & {
  icon: React.ReactNode;
};

type PaymentMethodsFormFieldProps = {
  paymentMethod: SalonPaymentMethodWithIcon;
};

export const PaymentMethodsFormField = ({ paymentMethod }: PaymentMethodsFormFieldProps) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <li key={paymentMethod?.id}>
      <input
        id={paymentMethod?.id}
        type="checkbox"
        className="peer hidden"
        defaultChecked={paymentMethod?.enabled}
        {...register(`paymentMethods.${paymentMethod?.id}.enabled`)}
      />
      <label
        htmlFor={paymentMethod?.id}
        className="inline-flex h-11 w-full cursor-pointer items-center rounded-md border border-stone-200 px-4 text-sm text-stone-500 transition-colors duration-300 ease-in-out peer-checked:border-stone-600 peer-checked:text-stone-600 peer-checked:shadow-sm">
        {paymentMethod?.icon}
        {t(`paymentMethods.${paymentMethod?.name}`)}
      </label>
    </li>
  );
};
