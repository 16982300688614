import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client";

import { Appointment } from "@features/calendar/type-policies";
import { Client, clientsQueryFields } from "@features/clients/type-policies";
import { Employee, employeesQueryFields } from "@features/employees/type-policies";
import {
  Treatment,
  SearchTreatment,
  treatmentsQueryFields,
} from "@features/services/type-policies";
import { CurrentSession, authQueryFields } from "@features/session/type-policies";

import { BlockedSlot } from "@/features/calendar/type-policies/blocked-slots";
import { itemsQueryFields, EquipmentItem } from "@/features/equipment/type-policies";
import { sentSmsQueryFields } from "@/features/messages/type-policies";
import { Sms } from "@/features/messages/type-policies/sentSms";
import generatedTypes from "@/types";

export const getCachePolicy = () =>
  new InMemoryCache({
    possibleTypes: generatedTypes.possibleTypes,
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "Client":
        case "Treatment":
        case "HeadCategory":
        case "Category":
        case "EquipmentItem":
        case "BlockedSlot":
        case "ClientTag":
        case "Sms":
          return `${responseObject.__typename}:${responseObject.id}`;
        case "SearchTreatment":
          return `${responseObject.__typename}:${responseObject.treatmentId}`;
        case "Employee":
          return `${responseObject.__typename}:${responseObject.accountId}`;
        case "Appointment":
          return `${responseObject.__typename}:${responseObject.appointmentId}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          ...authQueryFields,
          ...clientsQueryFields,
          ...employeesQueryFields,
          ...treatmentsQueryFields,
          ...sentSmsQueryFields,
          ...itemsQueryFields,
        },
      },
      CurrentSession,
      Client,
      Employee,
      Treatment,
      SearchTreatment,
      Appointment,
      Sms,
      EquipmentItem,
      BlockedSlot,
    },
  });
