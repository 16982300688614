import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonSmsTemplateFragment = { __typename?: 'SalonSmsTemplate', description?: string | null, salonId: any, title: string, type?: string | null, id: any };

export const SalonSmsTemplateFragmentDoc = gql`
    fragment SalonSmsTemplate on SalonSmsTemplate {
  description
  salonId
  title
  type
  id
}
    `;
