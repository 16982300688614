import { Fragment, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { Disclosure, Menu, Transition } from "@headlessui/react";

import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Float } from "@headlessui-float/react";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { Button } from "@/components/ui/Button";
import { FINALIZED_COLOR } from "@/features/calendar/constants";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { Category as CategoryType, Treatment as TreatmentType } from "@/types";
import { resolveCategoryColor } from "@/utils/colors";
import { cn } from "@/utils/utils";

import { useCategoryStore, useTreatmentsStore } from "../../hooks";
import { CategoryFormDialog, CategoryFormProps } from "../categories/CategoryFormDialog";
import { Treatment } from "./Treatment";

type CategoryProps = {
  category: CategoryType;
  showDndHandle?: boolean;
};

export const Category = ({ category, showDndHandle }: CategoryProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionContext();
  const { deleteCategory } = useCategoryStore();
  const { treatments, updateTreatmentsPositions } = useTreatmentsStore(category.id);
  const dialogState = useDialog<CategoryFormProps>();
  const confirmationDialogState = useConfirmationDialog();

  const color = resolveCategoryColor(category.color) || FINALIZED_COLOR;

  const treatmentIds = useMemo(() => treatments.map((treatment) => treatment.id), [treatments]);
  const [activeTreatment, setActiveTreatment] = useState<TreatmentType | null>(null);

  const handleCategoryEdit = (category: CategoryType) =>
    dialogState.open({
      categoryId: category.id,
      headCategoryId: category.headCategoryId,
      onRemove: handleCategoryRemove,
    });

  const handleCategoryRemove: (category: CategoryType) => Promise<boolean> = ({ id, name }) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.categories.delete"),
        message: t("services.categories.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteCategory({ categoryId: id });
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: category.id,
    data: {
      type: "category",
      category,
    },
    disabled: !permissions.manage_treatments || !showDndHandle,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 8,
      },
    }),
  );

  const handleTreatmentDragEnd = (
    activeTreatment: TreatmentType,
    overTreatment: TreatmentType,
  ): void => {
    if (!activeTreatment || !overTreatment) return;

    const categoryId = category.id;
    const treatmentIds = treatments.map((treatment) => treatment.id);

    const activeIndex = treatmentIds.indexOf(activeTreatment.id);
    const overIndex = treatmentIds.indexOf(overTreatment.id);

    if (activeIndex === overIndex) return;

    const newTreatmentIds = [...treatmentIds];
    newTreatmentIds.splice(activeIndex, 1);
    newTreatmentIds.splice(overIndex, 0, activeTreatment.id);

    updateTreatmentsPositions({
      categoryId: categoryId,
      sortedTreatmentIds: newTreatmentIds,
    });
  };

  const onTreatmentDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === "treatment") {
      const treatment = event.active.data.current.treatment as TreatmentType;
      setActiveTreatment(treatment);
      return;
    }
  };

  const onTreatmentDragEnd = (event: DragEndEvent) => {
    setActiveTreatment(null);

    const { active, over } = event;

    if (!over) return;

    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;

    if (activeType === "treatment" && overType === "treatment") {
      const activeTreatment = event.active.data.current?.treatment as TreatmentType;
      const overTreatment = event.over?.data.current?.treatment as TreatmentType;
      handleTreatmentDragEnd(activeTreatment, overTreatment);
    }
  };

  return (
    <>
      <Disclosure as="div" ref={setNodeRef} style={style} className="relative select-none">
        {({ open }) => (
          <>
            {isDragging && (
              <div className="absolute inset-0 z-10 rounded-lg border border-dashed border-gold-200 bg-gold-50" />
            )}
            <Disclosure.Button
              as="div"
              className="group flex cursor-pointer flex-col gap-3 py-6 hover:bg-stone-50/50">
              <div className="flex grow items-center justify-between">
                <div className="flex items-center gap-3">
                  {showDndHandle && permissions.manage_treatments && (
                    <div
                      {...attributes}
                      {...listeners}
                      className={cn(
                        isDragging ? "cursor-grabbing" : "cursor-grab",
                        "mx-1 flex h-6 w-4 scale-90 select-none items-center justify-center rounded-sm bg-white sm:m-0 sm:scale-[65%]",
                      )}>
                      <div className="grid h-full w-full grid-cols-2 items-center justify-center px-[2px] py-1">
                        {Array.from({ length: 6 }).map((_, index) => (
                          <span
                            key={index}
                            className="m-auto h-1 w-1 rounded-full bg-stone-300 hover:bg-stone-400"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className={cn("h-6 w-1 rounded-full", color.primary)}></div>
                  <div className="font-medium text-stone-700">{category.name}</div>
                </div>
                <div className="flex gap-1 sm:gap-2">
                  {permissions.manage_treatments && (
                    <Menu as="div" className="relative inline-block text-left">
                      <Float offset={2} placement={"bottom-end"} portal>
                        <Menu.Button className="flex size-8 items-center justify-center rounded-full transition-all sm:bg-transparent sm:hover:bg-stone-100">
                          <EllipsisVerticalIcon className="size-6 text-stone-700" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items
                            as="ul"
                            className="w-fill my-2 min-w-28 origin-top-right divide-y divide-stone-100 rounded-md bg-white shadow-lg ring-1 ring-stone-300 focus:outline-none">
                            <Menu.Item
                              as="li"
                              className="py-1 text-stone-500 group-hover:text-stone-600">
                              {({ active }) => (
                                <Button
                                  variant="neutral"
                                  onClick={() => {
                                    handleCategoryEdit(category);
                                  }}
                                  className={cn(
                                    "group flex w-full items-center px-4 py-2 text-xs",
                                    active && "bg-stone-100",
                                  )}>
                                  <PencilSquareIcon className="mr-3 size-4" aria-hidden="true" />
                                  {t("generic.edit")}
                                </Button>
                              )}
                            </Menu.Item>
                            <Menu.Item
                              as="li"
                              className="py-1 text-red-500 group-hover:text-stone-600">
                              {({ active }) => (
                                <Button
                                  variant="neutral"
                                  onClick={() => {
                                    handleCategoryRemove(category);
                                  }}
                                  className={cn(
                                    "group flex w-full items-center px-4 py-2 text-xs",
                                    active && "bg-stone-100",
                                  )}>
                                  <TrashIcon className="mr-3 size-4" aria-hidden="true" />
                                  {t("generic.delete")}
                                </Button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Float>
                    </Menu>
                  )}
                  <div className="flex size-8 items-center justify-center rounded-full transition-all hover:bg-stone-100">
                    <ChevronRightIcon
                      className={cn(
                        "size-6 text-stone-700",
                        open ? "rotate-90 transform transition-transform" : "",
                      )}
                    />
                  </div>
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="relative divide-y divide-dashed divide-stone-200">
                {treatments.length > 0 ? (
                  <DndContext
                    onDragStart={onTreatmentDragStart}
                    onDragEnd={onTreatmentDragEnd}
                    collisionDetection={closestCorners}
                    modifiers={[restrictToParentElement]}
                    sensors={sensors}>
                    <SortableContext items={treatmentIds} strategy={verticalListSortingStrategy}>
                      {treatments.map((treatment) => (
                        <Treatment
                          key={treatment.id}
                          treatment={treatment}
                          showDndHandle={treatments.length > 1}
                        />
                      ))}
                    </SortableContext>
                    {createPortal(
                      <DragOverlay className="select-none">
                        {activeTreatment && <Treatment treatment={activeTreatment} />}
                      </DragOverlay>,
                      document.body,
                    )}
                  </DndContext>
                ) : (
                  <div className="flex flex-col justify-center rounded-lg border border-dashed border-stone-200 px-4 py-6 text-center">
                    <ListBulletIcon className="mx-auto mb-2 size-8 text-stone-700" />
                    <span className="mb-0.5 text-xs font-semibold text-slate-900">
                      {t("services.treatments.noTreatments")}
                    </span>
                    <p className="mb-4 text-sm text-stone-500">
                      {t("services.treatments.addFirstTreatment", {
                        category: category.name,
                      })}
                    </p>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <CategoryFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </>
  );
};
