import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../fragments/Employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddEmployeeMutationVariables = Types.Exact<{
  data: Types.AddEmployeeInput;
}>;


export type AddEmployeeMutation = { __typename?: 'RootMutationType', addSalonEmployee: { __typename?: 'Employee', accountId: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Types.Role, permissions?: any | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: any | null, treatments: Array<{ __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };


export const AddEmployeeDocument = gql`
    mutation AddEmployee($data: AddEmployeeInput!) {
  addSalonEmployee(data: $data) {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;
export type AddEmployeeMutationFn = Apollo.MutationFunction<AddEmployeeMutation, AddEmployeeMutationVariables>;

/**
 * __useAddEmployeeMutation__
 *
 * To run a mutation, you first call `useAddEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeMutation, { data, loading, error }] = useAddEmployeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeMutation, AddEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeMutation, AddEmployeeMutationVariables>(AddEmployeeDocument, options);
      }
export type AddEmployeeMutationHookResult = ReturnType<typeof useAddEmployeeMutation>;
export type AddEmployeeMutationResult = Apollo.MutationResult<AddEmployeeMutation>;
export type AddEmployeeMutationOptions = Apollo.BaseMutationOptions<AddEmployeeMutation, AddEmployeeMutationVariables>;
