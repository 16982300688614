import { Fragment, useCallback, useLayoutEffect, useState } from "react";

import { Transition } from "@headlessui/react";

import {
  AUTOSCROLL_OFFSET,
  MINUTES_PER_DAY,
  NUMBER_OF_DAYS_IN_WEEK,
  SCALE_STEPS_PER_DAY,
} from "@features/calendar/constants";
import { SlotRowsByEmployee } from "@features/calendar/models";

import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";
import { useCalendarStore } from "../../hooks/use-calendar-store";
import { CalendarDaysNavigation } from "../CalendarDaysNavigation";
import { CalendarEmployeeHeader } from "../CalendarEmployeeHeader";
import { CalendarLoader } from "../CalendarLoader";
import { CalendarScaleBar } from "../CalendarScaleBar";
import { CalendarSlotsGroup } from "../CalendarSlotsGroup";
import { CalendarEmployeeWeekAvailability } from "./CalendarEmployeeWeekAvailability";
import { CalendarEmployeeWeekHeader } from "./CalendarEmployeeWeekHeader";
import { CalendarEmployeeWeekPlaceholder } from "./CalendarEmployeeWeekPlaceholder";

export const CalendarEmployeeWeekContent = () => {
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [scaleElement, setScaleElement] = useState<HTMLElement | null>(null);
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);

  const { filters } = useCalendarFilters();
  const { view } = useCalendarView();
  const { timeRange } = useCalendarDate();

  const { slotRowsByEmployee: slotGroups, loading } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });

  const containerRef = useCallback(
    (element: HTMLElement | null) => setContainerElement(element),
    [],
  );
  const headerRef = useCallback((element: HTMLElement | null) => setHeaderElement(element), []);
  const scaleRef = useCallback((element: HTMLElement | null) => setScaleElement(element), []);

  const hasMoreThanOneEmployee = slotGroups.length > 1 || filters.employeeId !== undefined;

  const handleResize = useCallback(() => {
    if (!containerElement || !headerElement || !scaleElement) return;

    const currentPeriodOffset = new Date().getHours() * 60 - AUTOSCROLL_OFFSET || 0;

    const scrollableHeight =
      containerElement.scrollHeight -
      (headerElement?.offsetHeight || 0) -
      ((scaleElement?.firstChild as HTMLDivElement)?.offsetHeight || 0);

    const offset = (scrollableHeight * currentPeriodOffset) / MINUTES_PER_DAY;

    containerElement.scrollTo({ top: offset, behavior: "smooth" });
  }, [containerElement, headerElement, scaleElement]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useLayoutEffect(() => {
    const calcIndicatorOffset = () => {
      if (!scaleElement) return;

      const containerHeight = scaleElement?.offsetHeight || 0;
      const offsetHeight = (scaleElement?.firstChild as HTMLElement)?.offsetHeight || 0;
      const height = containerHeight - offsetHeight;
      const pxPerMinute = height > 0 ? height / MINUTES_PER_DAY : 0;

      const now = new Date();
      const currentMinute = now.getHours() * 60 + now.getMinutes();

      setIndicatorOffset(currentMinute * pxPerMinute);
    };

    calcIndicatorOffset();
    const intervalId = window.setInterval(calcIndicatorOffset, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [scaleElement]);

  if (loading) {
    return <CalendarLoader />;
  }

  return (
    <div className="flex h-full flex-col">
      <div
        ref={containerRef}
        className="isolate flex flex-auto flex-col overflow-auto overscroll-none rounded-md border bg-white">
        <div className="flex w-fit min-w-fit flex-col 2xl:!w-full 2xl:max-w-full">
          {/* A dumb element to cover a small piece of scrollable header */}
          <div
            className={cn(
              hasMoreThanOneEmployee
                ? "sm:top-[43px] sm:rounded-none"
                : "sm:top-0 sm:rounded-tl-md",
              "hidden sm:absolute sm:left-0 sm:z-50 sm:block sm:size-[42px] sm:border sm:border-b-stone-200 sm:bg-white",
            )}></div>
          {hasMoreThanOneEmployee && <CalendarEmployeeHeader ref={headerRef} />}
          <CalendarEmployeeWeekHeader
            ref={headerRef}
            hasMoreThanOneEmployee={hasMoreThanOneEmployee}
          />
          <CalendarDaysNavigation ref={headerRef} />

          <div
            className={cn(
              hasMoreThanOneEmployee ? "sm:pt-6" : "sm:pt-0",
              "flex flex-auto pb-0 pt-28",
            )}>
            <div className="sticky left-0 z-30 w-10 flex-none bg-white/60 ring-1 ring-stone-100 backdrop-blur-sm"></div>
            <div className="relative grid flex-auto grid-cols-1 grid-rows-1">
              <Transition
                show={indicatorOffset > 0}
                appear
                as={Fragment}
                enter="transition-[opacity,_right] duration-500 ease-in"
                enterFrom="opacity-0 right-full"
                enterTo="opacity-80 right-0"
                leave="transition-[opacity,_right] duration-500 ease-in"
                leaveFrom="opacity-80 right-0"
                leaveTo="opacity-0 right-full">
                <div
                  className="border-1 absolute left-0 right-0 z-30 mt-7 h-[2px] border border-white border-opacity-25 bg-red-500 before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-red-500 before:content-['']"
                  style={{ top: `${indicatorOffset}px` }}></div>
              </Transition>
              <CalendarEmployeeWeekAvailability />
              <CalendarScaleBar ref={scaleRef} />
              <CalendarEmployeeWeekPlaceholder />

              <ol
                className="col-start-1 col-end-2 row-start-1 grid sm:pr-8"
                style={{
                  gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
                  gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))`,
                }}>
                {slotGroups.map((slotGroup: SlotRowsByEmployee) =>
                  slotGroup.slotRows.map((slotRow) => (
                    <CalendarSlotsGroup key={slotRow.from} group={slotRow} />
                  )),
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
