import { AddHeadCategoryInput, namedOperations, UpdateHeadCategoryInput } from "@/types";

import { useAddHeadCategoryMutation } from "../mutations/AddHeadCategory.generated";
import {
  useDeleteHeadCategoryMutation,
  DeleteHeadCategoryMutationVariables,
} from "../mutations/DeleteHeadCategory.generated";
import { useUpdateHeadCategoryMutation } from "../mutations/UpdateHeadCategory.generated";
import { useFetchHeadCategoryQuery } from "../queries/FetchHeadCategory.generated";

export const useHeadCategoryStore = (headCategoryId?: string) => {
  const { data, loading } = useFetchHeadCategoryQuery({
    variables: { categoryId: headCategoryId },
    skip: !headCategoryId,
  });

  const [addHeadCategory, { loading: loadingAdd }] = useAddHeadCategoryMutation();
  const [updateHeadCategory, { loading: loadingUpdate }] = useUpdateHeadCategoryMutation();
  const [deleteHeadCategory, { loading: loadingDelete }] = useDeleteHeadCategoryMutation();

  return {
    loading,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
    headCategory: data?.fetchHeadCategory,
    categories: data?.fetchHeadCategory?.categories || [],
    addHeadCategory(data: AddHeadCategoryInput) {
      return addHeadCategory({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    updateHeadCategory(data: UpdateHeadCategoryInput, id: string) {
      return updateHeadCategory({
        variables: { data, categoryId: id },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    deleteHeadCategory({ categoryId }: DeleteHeadCategoryMutationVariables) {
      return deleteHeadCategory({
        variables: { categoryId },
        update(cache) {
          const normalizedId = cache.identify({
            id: categoryId,
            __typename: "HeadCategory",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        refetchQueries: [namedOperations.Query.FetchAllTreatments],
      });
    },
  };
};
