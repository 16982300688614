import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBulkAppointmentMutationVariables = Types.Exact<{
  data: Types.UpdateBulkAppointmentInput;
}>;


export type UpdateBulkAppointmentMutation = { __typename?: 'RootMutationType', updateBulkAppointment: { __typename?: 'Appointment', appointmentId: any, clientId?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodId?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentId: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const UpdateBulkAppointmentDocument = gql`
    mutation UpdateBulkAppointment($data: UpdateBulkAppointmentInput!) {
  updateBulkAppointment(data: $data) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;
export type UpdateBulkAppointmentMutationFn = Apollo.MutationFunction<UpdateBulkAppointmentMutation, UpdateBulkAppointmentMutationVariables>;

/**
 * __useUpdateBulkAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateBulkAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkAppointmentMutation, { data, loading, error }] = useUpdateBulkAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBulkAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBulkAppointmentMutation, UpdateBulkAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBulkAppointmentMutation, UpdateBulkAppointmentMutationVariables>(UpdateBulkAppointmentDocument, options);
      }
export type UpdateBulkAppointmentMutationHookResult = ReturnType<typeof useUpdateBulkAppointmentMutation>;
export type UpdateBulkAppointmentMutationResult = Apollo.MutationResult<UpdateBulkAppointmentMutation>;
export type UpdateBulkAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateBulkAppointmentMutation, UpdateBulkAppointmentMutationVariables>;
