import { namedOperations, SalonPaymentMethodInput } from "@/types";
import { Option } from "@/utils/select-utils";

import { useUpdateSalonPaymentMethodsMutation } from "../mutations/UpdateSalonPaymentMethods.generated";
import { useFetchSalonPaymentMethodsQuery } from "../queries/FetchSalonPaymentMethods.generated";

export const useSalonPaymentMethodsStore = (skipFetch = false) => {
  const { data, loading: fetchLoading } = useFetchSalonPaymentMethodsQuery({
    skip: skipFetch,
  });

  const [updateSalonPaymentMethods, { loading: updatePaymentMethodsLoading }] =
    useUpdateSalonPaymentMethodsMutation();

  const salonActivePaymentMethodsOptions =
    data?.fetchSalonPaymentMethods?.reduce((acc, method) => {
      if (method.enabled) {
        acc.push({
          label: method.name,
          value: method.id,
        });
      }

      return acc;
    }, [] as Option[]) || [];

  return {
    fetchLoading,
    loading: fetchLoading || updatePaymentMethodsLoading,
    salonPaymentMethods: data?.fetchSalonPaymentMethods || [],
    salonActivePaymentMethodsOptions,
    updateSalonPaymentMethods(data: SalonPaymentMethodInput[]) {
      return updateSalonPaymentMethods({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchSalonPaymentMethods],
      });
    },
  };
};
