import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const fetchTreatments: TypePolicy = relayStylePagination(["categoryId"]);
export const fetchAllTreatments: TypePolicy = relayStylePagination();

export const Treatment: TypePolicy = {
  fields: {},
};
export const SearchTreatment: TypePolicy = {
  fields: {},
};
