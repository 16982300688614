import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientFragmentDoc } from '../fragments/Client.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonClientsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  limit: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.ClientSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  filters?: Types.InputMaybe<Types.ClientFilters>;
}>;


export type FetchSalonClientsQuery = { __typename?: 'RootQueryType', fetchSalonClients: { __typename?: 'ClientConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'ClientEdge', node: { __typename?: 'Client', id: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, phoneNew?: { __typename?: 'Phone', countryCode: string, number: string } | null, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, id: any }> } }> } };


export const FetchSalonClientsDocument = gql`
    query FetchSalonClients($query: String, $limit: Int!, $after: String, $sortBy: ClientSortBy, $sortOrder: SortOrder, $filters: ClientFilters) {
  fetchSalonClients(
    query: $query
    first: $limit
    after: $after
    sortBy: $sortBy
    sortOrder: $sortOrder
    filters: $filters
  ) {
    count
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...Client
      }
    }
  }
}
    ${ClientFragmentDoc}`;

/**
 * __useFetchSalonClientsQuery__
 *
 * To run a query within a React component, call `useFetchSalonClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonClientsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFetchSalonClientsQuery(baseOptions: Apollo.QueryHookOptions<FetchSalonClientsQuery, FetchSalonClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonClientsQuery, FetchSalonClientsQueryVariables>(FetchSalonClientsDocument, options);
      }
export function useFetchSalonClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonClientsQuery, FetchSalonClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonClientsQuery, FetchSalonClientsQueryVariables>(FetchSalonClientsDocument, options);
        }
export type FetchSalonClientsQueryHookResult = ReturnType<typeof useFetchSalonClientsQuery>;
export type FetchSalonClientsLazyQueryHookResult = ReturnType<typeof useFetchSalonClientsLazyQuery>;
export type FetchSalonClientsQueryResult = Apollo.QueryResult<FetchSalonClientsQuery, FetchSalonClientsQueryVariables>;
