import { useTranslation } from "react-i18next";

import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";

import { Logo } from "@components/branding/Logo";
import { LogoSignet } from "@components/branding/LogoSignet";
import { NavigationItems } from "@components/layout/sidebar/Sidebar/NavigationItems";
import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

import { UserDropdown } from "../header/UserDropdown";
import MobileSidebar from "./MobileSidebar";

type SidebarProps = {
  isOpen: boolean;
  isShrinked: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setSidebarIsShrinked: (isShrinked: boolean) => void;
};

export const Sidebar = ({ isOpen, setIsOpen, isShrinked, setSidebarIsShrinked }: SidebarProps) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { isMd } = useBreakpoint("md");

  return isMd ? (
    <div
      className={cn(
        isShrinked ? "w-16" : "w-48 xl:w-56",
        "fixed inset-y-0 flex flex-col transition-all duration-300 ease-in-out",
      )}>
      <div className="flex h-screen flex-grow flex-col">
        <div className="my-2 flex flex-grow flex-col">
          <div className="group relative flex items-center justify-between">
            <Button variant="neutral" to="/calendar" className="flex shrink-0 px-3 py-0">
              {isShrinked ? (
                <LogoSignet className="h-9 transition-opacity group-hover:opacity-0" />
              ) : (
                <Logo className="h-9" />
              )}
            </Button>
            <button
              className={cn("flex items-center rounded-md text-stone-500 hover:text-stone-700", {
                "justify-end": !isShrinked,
                "absolute left-5 top-2 opacity-0 transition-opacity group-hover:text-stone-700 group-hover:opacity-100":
                  isShrinked,
              })}
              onClick={() => setSidebarIsShrinked(!isShrinked)}>
              <span className="sr-only">{t("sidebar.toggle")}</span>
              <ArrowLeftEndOnRectangleIcon
                className={cn("mr-3 h-5 w-5 shrink-0 transition-transform", {
                  "-scale-100 ": isShrinked,
                })}
                aria-hidden="true"
              />
            </button>
          </div>
          <NavigationItems isSidebarShrinked={isShrinked} />
          <div className="flex justify-center">
            <UserDropdown onlyIcon={isShrinked} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MobileSidebar isOpen={isOpen} setIsOpen={setIsOpen} accountId={session?.accountId} />
  );
};
