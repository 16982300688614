import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TreatmentFragmentDoc } from '../../services/fragments/Treatment.generated';
export type EmployeeFragment = { __typename?: 'Employee', accountId: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Types.Role, permissions?: any | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: any | null, treatments: Array<{ __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> };

export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  accountId
  email
  firstName
  lastName
  fullName @client
  phone
  role
  permissions
  isAvailableForAppointments
  receiveEmails
  isActive
  imageUrl
  workSchedule
  treatments {
    ...Treatment
  }
}
    ${TreatmentFragmentDoc}`;
