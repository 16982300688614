import { createContext, ReactNode, useContext, useMemo, useReducer } from "react";

type Direction = 0 | 1 | -1;
type actionType = "swipe" | "expand" | "none";

type CalendarNavigationState = {
  isExpanded: boolean;
  direction: Direction;
  actionType: actionType;
};

type CalendarNavigationActions = {
  setIsExpanded: (isExpanded: boolean) => void;
  setDirection: (direction: Direction) => void;
  setActionType: (action: actionType) => void;
};

export type CalendarNavigationAPI = CalendarNavigationState & CalendarNavigationActions;

const enum ActionKind {
  SET_IS_EXPANDED = "SET_IS_EXPANDED",
  SET_DIRECTION = "SET_DIRECTION",
  SET_ACTION_TYPE = "SET_ACTION_TYPE",
}

type Action =
  | { type: ActionKind.SET_IS_EXPANDED; payload: boolean }
  | { type: ActionKind.SET_DIRECTION; payload: Direction }
  | { type: ActionKind.SET_ACTION_TYPE; payload: actionType };

const CalendarNavigationContext = createContext<CalendarNavigationAPI | null>(null);

const calendarNavigationReducer = (
  state: CalendarNavigationState,
  action: Action,
): CalendarNavigationState => {
  switch (action.type) {
    case ActionKind.SET_IS_EXPANDED:
      return {
        ...state,
        isExpanded: action.payload,
      };
    case ActionKind.SET_DIRECTION:
      return {
        ...state,
        direction: action.payload,
      };
    case ActionKind.SET_ACTION_TYPE:
      return {
        ...state,
        actionType: action.payload,
      };
    default:
      return state;
  }
};

export const CalendarNavigationProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(calendarNavigationReducer, {
    isExpanded: false,
    direction: 0,
    actionType: "none",
  });

  const actions = useMemo<CalendarNavigationActions>(() => {
    const setIsExpanded = (isExpanded: boolean) => {
      dispatch({ type: ActionKind.SET_IS_EXPANDED, payload: isExpanded });
    };

    const setDirection = (direction: Direction) => {
      dispatch({ type: ActionKind.SET_DIRECTION, payload: direction });
    };

    const setActionType = (actionType: actionType) => {
      dispatch({ type: ActionKind.SET_ACTION_TYPE, payload: actionType });
    };

    return {
      setIsExpanded,
      setDirection,
      setActionType,
    };
  }, []);

  const api = useMemo<CalendarNavigationAPI>(() => {
    return {
      ...state,
      ...actions,
    };
  }, [state, actions]);

  return (
    <CalendarNavigationContext.Provider value={api}>{children}</CalendarNavigationContext.Provider>
  );
};

export const useCalendarNavigation = () => {
  const context = useContext(CalendarNavigationContext);

  return useMemo<CalendarNavigationAPI>(() => {
    if (!context) {
      throw new Error("useCalendarNavigation must be used within a CalendarDateProvider");
    }
    return context;
  }, [context]);
};
