import { Controller, DeepPartial } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { DateField } from "@/components/ui/DateField";
import { Label } from "@/components/ui/Label";
import { TextAreaField } from "@/components/ui/TextAreaField";
import { TextField } from "@/components/ui/TextField";
import { TimeAutoComplete } from "@/components/ui/TimeAutoComplete";
import { TIME_AUTOCOMPLETE_STEP } from "@/constants";
import { TIME_PATTERN } from "@/constants/validations";
import { useBlockedSlotForm } from "@/features/calendar/hooks/use-blocked-slot-form";
import { EmployeeSelect } from "@/features/employees/components/EmployeeSelect";
import { useSessionContext } from "@/providers/SessionProvider";
import { Timerange, UpdateBlockedSlotInput } from "@/types";
import {
  generateTimeOptions,
  getTimerangeDiff,
  timerangeIsValid,
  TimerangeOption,
} from "@/utils/datetime";
import { isNotWhitespace } from "@/utils/form";
import { cn } from "@/utils/utils";

export type BlockedSlotFormInput = UpdateBlockedSlotInput & {
  date: Date;
};

export type BlockedSlotFormProps = {
  onClose?: () => void;
  defaultValues: DeepPartial<BlockedSlotFormInput> & {
    timeRange: Timerange;
  };
  action: "create" | "update";
};

const timeOptions = generateTimeOptions(TIME_AUTOCOMPLETE_STEP);

const mapTimeOptionsWithReferenceTime = (timeOptions: TimerangeOption[], referenceTime: string) => {
  return timeOptions.reduce<TimerangeOption[]>((acc, curr) => {
    if (timerangeIsValid(referenceTime, curr.value)) {
      acc.push({
        ...curr,
        props: {
          diff: getTimerangeDiff(referenceTime, curr.value),
        },
      });
    }

    return acc;
  }, []);
};

export const BlockedSlotForm = ({ onClose, defaultValues, action }: BlockedSlotFormProps) => {
  const { t } = useTranslation();
  const { onSubmit, handleDelete, form } = useBlockedSlotForm({
    onClose,
    defaultValues,
    action,
  });

  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const { permissions } = useSessionContext();

  const hasTimeRangeError = errors?.timeRange?.from || errors?.timeRange?.to;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("title", {
          validate: {
            isNotWhitespace,
          },
        })}
        placeholder={t("blockedSlots.placeholders.title")}
        showLabel
        disabled={!permissions.edit_slot_block}
        label={t("generic.title")}
      />

      <EmployeeSelect control={control} name="employeeId" disabled={!permissions.edit_slot_block} />

      <TextAreaField
        {...register("description")}
        showLabel
        label={t("generic.description")}
        disabled={!permissions.edit_slot_block}
      />
      <div className="flex flex-col flex-wrap gap-4 sm:flex-row sm:flex-nowrap">
        <DateField
          control={control}
          name="date"
          className="grow"
          label={t("generic.date")}
          portalId="date"
          error={errors.date}
          disabled={!permissions.edit_slot_block}
        />
        <div className="w-full sm:w-1/2">
          <Label htmlFor={`timeRange.from`}>{t("generic.time")}</Label>
          <div className="flex flex-1 flex-col items-center gap-1">
            <div className="flex w-full gap-4">
              <Controller
                control={control}
                name="timeRange.from"
                rules={{
                  pattern: TIME_PATTERN,
                  required: true,
                  deps: ["timeRange.to"],
                }}
                render={({ field: { onChange: formOnChange, value = "" } }) => (
                  <TimeAutoComplete
                    timeOptions={timeOptions}
                    label={t("generic.from")}
                    onChange={formOnChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="timeRange.to"
                rules={{
                  pattern: TIME_PATTERN,
                  required: true,
                  deps: ["timeRange.from"],
                  validate: (value) => timerangeIsValid(getValues("timeRange.from"), value),
                }}
                render={({ field: { onChange: formOnChange, value = "" } }) => (
                  <TimeAutoComplete
                    timeOptions={mapTimeOptionsWithReferenceTime(
                      timeOptions,
                      getValues("timeRange.from"),
                    )}
                    onChange={formOnChange}
                    label={t("generic.to")}
                    value={value}
                  />
                )}
              />
            </div>
            {hasTimeRangeError && (
              <p className="mt-1 w-full rounded-md bg-red-50 px-2.5 py-1.5 text-xs text-red-600">
                {t("errors.invalidTimeRange")}
              </p>
            )}
          </div>
        </div>
      </div>

      <div
        className={cn("mt-2 grid gap-4", {
          "sm:grid-cols-2": action === "update",
        })}>
        {action === "update" && (
          <Button
            variant="danger-outline"
            onClick={handleDelete}
            disabled={!permissions.delete_slot_block}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          type="submit"
          disabled={
            (action === "create" && !permissions.add_slot_block) ||
            (action === "update" && !permissions.edit_slot_block)
          }>
          {action === "create" ? t("blockedSlots.blockSlot") : t("generic.save")}
        </Button>
      </div>
    </form>
  );
};
