import { useLocation } from "react-router-dom";

import { EMAIL_VERIFICATION_ROUTES, ONBOARDING_ROUTES } from "../constants/routes";
export const useRouterUtils = () => {
  const location = useLocation();

  const isOnOnboardingPage = Object.values(ONBOARDING_ROUTES).some((link) =>
    location.pathname.includes(link),
  );

  const isOnEmailVerificationPage = Object.values(EMAIL_VERIFICATION_ROUTES).some((link) =>
    location.pathname.includes(link),
  );

  const isInitial = location.pathname === "/";

  const isOnMaintenancePage = location.pathname.includes("maintenance");

  const isOnSubscriptionPage = location.pathname.includes("no-subscription");

  return {
    isInitial,
    isOnOnboardingPage,
    isOnEmailVerificationPage,
    isOnMaintenancePage,
    isOnSubscriptionPage,
  };
};
