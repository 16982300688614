import { useTranslation } from "react-i18next";

import { Layout } from "@/components/layout/Layout";
import { Plans } from "@/features/payments/components/Plans";

export const SubscriptionsView = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("subscriptions.title")}>
      <div className="-ml-4 -mr-4 mt-4 flex max-w-6xl flex-col gap-8 rounded-xl px-4 sm:mt-8 sm:px-12 md:mx-auto md:px-12">
        <Plans />
      </div>
    </Layout>
  );
};
