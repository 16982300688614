import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveAppointmentRequestMutationVariables = Types.Exact<{
  data: Types.UpdateBulkAppointmentInput;
}>;


export type ApproveAppointmentRequestMutation = { __typename?: 'RootMutationType', approveAppointmentRequest: { __typename?: 'Appointment', appointmentId: any, clientId?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodId?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentId: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const ApproveAppointmentRequestDocument = gql`
    mutation ApproveAppointmentRequest($data: UpdateBulkAppointmentInput!) {
  approveAppointmentRequest(data: $data) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;
export type ApproveAppointmentRequestMutationFn = Apollo.MutationFunction<ApproveAppointmentRequestMutation, ApproveAppointmentRequestMutationVariables>;

/**
 * __useApproveAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useApproveAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAppointmentRequestMutation, { data, loading, error }] = useApproveAppointmentRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveAppointmentRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAppointmentRequestMutation, ApproveAppointmentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAppointmentRequestMutation, ApproveAppointmentRequestMutationVariables>(ApproveAppointmentRequestDocument, options);
      }
export type ApproveAppointmentRequestMutationHookResult = ReturnType<typeof useApproveAppointmentRequestMutation>;
export type ApproveAppointmentRequestMutationResult = Apollo.MutationResult<ApproveAppointmentRequestMutation>;
export type ApproveAppointmentRequestMutationOptions = Apollo.BaseMutationOptions<ApproveAppointmentRequestMutation, ApproveAppointmentRequestMutationVariables>;
