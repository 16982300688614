import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { UseFieldArrayReturn, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type {
  AppointmentFormInput,
  AppointmentFormProps,
} from "@/features/calendar/components/Appointment/AppointmentForm";
import { DEFAULT_APPOINTMENT_DURATION_SEC } from "@/features/calendar/constants";
import { useEquipmentAvailability } from "@/features/equipment/hooks/use-equipment-availability-store";
import { useCountersStore } from "@/features/session/hooks";
import { useSessionContext } from "@/providers/SessionProvider";
import {
  CheckEquipmentsForTreatmentsAvailabilityQuery,
  Client,
  Employee,
  SearchTreatment,
} from "@/types";
import { addTime, setTimeToDate } from "@/utils/datetime";
import { Option } from "@/utils/select-utils";
import { isTruthy } from "@/utils/utils";

type SelectedTreatment = {
  color: string;
  price: number;
  duration: number;
};

export type AppointmentFormListProps = {
  treatmentsArray: UseFieldArrayReturn<AppointmentFormInput, "treatments", "id">;
  equipmentAvailability: {
    loading: boolean;
    data: CheckEquipmentsForTreatmentsAvailabilityQuery | undefined;
    isAvailable: boolean;
  };
  currentEntityIndex: number;
  handleAddTreatment: (index: number) => void;
  handleRemoveTreatment: (index: number) => void;
  handleTreatmentSelect: (index: number, option?: Option<SearchTreatment>) => void;
  handleEmployeeSelect: (index: number, option?: Option<Employee>) => void;
  handleTimerangeChange: (index: number, data: string | ChangeEvent<HTMLInputElement>) => void;
  handleFromTimeChange: (index: number, from: string | ChangeEvent<HTMLInputElement>) => void;
  setCurrentEntityIndex: Dispatch<SetStateAction<number>>;
  selectedTreatments: SelectedTreatment[];
};

export const useAppointmentForm = ({ defaultValues, appointment }: AppointmentFormProps) => {
  const { t } = useTranslation();
  const form = useForm<AppointmentFormInput>({
    mode: "all",
    defaultValues,
  });

  const { setValue, trigger, getValues, control, formState } = form;

  const { session } = useSessionContext();
  const { smsLimit } = useCountersStore();

  const [currentEntityIndex, setCurrentEntityIndex] = useState<number>(
    (defaultValues.treatments?.length || 1) - 1,
  );
  const calculateDuration = (from: Date, to: Date) => {
    const fromInMiliseconds = new Date(from).getTime();
    const toInMiliseconds = new Date(to).getTime();

    return (toInMiliseconds - fromInMiliseconds) / 1000;
  };

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedTreatments, setSelectedTreatments] = useState<SelectedTreatment[]>(
    () =>
      appointment?.treatments.map((treatment) => ({
        color: treatment.categoryColor || "stone",
        price: treatment.suggestedPrice,
        duration: calculateDuration(treatment.timeRange.from, treatment.timeRange.to),
      })) || [],
  );

  const confirmationSmsContent = Object.entries({
    [t("sms.limitReachedTooltip")]: smsLimit <= 0,
    [t("sms.clientPhoneNotProvided")]: !selectedClient?.phone,
    [t("sms.confirmationSmsInfo")]: smsLimit > 0 && selectedClient?.phone,
  })
    .filter(([_key, value]) => isTruthy(value))
    .map(([key]) => key)
    .join(". ");

  const handleClientSelect = (option?: Option<Client>) => {
    if (option?.props) {
      setSelectedClient(option.props);
    }
  };

  const handleClientClear = (name: keyof AppointmentFormInput) => {
    setSelectedClient(null);
    form.resetField(name);
  };

  const handleTreatmentSelect = (index: number, option?: Option<SearchTreatment>) => {
    if (!option) return;

    setSelectedTreatments((prev) => {
      const newSelectedTreatments = [...prev];
      newSelectedTreatments[index] = {
        color: option?.props?.categoryColor || "stone",
        price: option?.props?.treatmentPrice || 0,
        duration: option?.props?.treatmentDuration || 0,
      };
      return newSelectedTreatments;
    });

    const from = getValues(`treatments.${index}.timeRange.from`);
    const duration = option?.props?.treatmentDuration || 0;

    from && setValue(`treatments.${index}.timeRange.to`, addTime(from, duration));
    setValue(`treatments.${index}.treatmentName`, option?.props?.treatmentName);
    setValue(`treatments.${index}.treatmentId`, option?.props?.treatmentId);
    trigger([`treatments.${index}.timeRange.from`, `treatments.${index}.timeRange.to`]);
  };

  const handleEmployeeSelect = (index: number, option?: Option<Employee>) => {
    setValue(
      `treatments.${index}.employeeName`,
      option?.props ? `${option.props.firstName} ${option.props.lastName}` : undefined,
    );
  };

  const handleAddTreatment = (lastIndex: number) => {
    const from = getValues(`treatments.${lastIndex}.timeRange.to`);
    const employee = {
      name: getValues(`treatments.${lastIndex}.employeeName`),
      id: getValues(`treatments.${lastIndex}.employeeId`),
    };

    setSelectedTreatments((prev) => {
      const newSelectedTreatments = [...prev];
      newSelectedTreatments.push({
        color: "stone",
        price: 0,
        duration: DEFAULT_APPOINTMENT_DURATION_SEC,
      });
      return newSelectedTreatments;
    });

    treatmentsArray.append({
      treatmentId: "",
      employeeId: employee.id || session?.accountId,
      employeeName: employee.name || session?.fullName,
      timeRange: { from, to: addTime(from, DEFAULT_APPOINTMENT_DURATION_SEC) },
    });
    setCurrentEntityIndex(lastIndex + 1);
  };

  const [date, treatments] = useWatch({
    control: control,
    name: ["date", "treatments"],
  });

  /* note: treatmentsArray.fields might be outdated, use treatments from useWatch - https://github.com/react-hook-form/react-hook-form/issues/1078 */
  const treatmentsArray = useFieldArray({
    control: control,
    name: "treatments",
  });

  const handleRemoveTreatment = (index: number) => {
    if (index === currentEntityIndex || currentEntityIndex === treatmentsArray.fields.length - 1) {
      setCurrentEntityIndex(index > 0 ? index - 1 : 0);
    }

    const lastTreatmentId = getValues(
      `treatments.${treatmentsArray.fields.length - 1}.treatmentId`,
    );

    const newSelectedTreatments = selectedTreatments.filter((_, idx) => index !== idx);

    const removedTreatmentStartingTime = treatmentsArray.fields[index].timeRange.from;

    const newTreatments = treatmentsArray.fields
      .filter((_, idx) => idx !== index)
      .reduce((acc: AppointmentFormInput["treatments"], treatment, idx) => {
        if (idx >= index) {
          const prevEndTime =
            idx === index
              ? removedTreatmentStartingTime
              : acc[idx - 1].timeRange.to || removedTreatmentStartingTime;
          return [
            ...acc,
            {
              ...treatment,
              treatmentId: lastTreatmentId,
              timeRange: {
                from: prevEndTime,
                to: addTime(
                  prevEndTime,
                  calculateDuration(
                    setTimeToDate(date, treatment.timeRange.from),
                    setTimeToDate(date, treatment.timeRange.to),
                  ),
                ),
              },
            },
          ];
        }
        return [...acc, treatment];
      }, []);

    setSelectedTreatments(newSelectedTreatments);
    setValue("treatments", newTreatments, {
      shouldDirty: true,
    });
  };

  const handleTimerangeChange = (index: number, data: string | ChangeEvent<HTMLInputElement>) => {
    const value = typeof data === "string" ? data : data.target.value;

    if (formState.errors.treatments) {
      trigger(`treatments.${index}.timeRange.from`);
    }

    const newTreatments = treatmentsArray.fields.reduce(
      (acc: AppointmentFormInput["treatments"], treatment, idx) => {
        const treatmentId = getValues(`treatments.${idx}.treatmentId`);
        const treatmentName = getValues(`treatments.${idx}.treatmentName`);

        if (idx > index) {
          const prevEndTime = acc[idx - 1].timeRange.to || value;
          return [
            ...acc,
            {
              ...treatment,
              treatmentId,
              treatmentName,
              timeRange: {
                from: prevEndTime,
                to: addTime(
                  prevEndTime,
                  calculateDuration(
                    setTimeToDate(date, treatment.timeRange.from),
                    setTimeToDate(date, treatment.timeRange.to),
                  ),
                ),
              },
            },
          ];
        }
        if (idx === index) {
          return [
            ...acc,
            {
              ...treatment,
              treatmentId,
              treatmentName,
              timeRange: {
                from: getValues(`treatments.${idx}.timeRange.from`),
                to: value,
              },
            },
          ];
        }
        return [
          ...acc,
          {
            ...treatment,
            treatmentId,
            treatmentName,
            timeRange: {
              from: getValues(`treatments.${idx}.timeRange.from`),
              to: getValues(`treatments.${idx}.timeRange.to`),
            },
          },
        ];
      },
      [],
    );

    setValue("treatments", newTreatments, {
      shouldDirty: true,
    });
  };

  const handleFromTimeChange = (index: number, from: string | ChangeEvent<HTMLInputElement>) => {
    const treatment = selectedTreatments[index];

    if (!treatment) return;

    const duration = treatment.duration;
    const value = typeof from === "string" ? from : from.target.value;
    const endTime = addTime(value, duration);

    setValue(`treatments.${index}.timeRange.to`, endTime);

    handleTimerangeChange(index, endTime);
  };

  const availabilityInput = treatments
    .map(({ treatmentId, timeRange }) => {
      if (treatmentId && timeRange.to && timeRange.from && formState.isValid) {
        return {
          treatmentId,
          timeRange: {
            from: setTimeToDate(date, timeRange.from),
            to: setTimeToDate(date, timeRange.to),
          },
        };
      }
    })
    .filter(isTruthy);

  const equipmentAvailability = useEquipmentAvailability(availabilityInput);

  return {
    form,
    selectedClient,
    confirmationSmsContent,
    date,
    treatmentsArray,
    equipmentAvailability,
    currentEntityIndex,
    setSelectedClient,
    handleClientSelect,
    handleClientClear,
    handleAddTreatment,
    handleRemoveTreatment,
    handleTreatmentSelect,
    handleEmployeeSelect,
    setCurrentEntityIndex,
    handleTimerangeChange,
    handleFromTimeChange,
    selectedTreatments,
  };
};
