import { useTranslation } from "react-i18next";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";

export const SuccessfulPaymentView = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Alert
        type="success"
        title={t("payments.successfulPayment.title")}
        description={t("payments.successfulPayment.description")}>
        <Button to="/calendar">{t("generic.continue")}</Button>
      </Alert>
    </div>
  );
};
